import { i18nTextId } from "./i18nText";

const i18n_es: { [id in i18nTextId]: string } = {
  PAGE_REGISTER_TITLE: "Registrarse",
  PAGE_REGISTER_LABEL_LANGUAGE: "Idioma",
  PAGE_REGISTER_LABEL_EMAIL: "Correo electrónico",
  PAGE_REGISTER_LABEL_USERNAME: "Usuario",
  PAGE_REGISTER_LABEL_PASSWORD: "Contraseña",
  PAGE_REGISTER_LABEL_CONFIRM_PASSWORD: "Confirmar contraseña",
  PAGE_REGISTER_LABEL_FIRST_NAME: "Nombre",
  PAGE_REGISTER_LABEL_LAST_NAME: "Apellido",
  PAGE_REGISTER_SUBMIT_BUTTON_TEXT: "Registrarse",
  PAGE_REGISTER_USERNAME_IN_USE: "Nombre de usuario no disponible",
  PAGE_REGISTER_TOKEN_ERROR: "El token ha caducado o no es válido",
  PAGE_REGISTER_PASSWORD_UNMATCH:
    "La contraseña y la confirmación de la contraseña no coinciden",
  VALIDATION_FIELD_REQUIRED: "Campo obligatorio",
  VALIDATION_FIELD_INVALID: "Campo no válido",
  VALIDATION_FIELD_MIN: "Mínimo ${} dígitos",
  VALIDATION_FIELD_MAX: "Máximo ${} dígitos",
  VALIDATION_STRONGER_PASSWORD: "No lo suficientemente fuerte",
  SELECTED_FARM_MAP_LABEL: "Mapa",
  SELECTED_FARM_EDIT_BUTTON_TEXT: "Editar",
  SELECTED_FARM_FILTER_BUTTON_TEXT: "Filtrar",
  SELECTED_FARM_FILTER_NAME: "Nombre",
  SELECTED_FARM_FILTER_PROBLEM: "Problema",
  SELECTED_FARM_PIVOTS: "Pivotes",
  SELECTED_FARM_IRPDS: "Bombas",
  SELECTED_FARM_SWEEP_BUTTON_TEXT: "Barrido",
  SELECTED_FARM_SWEEP_EXECUTING_TEXT: "Ejecutando barrido...",
  SELECTED_FARM_SWEEP_END_TEXT: "Barrido no finalizado!",
  SELECTED_FARM_SWEEP_GENERIC_ERROR: "Falla en el barrido del dispositivo",
  DEVICE_DRAWER_TITLE: "Dispositivos",
  DEVICE_BOX_VERY_STRONG: "Muy Fuerte",
  DEVICE_BOX_STRONG: "Fuerte",
  DEVICE_BOX_MODERATE: "Moderado",
  DEVICE_BOX_WEAK: "Débil",
  DEVICE_BOX_ERROR: "Error",
  DEVICE_NO_LOGS: "Ejecutar el barrido para ver las entradas del registro.",
  EDIT_FARM_LABEL: "Editar Granja",
  EDIT_FARM_GENERAL_INFORMATION: "Información General",
  EDIT_FARM_GENERAL_CONTACT: "Contacto",
  EDIT_FARM_ENERGY_RANGES: "Rangos de Potencia",
  EDIT_FARM_ENERGY_RANGES_GENERATED: "Tiempo restante generado automáticamente",
  EDIT_FARM_ENERGY_RANGES_ADD_BUTTON: "Añadir",
  EDIT_FARM_ENERGY_RANGES_REMOVE_BUTTON: "Eliminar",
  EDIT_FARM_USERS: "Usuarios",
  EDIT_FARM_USER: "Usuario",
  EDIT_FARM_VISITORS: "VISITANTES",
  EDIT_FARM_OPERATORS: "Operadores",
  EDIT_FARM_CODE: "Código Granja: ",
  EDIT_FARM_EMPLOYEES: "Usuarios",
  EDIT_FARM_RESALE: "Reventa",
  EDIT_FARM_APPROVE: "Aprobar",
  EDIT_FARM_PERMISSIONS: "Permisos ",
  EDIT_FARM_VIEW: "Visualización ",
  EDIT_FARM_SEND_INVITE_AGAIN: "Enviar invitación de nuevo",
  EDIT_FARM_RESEND_INVITATION_SUCCESS: "Invitación enviada.",
  EDIT_FARM_RESEND_INVITATION_FAILED:
    "No se ha podido reenviar la invitación. Por favor, inténtelo más tarde.",
  EDIT_FARM_REMOVE: "Eliminar",
  EDIT_FARM_REMOVE_INFO: "Desea eliminar el usuario ${}?",
  EDIT_FARM_REMOVE_REPEATER_INFO: "Desea eliminar el repetidor ${}?",
  EDIT_FARM_BUTTON_CANCEL: "Cancelar",
  EDIT_FARM_BUTTON_DETELE: "Borrar",
  EDIT_FARM_TITLE_PERMISSIONS: "Editar Permisos ",
  EDIT_FARM_TAG_VIEW: "Visualización",
  EDIT_FARM_TAG_OPERATE: "Operar",
  EDIT_FARM_BUTTON_SAVE: "Guardar",
  EDIT_FARM_GROUPS: "Grupos",
  EDIT_FARM_GROUPS_INFO:
    "Configurar grupos pivotantes para obtener estadísticas e informes por grupo.",
  EDIT_FARM_BUTTON_CREATE_GROUP: "Crear grupo pivote",
  EDIT_FARM_BUTTON_ADD_POWER_RANGE: "Añadir Rangos de Potencia",
  SELECTED_FARM_DELETE_BUTTON_TEXT: "Borrar",
  EDIT_FARM_ADMINISTRATOR: "Administrador",
  EDIT_FARM_TEMPORARY_ADMINISTRATOR: "Administrador Temporario:",
  EDIT_FARM_REGISTER_USER:
    "Ingrese el nombre, nombre de usuario o correo electrónico del usuario a ser invitado.",
  EDIT_FARM_BUTTON_REGISTER_USER: "Registrar Usuario",
  EDIT_FARM_BUTTON_EDIT: "Editar",
  EDIT_FARM_NAME_GROUP: "Nombre del Grupo",
  EDIT_FARM_REPEATERS: "Repetidores",
  EDIT_FARM_BUTTON_REGISTER_REPEATERS: "Registrar repetidores",
  EDIT_FARM_USER_PENDING: "Pendiente",
  EDIT_FARM_GROUP_NAME: "Nombre del Grupo",
  EDIT_FARM_EDIT_REPEATER: "Editar Repetidor",
  EDIT_FARM_USER_DELETE_SUCCESS: "Usuario eliminado",
  EDIT_FARM_USER_PERMISSION_SUCCESS: "Permisos guardados correctamente",
  EDIT_FARM_GROUP_SUCCESS: "Grupo creado",
  EDIT_FARM_EDIT_GROUP_SUCCESS: "Grupo editado",
  EDIT_FARM_EDIT_GROUP_REMOVED: "Grupo eliminado",
  EDIT_FARM_GROUP_NAME_ERROR: "Nombre del grupo no completado",
  EDIT_FARM_GROUP_PIVOT_ERROR: "Pivotes no seleccionados",
  EDIT_FARM_REPEATER_SUCCESS: "Repetidor registrado",
  EDIT_FARM_REPEATER_ERROR: "Error al registrar repetidor",
  EDIT_FARM_REPEATER_DELETE_SUCCESS: "Repetidor eliminado",
  EDIT_FARM_EDIT_REPEATER_SUCCESS: "Repetidor editado",
  EDIT_FARM_REPEATER_NAME_ERROR: "Nombre del repetidor no completado",
  EDIT_FARM_TIMEZONE: "Zona Horaria",
  EDIT_FARM_NO_REGISTERED_USERS: "No tiene usuarios registrados.",
  EDIT_FARM_NO_REGISTERED_GROUPS: "No tiene grupos registrados.",
  EDIT_FARM_NO_REGISTERED_REPEATERS: "No tiene repetidores registrados.",
  HISTORIC_SCHEDULE_IRRIGATION_INDEX: "Cronograma ${}:",
  HISTORIC_SCHEDULE_IRRIGATION_MODE: "Modo: ",
  HISTORIC_SCHEDULE_IRRIGATION_PERCENT: "Percentímetro: ",
  HISTORIC_SCHEDULE_IRRIGATION_DIRECTION: "Dirección: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_DATE: "Fecha Inicio: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_DATE: "Fecha Finalización: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS: "Finalización después de: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE: "Ángulo Final: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR: "Terminará al final del sector",
  HISTORIC_SCHEDULE_IRRIGATION_END_MODE: "Modo de Finalización: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_END: "Inicio - Finalización: ${} - ${}",
  FORGET_PASSWORD_OPTION: "Seleccionar una opción",
  FORGET_PASSWORD_OPTION_USER:
    "He olvidado mi nombre de usuario o correo electrónico",
  ORGET_PASSWORD_OPTION_PASSWORD: "He olvidado mi contraseña",
  FORGET_PASSWORD_TYPE_EMAIL: "Escriba su correo electrónico",
  FORGET_PASSWORD_INFO_USER:
    "Póngase en contacto con su administrador de la granja o con su distribuidor local.",
  FORGET_PASSWORD_INFO_EMAIL:
    "Se ha enviado un correo electrónico a la dirección:\n\n${}\n\nSiga las instrucciones del correo electrónico y recupere su contraseña.",
  FORGET_PASSWORD_RECOVEY: "Ingrese su nueva contraseña.",
  FORGET_PASSWORD_NEW_PASSWORD: "Nueva contraseña",
  FORGET_PASSWORD_REPEAT_NEW_PASSWORD: "Repita la nueva contraseña",
  FORGET_PASSWORD_SAVE: "Guarde la contraseña",
  FORGET_PASSWORD_OK: "OK",
  FORGET_PASSWORD_MODAL_SUCCESS: "La contraseña se ha cambiado correctamente!",
  CHANGE_PASSWORD_ERROR:
    "Error al cambiar la contraseña, tal vez esta no sea su contraseña actual",
  FORGET_PASSWORD_DIFFERENT:
    "La nueva contraseña y la contraseña de confirmación son diferentes",
  FORGET_PASSWORD_MODAL_EMAIL_FAILED:
    "No hay registro con este correo electrónico",
  EDIT_PIVOT_LABEL: "Editar Pivote",
  EDIT_MONITOR_LABEL: "Edit Monitor Pivote",
  EDIT_PIVOT_LATITUDE: "Latitud",
  EDIT_PIVOT_LONGITUDE: "Longitud",
  EDIT_PIVOT_SAVE_BUTTON: "Guardar",
  EDIT_PIVOT_LOAD_BUTTON: "Cargar",
  EDIT_PIVOT_LOAD_SUCCESS: "¡Configuración cargada correctamente!",
  EDIT_PIVOT_LOAD_ERROR: "Error al cargar la configuración.",
  EDIT_PIVOT_LABEL_GENERAL: "General",
  EDIT_PIVOT_LABEL_LOCATION: "Ubicación",
  EDIT_PIVOT_IMANAGE_RADIO: "iManage Radio",
  EDIT_PIVOT_SENSOR: "Sensores",
  EDIT_PIVOT_LABEL_CLOCK: "Reloj",
  EDIT_IRPD_LABEL_LINKED_METER: "Medidor vinculado",
  EDIT_METER_LABEL_LINKED_IRPD: "IRPDs vinculados",
  EDIT_METER_SUBTITLE_LINK_IRPD:
    "Vincule hasta 10 dispositivos IRPD a este dispositivo Imeter",
  EDIT_PIVOT_LABEL_PUMP: "Presión",
  EDIT_PIVOT_LABEL_PAUSE_TIME: "Tiempo de Pausa",
  EDIT_PIVOT_LABEL_PLUVIOMETER: "Pluviómetro",
  EDIT_PIVOT_LABEL_END_GUN: "Endgun",
  EDIT_PIVOT_LABEL_GENERAL_SETTINGS: "Configuraciones Generales",
  EDIT_PIVOT_LABEL_POWER_SETTINGS: "Configuración de la Energía",
  EDIT_PIVOT_LABEL_PAUSE_TIME_SETTINGS: "Configuración del Tiempo de Pausa",
  EDIT_PIVOT_LABEL_SETTING_HISTORIC: "Historial de Configuraciones",
  HISTORIC_SIMPLE_IRRIGATION_MODE: "Modo: ",
  HISTORIC_SIMPLE_IRRIGATION_DIRECTION: "Dirección: ",
  HISTORIC_SIMPLE_IRRIGATION_PERCENT: "Precipitación: ",
  HISTORIC_SIMPLE_IRRIGATION_START_MODE: "Modo Inicio: ${}",
  HISTORIC_SIMPLE_IRRIGATION_STOP_MODE: "Modo Detener: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE: "Detener Ángulo: ",
  HISTORIC_SIMPLE_IRRIGATION_START_DATE: "Fecha Inicio: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS: "Rondas de Detención: ",
  HISTORIC_SIMPLE_IRRIGATION_DONT_STOP: "No detener",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE: "Detener por ángulo",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR: "Detener por sector",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS: "Detener por vueltas",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON: "Encendido",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF: "Apagado",
  GPS_STREAM_TABLE_START: "Creado en",
  GPS_STREAM_TABLE_ANGLE: "Ángulo",
  GPS_STREAM_TABLE_POSITION: "Posición",
  GPS_STREAM_TABLE_DIRECTION: "Dirección",
  GPS_STREAM_TABLE_SPEED: "Precipitación",
  GPS_STREAM_TABLE_STATUS: "Estado",
  GPS_STREAM_TABLE_WATER_PRESSURE: "Presión - Última torre",
  GPS_STREAM_TABLE_CENTER_PRESSURE: "Presión - Torre central",
  GPS_STREAM_TABLE_BATERY: "Batería",
  GPS_STREAM_TABLE_HOURMETER: "Horómetro",
  GPS_STREAM_TABLE_TITLE: "Lista Información GPS",
  USER_HAS_NO_FARM: "No tiene acceso a ninguna granja.",
  SELECTED_IRPD_START: "Iniciar Bomba",
  SELECTED_IRPD_STOP: "Detener Bomba",
  LANGUAGE_SELECTOR_ENGLISH: "ENGLISH (US)",
  LANGUAGE_SELECTOR_SPANISH: "ESPAÑOL (ES)",
  LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL: "PORTUGUÊS (BR)",
  LANGUAGE_SELECTOR_RUSSIAN: "РУССКИЙ (RU)",
  LANGUAGE_SELECTOR_GERMAN: "DEUTSCH (AT)",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER: "Centro",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE: "Referencia de Inicio",
  PIVOT_CONFIG_V5_FIELD_GP_END_REFERENCE: "Referencia de Finalización",
  PIVOT_CONFIG_V5_FIELD_RADIUS: "Radio",
  PIVOT_CONFIG_V5_FIELD_FLOW: "Caudal",
  PIVOT_CONFIG_V5_FIELD_DEPTH_WELL: "Escala del sensor",
  PIVOT_CONFIG_V5_FIELD_SPEED: "Velocidad de la torre final",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_STABLE_TIME: "Voltaje Estable",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_REF: "Voltaje de Referencia",
  PIVOT_CONFIG_V5_FIELD_CLOCK: "Ajustar manualmente el reloj del dispositivo",
  PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK:
    "Ajustar automáticamente el reloj del dispositivo",
  PIVOT_CONFIG_V5_FIELD_PUMP_SOFT_START_TIME: "Hora de Inicio Suave",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF: "Apagado",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH: "Por Interruptor de Presión",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR: "Por sensor de presión",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Rango máximo del sensor de presión",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_FACTOR: "Factor de Sensor de Presión",
  PIVOT_CONFIG_V5_FIELD_PERIODIC_STREAM_TIMER:
    "Temporizador de flujo periódico (segundos)",
  PIVOT_CONFIG_V5_FIELD_ANGLE_RANGE: "Rango del ángulo",
  PIVOT_CONFIG_V5_PIVOT_SEGMENTS: "Segmentos",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_FORWARD: "Modo Avanzar",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_REVERSE: "Modo Retroceder",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_FORWARD: "% Avanzar",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_REVERSE: "% Retroceder",
  PIVOT_CONFIG_V5_FIELD_PILOT_AVERAGE_POWER: "Potencia Promedio del Pivote",
  PIVOT_CONFIG_V5_FIELD_CANAL_AVERAGE_POWER: "Potencia Promedio Pozo",
  PIVOT_CONFIG_V5_FIELD_SEGMENT: "Segmento",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH: "English",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE: "Portuguese",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN: "German",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH: "Español",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN: "Russian",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE: "Ninguno",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET: "Húmedo",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY: "Seco",
  PIVOT_CONFIG_V5_TITLE_ANGLE: "Ángulo",
  PIVOT_CONFIG_V5_FORWARD: "Avanzar",
  PIVOT_CONFIG_V5_REVERSE: "Retroceder",
  PIVOT_CONFIG_V5_START: "Inicio",
  PIVOT_CONFIG_V5_END: "Finalización",
  NOTIFICATIONS: "Notificaciones",
  NO_NOTIFICATION:
    "No tiene ninguna notificación. \nHaga clic en el botón de abajo para crear una.",
  ALERT_ANDROID_ONLY_NOTIFY: "Esta opción sólo está disponible para Android.",
  ALERT_ITEM_TITLE: "${} Pivote(s)",
  ALERT_ITEM_DO_ENABLE: "Habilitar",
  ALERT_ITEM_DO_DISABLE: "Inhabilitar",
  ALERT_ITEM_ENABLED: "Habilitado",
  ALERT_ITEM_DISABLED: "Inhabilitado",
  ALERT_ITEM_EDIT: "Editar",
  ALERT_ITEM_DELETE: "Borrar",
  ALERT_BOX_TIP:
    "**Consejo: pulse dos veces sobre la tarjeta para editarla/borrarla",
  SELECTED_ALERT_TIME_RANGE: "Rango de Tiempo",
  SELECTED_ALERT_TIME_BETWEEN: "Entre ${} y ${}",
  SELECTED_ALERT_SELECT_PIVOTS: "Seleccionar Pivotes",
  SELECTED_ALERT_SELECT_REASONS: "Seleccionar Razones",
  SELECTED_ALERT_REASONS: "Razones",
  EDIT_ALERT_ERROR_MSG:
    "Error. Verifique que todos los campos están completados.",
  NEW_ALERT_CREATE_NOTIFICATION: "Crear Notificación",
  NEW_ALERT_EDIT_SELECTED: "${} seleccionado",
  NEW_ALERT_EDIT_NOTIFICATION: "Editar Notificación",
  NOTIFICATION: "Notificación",
  ALARM: "Alarma",
  BASIC_INFO_BOX_COMMING_SOON: "Próximamente",
  BASIC_INFO_BOX_UNEXPECTED_STOPS: "Detenciones inesperadas",
  BASIC_INFO_BOX_LACK_OF_PRESSURE: "Baja presión",
  BASIC_INFO_BOX_POWER_OUTAGE: "Falla de energía",
  BASIC_INFO_BOX_MISALIGNMENT: "Seguridad",
  BASIC_INFO_BOX_POWER_SURGE: "Sobretensión",
  BASIC_INFO_BOX_ACTIONS_TITLE: "Acciones",
  BASIC_INFO_BOX_ACTIONS_BY_WEB: "Por la Web",
  BASIC_INFO_BOX_ACTIONS_BY_PANEL: "Por Panel",
  BASIC_INFO_BOX_NON_EXECUTED_ACTIONS: "No ejecutado",
  BASIC_INFO_BOX_HOURS: "Horas",
  BASIC_INFO_BOX_WET: "Húmedo",
  BASIC_INFO_BOX_DRY: "Seco",
  BASIC_INFO_BOX_TOTAL: "Total",
  BASIC_INFO_BOX_WET_ENERGY_CONSUMPTION: "Consumo de energía en modo húmedo",
  BASIC_INFO_BOX_TYPE: "Tipo",
  BASIC_INFO_BOX_KWH_PRICE: "Precio kW/h",
  BASIC_INFO_BOX_WATER_CONSUMPTION: "Consumo",
  BASIC_INFO_BOX_PEAK_HOUR: "Tiempo de Pausa",
  BASIC_INFO_BOX_OUT_OF_PEAK_HOUR: "Fuera del tiempo de pausa",
  BASIC_INFO_BOX_AT_NIGHTTIME: "En la noche",
  BASIC_INFO_BOX_AVERAGE_WATER_BLADE: "Precipitación promedio de agua",
  BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN: "Informe generado entre ${} y ${}",
  BASIC_INFO_BOX_WATER_BLADE_RADAR: "Gráfica de Precipitación",
  BASIC_INFO_BOX_WORK_HOURS: "Horas de trabajo en húmedo",
  BASIC_INFO_BOX_TOTAL_COST: "Costo Total",
  BASIC_INFO_BOX_PONTA: "Pico",
  BASIC_INFO_BOX_FORA_DE_PONTA: "Fuera del Pico",
  BASIC_INFO_BOX_REDUZIDO: "Noche",
  BASIC_INFO_BOX_FLOW: "Flujo:",
  BASIC_INFO_BOX_TOTAL_VALUE: "Total: $",
  BASIC_INFO_BOX_TIME_RANGES: "Rangos de Tiempo",
  BASIC_INFO_BOX_WATER_BLADE_MM: "Cuchilla de agua (mm)",
  BASIC_INFO_BOX_TOTAL_FEES: "Total:",
  BASIC_INFO_BOX_TOTAL_VOLUME: "Volumen Total:",
  BASIC_INFO_BOX_TENSION_TITLE: "Voltajes Pivote (V)",
  BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE: "Presión Comparativa (bar)",
  BASIC_INFO_BOX_TENSION_INFO:
    "Los voltajes mayores a 700V y menores a 300V se descartaron.",
  BASIC_INFO_BOX_TENSION_INFO_MIN: "Min",
  BASIC_INFO_BOX_TENSION_INFO_AVERAGE: "Prom",
  BASIC_INFO_BOX_TENSION_INFO_MAX: "Máx",
  BASIC_INFO_BOX_TENSION: "Voltajes (V)",
  BASIC_INFO_BOX_TENSION_DATES: "Fecha",
  BASIC_INFO_BOX_PRESSURE: "Presión (bar)",
  BASIC_INFO_BOX_LATEST_PRESSURE: "Presión",
  BASIC_INFO_BOX_CURRENT_PRESSURE: "Presión de Comparación",
  BASIC_INFO_BOX_CHART: "Gráficas",
  BASIC_INFO_BOX_TABLE: "Tablas",
  BASIC_INFO_BOX_NOT_AVAILABLE:
    "No disponible para pivotes con protocolo anterior a 4.1.",
  BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY:
    "Para ver estos datos, vaya a la página Editar Granja y haga clic en el botón 'habilitar' en la pestaña 'Informes Pivote'.",
  BASIC_INFO_BOX_ENABLE_IRPD_INFOS:
    "Para ver estos datos, vaya a la página Editar Granja y haga clic en el botón 'habilitar' en la pestaña 'Informes Bombas'.",
  BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED:
    "El Resumen de Operaciones se está calculando para los datos que ya existen en el sistema, esto puede llevar mucho tiempo.",
  BASIC_INFO_BOX_GO_TO_EDIT_FARM: "Ir a Editar Granja",
  BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY:
    "Función disponible sólo en la versión de escritorio.",
  SCHEDULE_IRRIGATION_FIELD_DIRECTION: "Dirección",
  SCHEDULE_IRRIGATION_FIELD_MODE: "Modo Operación",
  SCHEDULE_IRRIGATION_FIELD_PERCENT: "Percentímetro",
  SCHEDULE_IRRIGATION_FIELD_PRECIP: "Precipitación",
  SCHEDULE_IRRIGATION_VALUE_FORWARD: "Avanzar",
  SCHEDULE_IRRIGATION_VALUE_REVERSE: "Retroceder",
  SCHEDULE_IRRIGATION_VALUE_DRY: "Seco",
  SCHEDULE_IRRIGATION_VALUE_WET: "Húmedo",
  SCHEDULE_IRRIGATION_BUTTON_TURN_ON: "ENCENDER",
  SCHEDULE_IRRIGATION_BUTTON_ADD: "Añadir",
  SCHEDULE_IRRIGATION_BUTTON_REMOVE: "Eliminar",
  SCHEDULE_IRRIGATION_DATE_START_LABEL: "Fecha de inicio",
  SCHEDULE_IRRIGATION_START_DATE_INVALID:
    "No se puede crear una fecha de inicio entre fechas ya establecidas",
  SCHEDULE_IRRIGATION_END_DATE_INVALID:
    "No se puede crear una fecha de finalización entre fechas ya establecidas",
  GEOLOCATION_GETTER_GET_POSITION: "Consiga la posición que desea:",
  GEOLOCATION_GETTER_LATITUDE: "Latitud:",
  GEOLOCATION_GETTER_LONGITUDE: "Longitud:",
  GEOLOCATION_GETTER_GET: "OBTENER POSICIÓN",
  GEOLOCATION_GETTER_WAIT:
    "Espere un poco para obtener una mayor precisión del GPS.",
  GEOLOCATION_GETTER_ACCURACY_LABEL: "Precisión:",
  GEOLOCATION_GETTER_ACCURACY_VALUE: "${} metros",
  COUNTRY_SELECTOR_DDI: "Elegir un país",
  CREATE_USER_MODAL_TITLE: "Registrar Usuario",
  CREATE_USER_MODAL_ADMIN: "Administrador",
  CREATE_USER_MODAL_EMAIL: "Correo electrónico",
  CREATE_USER_MODAL_BUTTON_SAVE: "Guardar",
  CREATE_USER_MODAL_SUCCESS: "Se envió la invitación.",
  CREATE_USER_MODAL_ERROR:
    "Se produjo un error al enviar la invitación. Por favor intente de nuevo.",
  CREATE_USER_MODAL_ALREADY_REGISTERED:
    "El usuario ya está registrado en la granja.",
  CREATE_USER_MODAL_INVALID_EMAIL: "Correo electrónico no válido.",
  PIVOT_CONFIG_FIELD_ENDGUN: "Endgun",
  PIVOT_CONFIG_FIELD_CENTER: "Centro",
  PIVOT_CONFIG_FIELD_REFERENCE_START: "Referencia de Inicio",
  PIVOT_CONFIG_FIELD_REFERENCE_END: "Referencia de Finalización",
  PIVOT_CONFIG_FIELD_RADIUS: "Radio hasta la última torre",
  PIVOT_CONFIG_FIELD_TOTAL_RADIUS: "Radio total regado",
  PIVOT_CONFIG_FIELD_FLOW: "Caudal",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_STOP_MODE: "Modo Detener",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_MM_TO_STOP: "Detener a (mm)",
  PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT: "Apagado",
  PIVOT_CONFIG_VALUE_ENDGUN_ON: "Encendido",
  PIVOT_CONFIG_FIELD_DATE_TIME: "Fecha y hora",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_STOP: "Detener por mm",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_DECREASE: "Disminución de precipitación",
  SELECTED_PIVOT_SEGMENT_MODAL_LEFT_TITLE:
    "Configuración del segmento seleccionado",
  SELECTED_PIVOT_SEGMENT_TITLE: "Segmento seleccionado",
  CLICK_ON_SEGMENT: "haga clic en el segmento para editar la información",
  SELECTED_PIVOT_SEGMENT_MODAL_RIGHT_TITLE: "Parámetros de riego por segmentos",
  SELECTED_PIVOT_SEGMENT_MODAL_TITLE: "Envío de riego por segmentos",
  SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS:
    "Riego por segmentos enviado correctamente",
  SELECTED_PIVOT_SEGMENT_MODAL_FAILURE:
    "No se ha podido enviar el riego por segmentos",
  SEGMENT_IRRIGATION_FIELD_START_MODE: "Modo Inicio",
  SEGMENT_IRRIGATION_SHOW_CONFIGURED_SEGMENTS:
    "Mostrar los Segmentos Configurados",
  SEGMENT_IRRIGATION_FIELD_END_MODE: "Modo de Finalización",
  SEGMENT_IRRIGATION_FIELD_START_DATE: "Fecha Inicio",
  SEGMENT_IRRIGATION_FIELD_END_DATE: "Fecha Finalización",
  SEGMENT_IRRIGATION_FIELD_END_ANGLE: "Ángulo Final",
  SEGMENT_IRRIGATION_FIELD_END_ROUNDS: "Vueltas",
  SEGMENT_IRRIGATION_MODE_NOW: "Ahora",
  SEGMENT_IRRIGATION_ON_DATE: "En una fecha",
  SEGMENT_IRRIGATION_NEVER_ENDS: "No Detener",
  SEGMENT_IRRIGATION_BY_ANGLE: "Por Ángulo",
  SEGMENT_IRRIGATION_BY_DATE: "Por Fecha",
  SEGMENT_IRRIGATION_END_SECTOR: "Sector de Finalización",
  SEGMENT_IRRIGATION_BY_ROUNDS: "Por Vueltas",
  SEGMENT_IRRIGATION_ROUND_FIELD_ERROR:
    "Error. El número de vueltas debe ser superior a cero",
  SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE:
    "Ángulo de detención fuera de rango para este pivote",
  SEGMENT_IRRIGATION_ERROR_LOADING_LATEST:
    "No se ha podido cargar el último riego por segmentos",
  SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS:
    "No se pudo cargar el último riego. Los segmentos difieren.",
  SEGMENT_IRRIGATION_MISSING_SEGMENTS:
    "No hay segmentos guardados en el pivote actual. Guárdalos y luego intenta de nuevo.",
  SELECTED_PIVOT_GENERAL: "General",
  SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES: "Editar Historia",
  SELECTED_PIVOT_HARVEST: "Cosecha",
  SELECTED_PIVOT_BASIC_INFO: "Resumen de Operaciones",
  SELECTED_PIVOT_PUMP_BOX: "Bombas",
  SELECTED_PIVOT_HISTORIC: "Historia",
  SELECTED_PIVOT_RECOMMENDATION: "Recomendación",
  SELECTED_PIVOT_SETUP_IRRIGATION: "Configurar riego",
  SELECTED_PIVOT_CHOOSE_IRRIGATION_MODE: "Elegir Modo de Riego:",
  SELECTED_PIVOT_STANDARD_IRRIGATION: "Simple",
  SELECTED_PIVOT_BY_ANGLE_IRRIGATION: "Por Ángulo",
  SELECTED_PIVOT_VRI_IRRIGATION: "Segmentos",
  SELECTED_PIVOT_SCHEDULE_IRRIGATION: "Programación",
  SELECTED_PIVOT_STOP_ACTION: "Detener Pivote",
  SELECTED_PIVOT_EDIT_BUTTON_TEXT: "Editar",
  SELECTED_PIVOT_BACK_BUTTON_TEXT: "Atrás",
  SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON: "Segmentos",
  SELECTED_PIVOT_HISTORIC_SWITCH: "Avanzado",
  PIVOT_CONFIG_DASHBOARD_NAME: "${}",
  PIVOT_CONFIG_REPORT_PREFERENCES: "Editar Resumen",
  PIVOT_REPORT_EXCEL: "Excel",
  IRPD_REPORT_CENTRAL: "Informe de operaciones",
  IRPD_CENTRAL_GH_REPORT:
    "Informe de operaciones centrales generado. Pronto estará disponible en su correo electrónico.",
  IRPD_CENTRAL_GH_REPORT_ERROR:
    "Error al solicitar el informe de operaciones centrales. Por favor, inténtelo de nuevo más tarde.",
  PIVOT_REPORT_EXCEL_ERROR: "No hay datos para generar la planilla excel.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL:
    "Descargar archivo Excel. Por favor, espere.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR:
    "Algo no funcionó. Por favor, inténtelo más tarde.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS:
    "Descarga completa. Puede cerrar esta ventana.",
  REPORT_TABLE_START_DATE: "Inicio",
  REPORT_TABLE_END_DATE: "Finalización",
  REPORT_TABLE_START_TOTAL: "Total",
  REPORT_TABLE_START_WATER_BLADE: "Cuchilla de agua",
  REPORT_TABLE_START_DETAILS: "Detalles",
  HYDRIC_MANAGEMENT_CREATE_LABEL: "Bomba de gestión hídrica",
  HYDRIC_REPORT_TABLE_START_DATE: "Inicio de operación",
  HYDRIC_REPORT_TABLE_END_DATE: "Fin de operación",
  HYDRIC_REPORT_TABLE_DURATION: "Duración de la operación",
  HYDRIC_REPORT_TABLE_CALC_VOLUME: "Volumen calculado",
  HYDRIC_REPORT_TABLE_TOTAL_VOLUME: "Volumen del hidrómetro",
  SELECTED_PIVOT_IRRIFAST: "Alineación de torres",
  IRRIFAST_TABLE_COL_SPEC: "Especificación",
  IRRIFAST_TABLE_COL_VALUE: "Valor",
  IRRIFAST_TABLE_PROPS_TENSION: "Voltaje (DC)",
  IRRIFAST_TABLE_PROPS_FREQ: "Frecuencia de salida",
  IRRIFAST_TABLE_PROPS_CURRENT: "Corriente de salida",
  IRRIFAST_TABLE_PROPS_TENSION_SUFFIX: "V",
  IRRIFAST_TABLE_PROPS_FREQ_SUFFIX: "Hz",
  IRRIFAST_TABLE_PROPS_CURRENT_SUFFIX: "A",
  IRRIFAST_TABLE_ACTION_COLLAPSE: "Información detallada",
  IRRIFAST_TABLE_LABEL_NONE:
    "Por favor, seleccione una torre para inspeccionar datos detallados.",
  IRRIFAST_TABLE_STATUS_OFF: "Apagado",
  IRRIFAST_TABLE_STATUS_ALIGNED: "Alineado",
  IRRIFAST_TABLE_STATUS_NOTALIGNED: "No alineado",
  DEVICE_BOX_ANALYZE: "Analizar:",
  DEVICE_BOX_CONTROL: "Controlador",
  DEVICE_BOX_GPS: "GPS",
  DEVICE_BOX_PIVOT: "Pivote",
  DEVICE_BOX_SHOW_GPS: "Mostrar GPS",
  DEVICE_BOX_SEARCH_RADIO: "Buscar Radio",
  DEVICE_BOX_KEEP_LINES: "Mantener Líneas",
  SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS:
    "Programación del riego enviada correctamente",
  SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE:
    "Falló el envío de la programación del riego",
  SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT: "Sólo se pueden añadir 7 filas.",
  BY_ANGLE_IRRIGATION_BUTTON_FORWARD: "AVANZAR",
  BY_ANGLE_IRRIGATION_BUTTON_BACKWARD: "RETROCEDER",
  BY_ANGLE_IRRIGATION_FIELD_PERCENTAGE: "Percentímetro",
  BY_ANGLE_IRRIGATION_FIELD_PERCENT: "Por ciento",
  BY_ANGLE_IRRIGATION_FIELD_MILILITER: "Milímetro",
  BY_ANGLE_IRRIGATION_ERROR:
    "Error. El valor informado es menor que el mínimo esperado",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE: "Modo Endgun",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE: "Modo Operación",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION: "Dirección",
  BY_ANGLE_IRRIGATION_FIELD_DEFAULT_ERROR:
    "Error. Verifique que todos los campos están completados.",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_FORWARD: "Avanzar",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_REVERSE: "Retroceder",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON: "Encendido",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF: "Apagado",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY: "Seco",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET: "Húmedo",
  BY_ANGLE_IRRIGATION_BUTTON_SEND: "Enviar Comando",
  IRRIGATION_END_DURATION: "Previsión de la duración de la operación: ",
  IRRIGATION_END_DATE_PREDICTION: "Previsión de fecha de finalización: ",
  BY_ANGLE_IRRIGATION_BUTTON_CANCEL: "Cancelar",
  ALERT_DIALOG_YES: "Sí",
  ALERT_DIALOG_NO: "No",
  ALERT_DIALOG_CONFIRMATION: "Confirmación",
  FIRST_STEPS: "Primeros Pasos",
  FIRST_STEPS_SELECT_OPTION: "Seleccionar una opción",
  FIRST_STEPS_OPTION_ADM:
    "Soy el administrador de la granja y necesito registrar mi equipo.",
  FIRST_STEPS_OPTION_EMPLOYEE:
    "Soy trabajador de la granja y necesito registrarme.",
  FIRST_STEPS_OPTION_ADM_INFO:
    "Utilice su cámara para escanear el código QR que se encuentra en el interior de la Central, o introduzca debajo la secuencia de 16 dígitos que corresponde al ID de la Central.",
  FIRST_STEPS_OPTION_EMPLOYEE_INFO:
    "Pida a su administrador el código de la granja para continuar el registro.",
  FIRST_STEPS_FARM_CODE: "Código Granja",
  FIRST_STEPS_CENTRAL_ID: "ID Central",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE: "Crear perfil",
  FIRST_STEPS_CREATE_PROFILE_ADM: "Crear administrador",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE_INFO:
    "Ingrese sus datos de perfil. Su registro se completará después de confirmar el correo electrónico de verificación.",
  FIRST_STEPS_CREATE_PROFILE_ADM_INFO:
    "Ingrese los datos de usuario del administrador de la granja. Su registro se completará después de confirmar el correo electrónico de verificación.",
  GENERAL_BOX_NO_PANEL_STREAM: "Aún no hay actualizaciones",
  GENERAL_BOX_LAST_UPDATE: "Última comunicación: ",
  GENERAL_BOX_STOP_PIVOT_SUCCESS: "Orden de detención enviada.",
  GENERAL_BOX_STOP_PIVOT_FAILURE: "No se ha podido al detener el pivote",
  GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE:
    "No se ha podido comprobar si el pivote está conectado",
  EDIT_IRPD_DATASHEET_WARNING:
    "En la configuración de este equipo hay campos cuyos valores difieren de la hoja técnica actual.",
  EDIT_IRPD_SEND_SUCCESS: "Enviar",
  EDIT_IRPD_SEND_CANCEL: "Cancelar",
  GENERAL_BOX_IRPD_IS_ONLINE_FAILURE:
    "No se ha podido comprobar si el irpd está en línea",
  IRPD_BOX_STOP_COMMAND_SENT: "Orden de detención enviada correctamente.",
  IRPD_BOX_COMMAND_ERROR: "Error al enviar la orden.",
  IRPD_BOX_COMMAND_SENT: "Orden enviada correctamente.",
  IRPD_BOX_CONFIGURATION_SAVED: "Configuración enviada correctamente.",
  IRPD_BOX_CONFIGURATION_ERROR: "Error al enviar la configuración.",
  EDIT_FARM_CENTER_MAP_BUTTON: "Centro en el mapa",
  EDIT_FARM_SAVE_SUCCESS: "Granja guardada correctamente",
  EDIT_FARM_ERROR_NAME: "Tamaño de nombre no válido",
  EDIT_FARM_ERROR_BILLING_DATE: "Fecha de facturación no válida",
  EDIT_FARM_ERROR_INITIAL_LAT: "Latitud inicial no válida",
  EDIT_FARM_ERROR_INITIAL_LNG: "Longitud inicial no válida",
  EDIT_FARM_ERROR_LAT: "Latitud no válida",
  EDIT_FARM_ERROR_LNG: "Longitud no válida",
  EDIT_FARM_ERROR_ADDRESS: "Dirección no válida",
  EDIT_FARM_ERROR_POSTAL_CODE: "Código Postal no válido",
  EDIT_FARM_ERROR_CITY: "Ciudad no válida",
  EDIT_FARM_ERROR_STATE: "Estado no válido",
  EDIT_FARM_ERROR_COUNTRY: "País no válido",
  EDIT_FARM_ERROR_PHONE: "Número telefónico no válido",
  EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE:
    "Faltan días en sus rangos de energía. Completar todos los días de la semana.",
  EDIT_FARM_REPEATED_DAYS: "Este día ya se ha añadido a la lista.",
  EDIT_FARM_POWER_RANGES: "Rangos de Potencia",
  EDIT_FARM__POWER_RANGES_DESCRIPTION:
    "Los Rangos de Potencia se utilizan para calcular los costos de energía de los informes.",
  EDIT_FARM_ADD_POWER_RANGES: "Añadir Rangos de Potencia",
  EDIT_FARM_HOLLIDAYS: "VACACIONES",
  EDIT_FARM_ADD_HOLLIDAYS: "AÑADIR VACACIONES",
  DATE_PICKER_HOLIDAYS: "Seleccione una fecha",
  EDIT_FARM_DAY: "Día",
  EDIT_FARM_MONTH: "Mes",
  EDIT_FARM_START_TIME: "Fecha Inicio",
  EDIT_FARM_END_TIME: "Fecha Finalización",
  EDIT_FARM_INTERVAL: "Intervalo",
  EDIT_FARM_DURATION: "Duración",
  EDIT_FARM_TYPE: "Tipo",
  EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING:
    "Los rangos de potencia han cambiado, guarde los cambios anteriores para habilitar las teclas de abajo.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL: "Habilitar Informes",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT:
    "Presione la tecla para habilitar la generación de informes para los pivotes de la granja.",
  EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES:
    "Registrar Rangos de Potencia válidos para habilitar los informes.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING:
    "Sus informes se están generando.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_ENABLED:
    "Sus informes ya están habilitados.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED: "Su informe está activado.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL: "Recalcular informes",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION:
    "¿Está seguro de que quiere recalcular sus/n informes?",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT:
    "Presione la tecla para recalcular los informes para los pivotes de la granja.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE: "Recalcular",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS: "Informes habilitados.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR: "Error al habilitar informes.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS:
    "Se están recalculando los informes.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR:
    "Error al intentar recalcular los informes.",
  EDIT_FARM_PIVOT_REPORTS: "Informes Pivote",
  EDIT_FARM_IRPD_REPORTS: "Informes Bomba",
  PIVOT: "Pivote",
  PIVOT_PLURAL: "Pivotes",
  PIVOT_STATUS_STOPPED: "Detenido",
  PIVOT_STATUS_IN_MOVING: "Moviendo en Modo Seco",
  PIVOT_STATUS_IRRIGATING: "Irrigando",
  PIVOT_STATUS_PROBLEM: "Problema",
  PIVOT_STATUS_PROGRAMMED: "Programado",
  PIVOT_STATUS_PANEL_ON: "Panel encendido",
  PIVOT_STATUS_PANEL_OFF: "Panel apagado",
  PIVOT_STATUS_PRESSURIZING: "Esperando la presión",
  PIVOT_FIELD_NAME: "Nombre",
  PAINEL_STREAM_REASON_0: "Panel encendido",
  PAINEL_STREAM_REASON_1: "Moviendo en modo seco",
  PAINEL_STREAM_REASON_2: "Modo húmedo después de la presurización",
  PAINEL_STREAM_REASON_4: "Modo húmedo después de un corte de energía",
  PAINEL_STREAM_REASON_5: "Modo seco después de un corte de energía",
  PAINEL_STREAM_REASON_6: "Encendido tras un corte de energía y presurizado",
  PAINEL_STREAM_REASON_8: "Modo húmedo después del tiempo de pausa",
  PAINEL_STREAM_REASON_9: "Modo seco después del tiempo de pausa",
  PAINEL_STREAM_REASON_10:
    "Modo húmedo después del tiempo de pausa y de la presurización",
  PAINEL_STREAM_REASON_11: "Modo seco tras el inicio de los riegos programados",
  PAINEL_STREAM_REASON_16: "Después del riego programado",
  PAINEL_STREAM_REASON_18: "Modo húmedo después de la presurización",
  PAINEL_STREAM_REASON_21: "Pivote programado en modo seco",
  PAINEL_STREAM_REASON_22:
    "Pivote encendido y programado después de presurizado",
  PAINEL_STREAM_REASON_32: "Pivote programado en modo húmedo",
  PAINEL_STREAM_REASON_33: "Modo seco después de programado",
  PAINEL_STREAM_REASON_34: "Modo húmedo después del riego programado",
  PAINEL_STREAM_REASON_38: "Pivote húmedo después de un corte de energía",
  PAINEL_STREAM_REASON_39: "Fertirrigación iniciada",
  PAINEL_STREAM_REASON_65: "Continuación de la programación en seco",
  PAINEL_STREAM_REASON_66: "Continuación de la programación en húmedo",
  PAINEL_STREAM_REASON_98: "Irrigando",
  PAINEL_STREAM_REASON_100: "Encendido del panel",
  PAINEL_STREAM_REASON_101: "Panel apagado",
  PAINEL_STREAM_REASON_102: "Esperando la presurización",
  PAINEL_STREAM_REASON_103: "Pivote detenido con la presión encendida",
  PAINEL_STREAM_REASON_104: "Fertirrigación detenida",
  PAINEL_STREAM_REASON_240: "Detenido por el pluviómetro",
  PAINEL_STREAM_REASON_241:
    "Detenido antes del siguiente inicio de programación",
  PAINEL_STREAM_REASON_242: "Detenido por un factor desconocido",
  PAINEL_STREAM_REASON_243: "Detenido en el sector final",
  PAINEL_STREAM_REASON_244: "Programar riegos finalizado",
  PAINEL_STREAM_REASON_245: "Realizando la autoreversión",
  PAINEL_STREAM_REASON_246: "Se detuvo después de una subida de tensión",
  PAINEL_STREAM_REASON_247: "Detenido por tiempo de pausa",
  PAINEL_STREAM_REASON_248: "Detenido por baja presión",
  PAINEL_STREAM_REASON_249: "Detenido en la web",
  PAINEL_STREAM_REASON_250: "Detenido por el tiempo de presión",
  PAINEL_STREAM_REASON_251:
    "Detenido después de que el relé térmico se disparara",
  PAINEL_STREAM_REASON_252: "Detenido al finalizar el riego",
  PAINEL_STREAM_REASON_253: "Detenido tras un corte de energía",
  PAINEL_STREAM_REASON_254: "Botón STOP presionado",
  PAINEL_STREAM_REASON_255: "Detenido después de la desalineación",
  PAINEL_STREAM_REASON_256: "Sin comunicación",
  PANEL_STREAM_STATUS_0: "Detenido por la web",
  PANEL_STREAM_STATUS_1: "Avanzando",
  PANEL_STREAM_STATUS_2: "Retrocediendo",
  PANEL_STREAM_STATUS_3: "Esperando presión",
  PANEL_STREAM_STATUS_4: "Irrigando",
  PANEL_STREAM_STATUS_5: "Retraso de espera de la presión",
  PANEL_STREAM_STATUS_6: "Moviéndose a seco",
  PANEL_STREAM_STATUS_7: "Programado",
  PANEL_STREAM_STATUS_8: "Preparándose para encender",
  PANEL_STREAM_STATUS_9: "Detenido por el Tiempo de Pausa",
  PANEL_STREAM_STATUS_10: "Riego finalizado",
  PANEL_STREAM_STATUS_11: "Detenido por autoreversión",
  PANEL_STREAM_STATUS_12: "Detenido por internet",
  PANEL_STREAM_STATUS_13: "Detención manual",
  PANEL_STREAM_STATUS_14: "Panel encendido",
  PANEL_STREAM_STATUS_14_2: "Pivot on",
  PANEL_STREAM_STATUS_15: "Seguridad",
  PANEL_STREAM_STATUS_16: "Falla en la presión",
  PANEL_STREAM_STATUS_17: "Dirección de riego no válida",
  PANEL_STREAM_STATUS_18: "Falla de energía",
  PANEL_STREAM_STATUS_19: "Voltaje fuera del rango",
  PANEL_STREAM_STATUS_20: "Error parámetro",
  PANEL_STREAM_STATUS_21: "Error desconocido",
  PANEL_STREAM_STATUS_22: "Panel Apagado",
  PANEL_STREAM_STATUS_23: "Tiempo de Presión",
  PANEL_STREAM_STATUS_24: "Riego iniciado",
  PANEL_STREAM_STATUS_25: "Botón Stop presionado",
  PANEL_STREAM_STATUS_32: "Error Bomba",
  PANEL_STREAM_STATUS_33: "Moviéndose en seco por el pluviómetro",
  PANEL_STREAM_STATUS_34: "Detenido por el pluviómetro",
  PANEL_STREAM_STATUS_35: "Detenido por error del reloj",
  PANEL_STREAM_STATUS_36: "Fertirrigación iniciada",
  PANEL_STREAM_STATUS_37: "Fertirrigación detenida",
  PANEL_STREAM_STATUS_38: "Sin comunicación",
  PANEL_STREAM_STATUS_POWER_OFF: "Apagado",
  PANEL_STREAM_STATUS_NONE: "Estado desconocido",
  IRPD_STATUS_STOPPED: "Detenido",
  IRPD_STATUS_PUMPING: "Bombeando",
  IRPD_STATUS_PROBLEM: "Problema",
  IRPD_STATUS_PROGRAMMED: "Programado",
  IRPD_STATUS_ENERGIZED: "Energizado",
  IRPD_STATUS_WITHOUT_ENERGY: "Sin energía",
  SELECTED_IRPD_BACK_BUTTON_TEXT: "Atrás",
  SELECTED_IRPD_SAVE_BUTTON_TEXT: "Guardar",
  SELECTED_IRPD_SAVE_BUTTON_TEXT_DS: "Guardar / Establecer como hoja técnica",
  IRPD_STATUS_1: "Encendido a través de Internet",
  IRPD_STATUS_2: "Encendido manual",
  IRPD_STATUS_3: "Encendido después de un tiempo de pausa",
  IRPD_STATUS_4: "Encendido después de un corte de energía",
  IRPD_STATUS_5: "La bomba está programada",
  IRPD_STATUS_6: "El dispositivo está encendido",
  IRPD_STATUS_7: "La bomba está encendida por un sensor",
  IRPD_STATUS_8: "La bomba espera un tiempo después del fallo de alimentación",
  IRPD_STATUS_9: "Bomba encendida por radio remoto",
  IRPD_STATUS_192: "Horómetro corrupto",
  IRPD_STATUS_193: "Bomba controlada por una radio desconocida",
  IRPD_STATUS_246: "Bomba apagada por IMeter",
  IRPD_STATUS_247: "Horario caducado",
  IRPD_STATUS_248: "Horario incorrecto",
  IRPD_STATUS_249: "Bomba apagada por fin del horario",
  IRPD_STATUS_250: "Bomba apagada por un sensor",
  IRPD_STATUS_251: "Apagado",
  IRPD_STATUS_252: "Apagado tras un corte de energía",
  IRPD_STATUS_253: "Apagado después de un tiempo de pausa",
  IRPD_STATUS_254: "Apagado manualmente",
  IRPD_STATUS_255: "Detenido en internet",
  IRPD_CONFIG_FIELD_START_NOW: "Iniciar ahora",
  IRPD_CONFIG_FIELD_STOP_NOW: "Detener ahora",
  IRPD_FIELD_START_INTERVAL: "Inicio en",
  IRPD_CONFIG_FIELD_DONT_STOP: "No detener",
  IRPD_FIELD_END_INTERVAL: "Detener en",
  IRPD_FIELD_OFF: "Apagado",
  EDIT_IRPD_CANCEL: "¿Está seguro?",
  EDIT_IRPD_ERROR_RTCDATE: "RTC no válido",
  EDIT_IRPD_ERROR_HOUR_RANGE_MAX: "Hora máxima no válida",
  EDIT_IRPD_ERROR_HOUR_RANGE_MIN: "Hora mínima no válida",
  EDIT_IRPD_ERROR_ENERGY_TIME: "Tiempo de energía no válido",
  COUNTRY_AFGHANISTAN: "Afganistán",
  COUNTRY_SOUTH_AFRICA: "Sudáfrica",
  COUNTRY_ALBANIA: "Albania",
  COUNTRY_GERMANY: "Alemania",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTARCTICA: "Antarctica",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antigua y Barbuda",
  COUNTRY_SAUDI_ARABIA: "Arabia Saudita",
  COUNTRY_ALGERIA: "Argelia",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armenia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_AUSTRALIA: "Australia",
  COUNTRY_AUSTRIA: "Austria",
  COUNTRY_AZERBAIJAN: "Azerbaijan",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrain",
  COUNTRY_BANGLADESH: "Bangladesh",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Bielorrusia",
  COUNTRY_BELGIUM: "Bélgica",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermuda",
  COUNTRY_BOLIVIA: "Bolivia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bosnia & Herzegovina",
  COUNTRY_BOTSWANA: "Botswana",
  COUNTRY_BRAZIL: "Brasil",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgaria",
  COUNTRY_BURKINA_FASO: "Burkina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_BHUTAN: "Bhutan",
  COUNTRY_CAPE_VERDE: "Cabo Verde",
  COUNTRY_CAMEROON: "Camerún",
  COUNTRY_CAMBODIA: "Cambodia",
  COUNTRY_CANADA: "Canadá",
  COUNTRY_CANARY_ISLANDS: "Islas Canarias",
  COUNTRY_KAZAKHSTAN: "Kazakhstan",
  COUNTRY_CEUTA_AND_MELILLA: "Ceuta y Melilla",
  COUNTRY_CHAD: "Chad",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CYPRUS: "Chipre",
  COUNTRY_SINGAPORE: "Singapur",
  COUNTRY_COLOMBIA: "Colombia",
  COUNTRY_COMOROS: "Comoros",
  COUNTRY_CONGO_REPUBLIC: "Congo (Republica)",
  COUNTRY_NORTH_KOREA: "Corea del Norte",
  COUNTRY_SOUTH_KOREA: "Corea del Sur",
  COUNTRY_CTE_DIVOIRE: "Costa de Marfil",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croacia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CURAAO: "Curaçao",
  COUNTRY_DIEGO_GARCIA: "Diego Garcia",
  COUNTRY_DENMARK: "Dinamarca",
  COUNTRY_DJIBOUTI: "Yibuti",
  COUNTRY_DOMINICA: "Dominicana",
  COUNTRY_EGYPT: "Egipto",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_UNITED_ARAB_EMIRATES: "Emiratos Árabes Unidos",
  COUNTRY_ECUADOR: "Ecuador",
  COUNTRY_ERITREA: "Eritrea",
  COUNTRY_SLOVAKIA: "Eslovaquia",
  COUNTRY_SLOVENIA: "Eslovenia",
  COUNTRY_SPAIN: "España",
  COUNTRY_UNITED_STATES: "Estados Unidos",
  COUNTRY_ESTONIA: "Estonia",
  COUNTRY_ETHIOPIA: "Etiopía",
  COUNTRY_FIJI: "Fiji",
  COUNTRY_PHILIPPINES: "Filipinas",
  COUNTRY_FINLAND: "Finlandia",
  COUNTRY_FRANCE: "Francia",
  COUNTRY_GABON: "Gabón",
  COUNTRY_GAMBIA: "Gambia",
  COUNTRY_GHANA: "Ghana",
  COUNTRY_GEORGIA: "Georgia",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_UNITED_KINGDOM: "Reino Unido",
  COUNTRY_GRENADA: "Granada",
  COUNTRY_GREECE: "Grecia",
  COUNTRY_GREENLAND: "Groenlandia",
  COUNTRY_GUADELOUPE: "Guadalupe",
  COUNTRY_GUAM: "Guam",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUERNSEY: "Guernsey",
  COUNTRY_GUYANA: "Guyana",
  COUNTRY_FRENCH_GUIANA: "Guyana Francesa",
  COUNTRY_GUINEA: "Guinea",
  COUNTRY_EQUATORIAL_GUINEA: "Guinea Ecuatorial",
  COUNTRY_GUINEA_BISSAU: "Guinea-Bissau",
  COUNTRY_HAITI: "Haití",
  COUNTRY_NETHERLANDS: "Países Bajos",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG: "Hong Kong",
  COUNTRY_HUNGARY: "Hungría",
  COUNTRY_YEMEN: "Yemen",
  COUNTRY_BOUVET_ISLAND: "isla Bouvet",
  COUNTRY_ASCENSION_ISLAND: "Isla Ascensión",
  COUNTRY_CLIPPERTON_ISLAND: "Isla Clipperton",
  COUNTRY_ISLE_OF_MAN: "Isla de Man",
  COUNTRY_CHRISTMAS_ISLAND: "Isla Christmas",
  COUNTRY_PITCAIRN_ISLANDS: "Islas Pitcairn",
  COUNTRY_RUNION: "Réunion",
  COUNTRY_LAND_ISLANDS: "Islas Åland",
  COUNTRY_CAYMAN_ISLANDS: "Islas Caimán",
  COUNTRY_COCOS_KEELING_ISLANDS: "Islas Cocos (Keeling)",
  COUNTRY_COOK_ISLANDS: "Islas Cook",
  COUNTRY_FAROE_ISLANDS: "Islas Faroe",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS:
    "Georgia del Sur e Islas Sandwich del Sur",
  COUNTRY_HEARD_AND_MCDONALD_ISLANDS: "Islas Heard y McDonald",
  COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS: "Islas Falkland (Islas Malvinas)",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Islas Mariana del Norte",
  COUNTRY_MARSHALL_ISLANDS: "Islas Marshall",
  COUNTRY_US_OUTLYING_ISLANDS: "Estados Unidos Islas Outlying",
  COUNTRY_NORFOLK_ISLAND: "Islas Norfolk",
  COUNTRY_SOLOMON_ISLANDS: "Islas Solomon",
  COUNTRY_SEYCHELLES: "Seychelles",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Islas Turks y Caicos",
  COUNTRY_US_VIRGIN_ISLANDS: "Estados Unidos Islas Vírgenes",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Islas Vírgenes Británicas",
  COUNTRY_INDIA: "India",
  COUNTRY_INDONESIA: "Indonesia",
  COUNTRY_IRAN: "Irán",
  COUNTRY_IRAQ: "Iraq",
  COUNTRY_IRELAND: "Irlanda",
  COUNTRY_ICELAND: "Islandia",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Italia",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japón",
  COUNTRY_JERSEY: "Jersey",
  COUNTRY_JORDAN: "Jordania",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KOSOVO: "Kosovo",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LESOTHO: "Lesotho",
  COUNTRY_LATVIA: "Latvia",
  COUNTRY_LEBANON: "Líbano",
  COUNTRY_LIBERIA: "Liberia",
  COUNTRY_LIBYA: "Libia",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Lituania",
  COUNTRY_LUXEMBOURG: "Luxemburgo",
  COUNTRY_MACAU: "Macao",
  COUNTRY_MACEDONIA_FYROM: "Macedonia (FYROM)",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAYSIA: "Malasia",
  COUNTRY_MALAWI: "Malawi",
  COUNTRY_MALDIVES: "Maldivas",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MOROCCO: "Marruecos",
  COUNTRY_MARTINIQUE: "Martinica",
  COUNTRY_MAURITIUS: "Mauritius",
  COUNTRY_MAURITANIA: "Mauritania",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "México",
  COUNTRY_MICRONESIA: "Micronesia",
  COUNTRY_MOZAMBIQUE: "Mozambique",
  COUNTRY_MOLDOVA: "Moldova",
  COUNTRY_MONACO: "Mónaco",
  COUNTRY_MONGOLIA: "Mongolia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MYANMAR_BURMA: "Myanmar (Burma)",
  COUNTRY_NAMIBIA: "Namibia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NICARAGUA: "Nicaragua",
  COUNTRY_NIGER: "Niger",
  COUNTRY_NIGERIA: "Nigeria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORWAY: "Noruega",
  COUNTRY_NEW_CALEDONIA: "Nueva Caledonia",
  COUNTRY_NEW_ZEALAND: "Nueva Zelanda",
  COUNTRY_OMAN: "Omán",
  COUNTRY_CARIBBEAN_NETHERLANDS: "Países Bajos del Caribe",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PALESTINE: "Palestina",
  COUNTRY_PANAMA: "Panamá",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua Nueva Guinea",
  COUNTRY_PAKISTAN: "Paquistán",
  COUNTRY_PARAGUAY: "Paraguay",
  COUNTRY_PERU: "Perú",
  COUNTRY_FRENCH_POLYNESIA: "Polinesia Francesa",
  COUNTRY_POLAND: "Polonia",
  COUNTRY_PUERTO_RICO: "Puerto Rico",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_QATAR: "Qatar",
  COUNTRY_KENYA: "Kenia",
  COUNTRY_KYRGYZSTAN: "Kirguizistán",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "República Centroafricana",
  COUNTRY_CONGO_DRC: "Congo (DRC)",
  COUNTRY_DOMINICAN_REPUBLIC: "República Dominicana",
  COUNTRY_CZECH_REPUBLIC: "República Checa",
  COUNTRY_ROMANIA: "Rumania",
  COUNTRY_RWANDA: "Rwanda",
  COUNTRY_RUSSIA: "Rusia",
  COUNTRY_WESTERN_SAHARA: "Sáhara Occidental",
  COUNTRY_ST_PIERRE_AND_MIQUELON: "St. Pierre y Miquelon",
  COUNTRY_AMERICAN_SAMOA: "American Samoa",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_ST_HELENA: "St. Helena",
  COUNTRY_ST_LUCIA: "St. Lucia",
  COUNTRY_ST_BARTHLEMY: "St. Barthélemy",
  COUNTRY_ST_KITTS_AND_NEVIS: "St. Kitts & Nevis",
  COUNTRY_ST_MARTIN: "St. Martin",
  COUNTRY_SINT_MAARTEN: "Sint Maarten",
  COUNTRY_SO_TOM_AND_PRNCIPE: "São Tomé y Príncipe",
  COUNTRY_ST_VINCENT_AND_GRENADINES: "San Vicente y las Granadinas",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SIERRA_LEONE: "Sierra Leona",
  COUNTRY_SERBIA: "Serbia",
  COUNTRY_SYRIA: "Siria",
  COUNTRY_SOMALIA: "Somalia",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SWAZILAND: "Suazilandia",
  COUNTRY_SUDAN: "Sudán",
  COUNTRY_SOUTH_SUDAN: "Sudán del Sur",
  COUNTRY_SWEDEN: "Suecia",
  COUNTRY_SWITZERLAND: "Suiza",
  COUNTRY_SURINAME: "Surinam",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Svalbard & Jan Mayen",
  COUNTRY_TAJIKISTAN: "Tayikistán",
  COUNTRY_THAILAND: "Tailandia",
  COUNTRY_TAIWAN: "Taiwán",
  COUNTRY_TANZANIA: "Tanzania",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY:
    "Territorio Británico del Océano Índico",
  COUNTRY_FRENCH_SOUTHERN_TERRITORIES: "Territorios Australes Franceses",
  COUNTRY_TIMOR_LESTE: "Timor Oriental",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad & Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristan da Cunha",
  COUNTRY_TUNISIA: "Túnez",
  COUNTRY_TURKMENISTAN: "Turkmenistán",
  COUNTRY_TURKEY: "Turquía",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UKRAINE: "Ucrania",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_URUGUAY: "Uruguay",
  COUNTRY_UZBEKISTAN: "Uzbekistán",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Ciudad del Vaticano",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnam",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis & Futuna",
  COUNTRY_ZAMBIA: "Zambia",
  COUNTRY_ZIMBABWE: "Zimbabwe",
  IRRIGATION_BOX_CONVENTIONAL_BUTTON: "Enviar Orden",
  IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG: "Orden de riego simple enviada.",
  IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG: "Orden enviada correctamente.",
  IRRIGATION_BOX_BY_ANGLE_ERROR_MSG: "Error al enviar la orden.",
  IRRIGATION_BOX_GENERIC_ERROR_MSG: "Error al enviar la orden.",
  IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG:
    "Error al enviar la orden. Verifique que todos los campos están completados.",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE: "Confirme su acción",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION:
    "Por favor, confirme la acción de detención para ${}",
  IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION:
    "Por favor, confirme la acción de riego hacia adelante para ${}",
  IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION:
    "Por favor, confirme la acción de riego inverso para ${}",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES: "Enviar",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO: "Cancelar",
  MOBILE_MODAL_TITLE_SIMPLE_IRRIGATION: "Riego Simple",
  MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION: "Riego Programado",
  MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION: "Riego Simple",
  MOBILE_MODAL_TITLE_LANGUAGES: "Elija un idioma",
  MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION: "Riego por Ángulo",
  MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION: "Riego por Segmentos",
  PIVOT_ACTION_FIELD_DIRECTION: "Dirección",
  PIVOT_ACTION_FIELD_MODE: "Modo Operación",
  PIVOT_ACTION_FIELD_ENDGUN: "Endgun",
  PIVOT_ACTION_FIELD_PRECIPITATION: "Precipitación",
  PIVOT_ACTION_FIELD_START_DATE: "Inicio en",
  PIVOT_ACTION_FIELD_START_TIME: "Fecha Inicio",
  PIVOT_ACTION_FIELD_END_DATE: "Finalizar el",
  PIVOT_ACTION_FIELD_END_TIME: "Fecha Finalización",
  PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN: "Desconocido",
  PIVOT_ACTION_VALUE_DIRECTION_STOPPED: "Detenido",
  PIVOT_ACTION_VALUE_DIRECTION_PROGRESS: "Avanzar",
  PIVOT_ACTION_VALUE_DIRECTION_REVERSE: "Retroceder",
  PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS: "Inicio Avanzar",
  PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE: "Inicio Retroceder",
  PIVOT_ACTION_VALUE_DRY: "Seco",
  PIVOT_ACTION_VALUE_MODE_WET: "Húmedo",
  PIVOT_ACTION_FIELD_PERCENT: "Por ciento",
  PIVOT_ACTION_FIELD_START_NOW: "Iniciar Ahora",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME: "Luego del Tiempo de Pausa",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1: "Luego del Tiempo de Pausa 1",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2: "Luego del Tiempo de Pausa 2",
  PIVOT_ACTION_FIELD_WITHOUT_STOP: "Sin detención",
  PIVOT_ACTION_INVALID_DATE: "Fecha no válida",
  NAV_ITEM_LOGOUT: "Cierre de sesión",
  NAV_ITEM_LANGUAGE: "Lenguaje",
  NAV_ITEM_PROFILE: "Editar Perfil",
  NAV_ITEM_FIRST_STEPS: "Primeros Pasos",
  NAV_ITEMS_NEW_FARM: "Nueva Granja",
  SELECTED_FARM_NO_PIVOTS: "Esta granja no tiene pivotes.",
  SELECTED_FARM_CENTRAL_ONLINE: "Central en línea",
  SELECTED_FARM_PIVOT_ONLINE: "pivote online",
  SELECTED_FARM_CENTRAL_FILTER_BY: "Filtrar equipos por",
  SELECTED_FARM_CENTRAL_OFFLINE: "Central fuera de línea",
  SELECTED_FARM_PIVOT_OFFLINE: "Pivot offline",
  SELECTED_FARM_WATER_LIMIT_EXCEEDED:
    "Se ha superado el límite de consumo de agua",
  LOADING: "Cargando",
  NOT_FOUND_TITLE: "Ops!",
  NOT_FOUND_ERROR_TEXT: "Página no encontrada",
  NOT_FOUND_BACK_BUTTON_TEXT: "PÁGINA DE INICIO",
  NOT_FOUND_TOKEN: "La sesión ha expirado. Inicie sesión de nuevo.",
  EDIT_PIVOT_V5_RELEVANT_INFO: "Información relevante",
  EDIT_PIVOT_V5_PIVOT_TITLE_SEGMENTS: "Segmentos",
  EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG: "Ajustes de presión",
  EDIT_PIVOT_V5_AUTOREVERSION: "Autoreversion",
  EDIT_PIVOT_V5_AUTOREVERSION_30S: "30 segundos",
  EDIT_PIVOT_V5_AUTOREVERSION_1MIN: "1 minuto",
  EDIT_PIVOT_V5_AUTOREVERSION_2MIN: "2 minutos",
  EDIT_PIVOT_V5_REFERENCE_NORTH: "Utilizar el Norte como referencia",
  EDIT_PIVOT_V5_REFERENCE_NORTH_SELECT: "Establecer referencia de pivote",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_END_ADORNMENT: "s",
  EDIT_PIVOT_V5_AUTOREVERSION_DISABLED: "Inhabilitado",
  EDIT_PIVOT_ERROR_NAME: "Tamaño de nombre no válido",
  EDIT_PIVOT_V5_ERROR_POTENCY: "Valor de potencia no válido",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LAT: "Latitud central no válida",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LNG: "Longitud central no válida",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LAT:
    "Latitud de referencia inicial no válida",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LNG:
    "Longitud de referencia inicial no válida",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LAT:
    "Valor de latitud de referencia final no válido",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LNG:
    "Valor de longitud de referencia final no válido",
  EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION:
    "Error al enviar la configuración:",
  EDIT_PIVOT_V5_ERROR_RADIUS: "Valor de radio no válido",
  EDIT_PIVOT_V5_ERROR_FLOW: "Valor de flujo no válido",
  EDIT_PIVOT_V5_ERROR_SPEED: "Valor de radio no válido",
  EDIT_PIVOT_V5_ERROR_AREA: "Valor de área no válido",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_STABLE_TIME:
    "Valor de tiempo de tensión estable no válido",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_REF: "Valor de referencia de tensión no válido",
  EDIT_PIVOT_V5_ERROR_PUMP_TIMEOUT:
    "Valor de tiempo de espera de la bomba no válido",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_DELAY:
    "Valor de retraso de la presión de la bomba no válido",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_SWITCH:
    "Valor del interruptor de presión de la bomba no válido",
  EDIT_PIVOT_V5_ERROR_PUMP_SOFT_START_TIME:
    "Valor de hora de inicio suave de la bomba no válido",
  EDIT_PIVOT_V5_ERROR_ANGLE_START: "Valor del ángulo de inicio no válido",
  EDIT_PIVOT_V5_ERROR_ANGLE_END: "Valor de ángulo final no válido",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START: "Valor de tiempo de inicio no válido",
  EDIT_PIVOT_V5_ERROR_PIVOT_AVERAGE_POWER:
    "Valor de potencia promedio del pivote no válido",
  EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER:
    "Valor de potencia promedio del canal no válido",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END: "Valor de tiempo final no válido",
  EDIT_PIVOT_V5_ERROR_POWER_DELAY: "Valor de retraso de potencia no válido",
  EDIT_PIVOT_V5_ERROR_SENSOR_SCALE_END:
    "Valor final de la escala del sensor no válido",
  EDIT_PIVOT_V5_ERROR_PIVOT_SEGMENT: "Segmentos de pivote no válidos",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS: "Segmentos",
  EDIT_PIVOT_V5_ENDGUN: "Endgun",
  EDIT_PIVOT_V5_SAVE_SUCCESS: "Configuración del pivote enviada",
  EDIT_PIVOT_V5_SEGMENT_WARNING:
    "Llenar todos los segmentos y no exceder el ángulo de trabajo",
  EDIT_PIVOT_V5_SAVE_ERROR:
    "Error al guardar el pivote. Se deben rellenar todos los campos.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK: "Control de tensión",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_DELETE_ALL: "Borrar todo",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START: "Inicio: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END: "Fin: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_FORWARD: "Avanzar: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_REVERSE: "Retroceder: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_MODE: "${}, ${}%",
  PIVOT_CONFIG_V5_CONFIG_HISTORY: "Historial de Configuraciones",
  PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM: "Borrar tabla",
  PIVOT_CONFIG_V5_SEGMENT_ERROR: "Puede crear hasta ${} segmentos",
  EDIT_PIVOT_V5_WEEKDAY_MONDAY: "LUN",
  EDIT_PIVOT_V5_WEEKDAY_TUESDAY: "MAR",
  EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY: "MIER",
  EDIT_PIVOT_V5_WEEKDAY_THURSDAY: "JUE",
  EDIT_PIVOT_V5_WEEKDAY_FRIDAY: "VIERI",
  EDIT_PIVOT_V5_WEEKDAY_SATURDAY: "SÁB",
  EDIT_PIVOT_V5_WEEKDAY_SUNDAY: "DOM",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK: "Días de la Semana",
  EDIT_PIVOT_V5_CONFIGURATIONS: "Configuraciones",
  COMMAND_SENT_WAITING_CONFIRMATION:
    "Orden enviada. Esperar por confirmación del dispositivo",
  COMMAND_CONFIRMED: "Orden recibida por el dispositivo.",
  COMMAND_ERROR: "Error al ejecutar la orden",
  NAV_NO_NOTIFICATIONS: "Usted no tiene notificaciones.",
  CURRENT_ANGLE: "Ángulo actual: ",
  POWER_RANGE_POINT: "Pico",
  POWER_RANGE_OUT_OF_POINT: "Fuera del Pico",
  POWER_RANGE_REDUCED: "Reducido",
  POWER_RANGE_TIME_START: "Inicio",
  POWER_RANGE_TIME_END: "Finalización",
  POWER_RANGE_TYPE: "Tipo",
  POWER_RANGE_DELETE_CONFIRMATION:
    "¿Está seguro que desea eliminar este rango de energía?",
  HISTORIC_SEGMENT_IRRIGATION_START_MODE: "Modo Inicio: ",
  HISTORIC_SEGMENT_IRRIGATION_START_AT: "Inicio en: ",
  HISTORIC_SEGMENT_IRRIGATION_END_MODE: "Modo de Finalización: ",
  HISTORIC_SEGMENT_IRRIGATION_SEGMENT: "Segmento ${}:",
  HISTORIC_SEGMENT_IRRIGATION_START_ANGLE: "Ángulo Inicial ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_ANGLE: "Ángulo Final ${}",
  HISTORIC_SEGMENT_IRRIGATION_FORWARD_INFO: "Avanzar - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO: "Retroceder - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_AT: "Finalizar en: ",
  HISTORIC_SEGMENT_IRRIGATION_ROUNDS: "Vueltas: ${}",
  HISTORIC_SEGMENT_IRRIGATION_START_DATE: "Inicio: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_DATE: "Finalización: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_MODE: "Modo Autoreversion: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_QUANTITY: "Vueltas: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS: "No Detener",
  HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE: "Detener por Ángulo",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE: "Detener por Fecha",
  HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR:
    "Detener por Sector de Finalización",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS: "Detener por Vueltas",
  HISTORIC_SEGMENT_IRRIGATION_AUTO_REVERSIONS: "Auto Reversiones ${}",
  HISTORIC_SEGMENT_IRRIGATION_NOW: "Inmediatamente",
  HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE: "En una fecha",
  SEGMENT_IRRIGATION_FIELD_START_NOW: "¿Iniciar ahora?",
  SEGMENT_IRRIGATION_FIELD_END_BY_TIME: "¿Finalizar por tiempo?",
  SEGMENT_IRRIGATION_FIELD_END_BY_LAPS: "¿Finalizar por vueltas?",
  SEGMENT_IRRIGATION_FIELD_END_BY_SEGMENT: "¿Finalizar por segmentos?",
  SEGMENT_IRRIGATION_FIELD_NEVER_ENDS: "Nunca finaliza.",
  SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS: "Número de vueltas",
  SEGMENT_IRRIGATION_FIELD_LAPS: "Vuelta(s)",
  SEGMENT_IRRIGATION_BUTTON_FORWARD: "INICIAR AVANCE",
  SEGMENT_IRRIGATION_BUTTON_REVERSE: "INICIAR RETROCESO",
  DRAWER_HOME: "Inicio",
  DRAWER_FARMS: "Granjas",
  DRAWER_REPORTS: "Informes",
  NOTIFICATIONS_DRAWER_TITLE: "Notificaciones",
  GENERAL_BOX_PRESSURE_LABEL: "${} bar",
  LOGIN_WELCOME: "Bienvenido",
  LOGIN_EMAIL: "Correo electrónico",
  LOGIN_NEW_USER: "¿Cliente nuevo?",
  LOGIN_SIGN_IN: "Iniciar sesión",
  LOGIN_SIGN_UP: "Registrarse",
  LOGIN_USERNAME_OR_EMAIL: "Nombre de usuario o correo electrónico",
  LOGIN_PASSWORD: "Contraseña",
  LOGIN_OR: "o",
  LOGIN_FORGET_PASSWORD: "¿Olvidó el usuario o la contraseña?",
  LOGIN_GOOGLE_SIGN_IN: "Iniciar sesión con Google",
  LOGIN_RECOVER_PASSWORD: "Recuperar su contraseña",
  LOGIN_GO_BACK: "Volver",
  LOGIN_SUCCESSFUL: "El usuario ha iniciado la sesión correctamente.",
  SIGN_UP_FOOTER: "Rellene todos los campos del formulario para continuar.",
  SIGN_UP_WELCOME: "Bienvenido a la plataforma de Irricontrol.",
  SIGN_UP_WELCOME_AUX: "Rellene el formulario para completar su registro:",
  SIGN_UP_EMAIL: "Correo electrónico",
  SIGN_UP_REPEAT_EMAIL: "Repita correo electrónico",
  SIGN_UP_NAME: "Nombre",
  SIGN_UP_LAST_NAME: "Apellido",
  SIGN_UP_USERNAME: "Nombre usuario",
  SIGN_UP_REPEAT_PASSWORD: "Repita su contraseña",
  SIGN_UP_BACK: "Atrás",
  SIGN_UP_NEXT: "Siguiente",
  SIGN_UP_WARNING_SUCCESS: "Registro completado correctamente",
  LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED:
    "Nombre de usuario y/o contraseña no proporcionados",
  LOGIN_ERROR_GOOGLE_USER_NOT_REGISTERED:
    "El usuario de Google no está registrado",
  LOGIN_ERROR_DEFAULT: "La autenticación ha fallado",
  LOGIN_ERROR_SERVER_OFFLINE: "Servidor desconectado",
  LOGIN_ERROR_INVALID_CREDENTIALS:
    "El nombre de usuario o la contraseña son incorrectos",
  SIGN_UP_CONFIRMATION_RETURN:
    "¿Está seguro de que quiere abandonar la página sin completar el registro?",
  SIGN_UP_CONFIRMATION_SIGN_UP: "¿Confirma todos sus datos?",
  SIGN_UP_BUTTON_CONFIRM: "Confirmar",
  CREATE_DEVICE_MODAL_TITLE: "Registrar equipos",
  CREATE_DEVICE_MODAL_CENTRAL: "Central",
  CREATE_DEVICE_MODAL_EQUIPMENT: "Tipo de Equipo",
  CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER: "Nombre del Equipo",

  CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER: "Hardware Id",

  CREATE_DEVICE_MODAL_CREATE_DEVICE: "Registrar Dispositivos",
  CREATE_DEVICE_MODAL_CONTROLLER_CODE: "Radio Controlador",
  CREATE_DEVICE_MODAL_GPS_CODE: "Radio GPS",
  CREATE_DEVICE_MODAL_PIVOT: "Pivote",
  CREATE_DEVICE_MODAL_GPS: "GPS",
  CREATE_DEVICE_MODAL_REPEATER: "Repetidor",
  CREATE_DEVICE_MODAL_REPEATER_CODE: "Radio Repetidor",
  CREATE_DEVICE_MODAL_CODE_READ_SUCCESS: "Código leído correctamente",
  CREATE_DEVICE_MODAL_CODE_READ_FAIL: "Error de lectura de código",
  CREATE_DEVICE_MODAL_CREATE: "Crear",
  CREATE_DEVICE_MODAL_ERROR_NOT_VALID: "Radio no válida",
  CREATE_DEVICE_MODAL_ERROR_TAKEN: "Este dispositivo ya está ocupado",
  CREATE_DEVICE_MODAL_ERROR_NOT_MONITOR: "Este dispositivo no es un GPS",
  CREATE_DEVICE_MODAL_ERROR_NOT_CONTROL: "Este dispositivo no es un Pivote",
  CREATE_DEVICE_MODAL_ERROR_NOT_REPEATER: "Este dispositivo no es un Repetidor",
  CREATE_DEVICE_MODAL_ERROR_SERVER: "Error del servidor",
  CREATE_DEVICE_MODAL_SUCCESS: "El dispositivo se ha creado correctamente",
  EDIT_PROFILE_TITLE: "Perfil de Usuario",
  EDIT_PROFILE_ERROR_NAME: "Tamaño de nombre no válido",
  EDIT_PROFILE_ERROR_LAST_NAME: "Tamaño de nombre no válido",
  EDIT_PROFILE_ERROR_USERNAME: "Tamaño de nombre de usuario no válido",
  EDIT_PROFILE_ERROR_EMAIL: "Dirección de correo electrónico no válida",
  EDIT_PROFILE_ERROR_CONFIRM_EMAIL: "Dirección de correo electrónico no válida",
  EDIT_PROFILE_ERROR_BIRTHDAY: "Fecha no válida",
  EDIT_PROFILE_ERROR_COUNTRY_CODE: "Código de país no válido",
  EDIT_PROFILE_ERROR_CELLPHONE: "Número de teléfono celular no válido",
  EDIT_PROFILE_ERROR_LANDLINE: "Número de teléfono fijo no válido",
  EDIT_PROFILE_ERROR_ADDRESS: "Dirección no válida",
  EDIT_PROFILE_ERROR_NUMBER_HOUSE: "Número de casa no válido",
  EDIT_PROFILE_ERROR_NEIGHBORHOOD: "Vecindad no válida",
  EDIT_PROFILE_ERROR_CITY: "Ciudad no válida",
  EDIT_PROFILE_ERROR_STATE: "Estado no válido",
  EDIT_PROFILE_ERROR_COUNTRY: "País no válido",
  EDIT_PROFILE_ERROR_ZIPCODE: "Código postal no válido",
  INVALID_CPF: "Código de documento no válido",
  EDIT_PROFILE_ERROR_PASSWORD: "Contraseña no válida",
  EDIT_PROFILE_ERROR_PASSWORD2: "Contraseña no válida",
  EDIT_PROFILE_NAME: "Nombre",
  EDIT_PROFILE_LAST_NAME: "Apellido",
  EDIT_PROFILE_EMAIL: "Correo electrónico",
  EDIT_PROFILE_BIRTHDAY: "Cumpleaños",
  EDIT_PROFILE_CELLPHONE: "Número teléfono celular",
  EDIT_PROFILE_LANDLINE: "Número de teléfono fijo",
  EDIT_PROFILE_COUNTRY_CODE: "Código de País",
  EDIT_PROFILE_ADDRESS: "Dirección",
  EDIT_PROFILE_NUMBER_HOUSE: "Número",
  EDIT_PROFILE_NEIGHBORHOOD: "Vecindad",
  EDIT_PROFILE_CITY: "Ciudad",
  EDIT_PROFILE_STATE: "Estado",
  EDIT_PROFILE_COUNTRY: "País",
  EDIT_PROFILE_ZIPCODE: "Código Postal",
  EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE: "Código de documento oficial",
  PROFILE_BOX_CONVENTIONAL_ERROR_MSG: "Error al editar el perfil.",
  PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG: "Perfil de Usuario enviado",
  EDIT_PROFILE_CONFIRM_PASSWORD: "Contraseña",
  EDIT_PROFILE_PASSWORD: "Confirmar contraseña",
  EDIT_PROFILE_CONFIRM_EMAIL: "Confirmar correo electrónico",
  EDIT_PROFILE_CURRENT_PASSWORD: "Contraseña Actual",
  EDIT_PROFILE_NEW_PASSWORD: "Contraseña Nueva",
  EDIT_PROFILE_CONFIRM_NEW_PASSWORD: "Confirmar Nueva contraseña",
  EDIT_PROFILE_PERSONAL_INFO: "Información Personal",
  EDIT_PROFILE_CONTACT_ADDRESS: "Dirección de Contacto:",
  EDIT_PROFILE_PASSWORD_CHANGE: "Cambio de contraseña:",
  SIMPLE_IRRIGATION_FIELD_DIRECTION: "Dirección",
  SIMPLE_IRRIGATION_FIELD_MODE: "Modo Operación",
  SIMPLE_IRRIGATION_FIELD_PERCENT: "Por ciento",
  SIMPLE_IRRIGATION_FIELD_MM: "Precipitación",
  SIMPLE_IRRIGATION_FIELD_BY_ANGLE: "¿Detener por ángulo?",
  SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP: "Ángulo final",
  SIMPLE_IRRIGATION_VALUE_INJECTION_ON: "Encendido",
  SIMPLE_IRRIGATION_VALUE_INJECTION_OFF: "Apagado",
  SIMPLE_IRRIGATION_VALUE_FORWARD: "Avanzar",
  SIMPLE_IRRIGATION_VALUE_REVERSE: "Retroceder",
  SIMPLE_IRRIGATION_VALUE_DRY: "Seco",
  SIMPLE_IRRIGATION_VALUE_WET: "Húmedo",
  SIMPLE_IRRIGATION_FIELD_STOP_MODE: "Modo Detener",
  SIMPLE_IRRIGATION_NEVER_ENDS: "No Detener",
  SIMPLE_IRRIGATION_END_SECTOR: "Sector de Finalización",
  SIMPLE_IRRIGATION_BY_ROUNDS: "Por Vueltas",
  SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP: "Vueltas",
  FARM: "Granja",
  FARMS: "Granjas",
  FARM_FIELD_SEARCH_BAR: "Buscar",
  FARM_FIELD_NAME: "Nombre de Granja",
  FARM_FIELD_BILLING_DATE: "Día de facturación de energía",
  FARM_FIELD_LAT: "Latitud",
  FARM_FIELD_LNG: "Longitud",
  FARM_FIELD_PHONE: "Teléfono",
  FARM_FIELD_ADDRESS: "Dirección de Calle",
  FARM_FIELD_POSTAL_CODE: "Código Postal",
  FARM_FIELD_CITY: "Ciudad",
  FARM_FIELD_STATE: "Estado",
  FARM_FIELD_COUNTRY: "País",
  FARM_FIELD_START_INTERVAL: "Fecha Inicio",
  FARM_FIELD_END_INTERVAL: "Hora Finalización",
  FARM_FIELD_TYPE_INTERVAL: "Tipo",
  FARM_VALUE_RUSH_HOUR: "Tiempo de Pausa",
  FARM_VALUE_OFF_PEAK_TIME: "Tiempo de inactividad",
  FARM_VALUE_REDUCED_TIME: "Tiempo reducido",
  HISTORIC_SIMPLE_IRRIGATION_CREATED_AT: "Creado el: ${}",
  HISTORIC_CREATED_BY: "Enviado por: ",
  HISTORIC_CREATED_ON_HARDWARE: "Creado en el dispositivo",
  HISTORIC_PAINEL_STREAM_REASON: "Razón:",
  HISTORIC_PAINEL_STREAM_REASON_VALUE: "${}",
  HISTORIC_PAINEL_STREAM_WITH_ENERGY: "Energizado",
  HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY: "No energizado",
  HISTORIC_PAINEL_STREAM_ALIGNED: "Alineado",
  HISTORIC_PAINEL_STREAM_NOT_ALIGNED: "No alineado",
  HISTORIC_PAINEL_STREAM_HOURMETER: "Horómetro: ",
  HISTORIC_PAINEL_STREAM_POWER: "Voltaje: ",
  HISTORIC_PAINEL_STREAM_WITH_PRESSURE: "Con presión",
  HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE:
    "Tiempo de espera de la presión de la bomba",
  HISTORIC_PAINEL_STREAM_REVERSIONS: "Reversiones ejecutadas: ${} veces",
  HISTORIC_GPS_STREAM_CREATED_HUMANIZED: "${} atrás",
  HISTORIC_GPS_STREAM_CREATED: "${}",
  HISTORIC_GPS_STREAM_POSITION: "Posición: ${},${}.",
  HISTORIC_GPS_STREAM_REASON: "Razón:",
  HISTORIC_GPS_STREAM_REASON_VALUE: "${}",
  HISTORIC_GPS_STREAM_SPEED: "Percentímetro: ",
  HISTORIC_GPS_STREAM_HOURMETER: "Horómetro: ",
  HISTORIC_GPS_STREAM_WATER_PRESSURE: "Última presión de la torre: ",
  HISTORIC_GPS_STREAM_CENTER_PRESSURE: "Presión Centro: ${} bar",
  HISTORIC_GPS_STREAM_DIRECTION: "Dirección: ",
  HISTORIC_PIVOT_ACTION_STOP: "Detener Pivote",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD: "${} | ${}",
  HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED: "${} atrás",
  HISTORIC_PIVOT_ACTION_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_START: "Inicio: ",
  HISTORIC_PIVOT_ACTION_START_NOW: "Inmediatamente",
  HISTORIC_PIVOT_ACTION_END: "Detención programada: ",
  HISTORIC_PIVOT_ACTION_NEVER_END: "No detener",
  HISTORIC_PIVOT_ACTION_MANUAL: "Creado  manualmente",
  HISTORIC_PIVOT_ACTION_USER: "Por usuario: ",
  HISTORIC_PIVOT_ACTION_DIRECTION: "Dirección: ",
  HISTORIC_PIVOT_ACTION_ENDGUN: "Percentímetro: ${} %, ${} mm",
  HISTORIC_PIVOT_ACTION_MODE: "Modo: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW: "Por Ángulo",
  HISTORIC_PIVOT_ACTION_VRI_START: "Inicio: ",
  HISTORIC_PIVOT_ACTION_VRI_START_NOW: "Ahora",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED: "${} atrás",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL: "Creado  manualmente",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER: "Usuario: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE: "Modo: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION: "${} | ${} , ${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON: "Endgun encendido",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF: "Endgun apagado",
  HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION: "Riego Simple",
  HISTORIC_PANEL_STREAM_V5_: "Detener Pivote",
  HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION: "Riego Programado",
  HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION: "Riego por Segmentos",
  HISTORIC_PANEL_STREAM_V5_STOPPED: "Detenido",
  HISTORIC_PANEL_STREAM_V5_FORWARD: "Avanzar",
  HISTORIC_PANEL_STREAM_V5_REVERSE: "Retroceder",
  HISTORIC_PANEL_STREAM_V5_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PANEL_STREAM_V5_DIRECTION: "Dirección: ",
  HISTORIC_PANEL_STREAM_V5_STATUS: "Estado: ${}",
  HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION: "Activar Riego: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL: "Total horómetro: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET: "Horómetro húmedo: ",
  HISTORIC_PANEL_STREAM_V5_MODE: "Modo: ",
  HISTORIC_PANEL_STREAM_V5_PERCENTAGE: "Precipitación: ",
  HISTORIC_PANEL_STREAM_V5_POSITION: "Posición",
  HISTORIC_PANEL_STREAM_V5_ANGLE: "Ángulo: ",
  HISTORIC_PANEL_STREAM_V5_VOLTAGE: "Voltaje: ${} V",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER: "Presión de la torre central: ",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER: "Presión torre final: ",
  HISTORIC_GPS_STREAM_V5_NO_LABEL: "${}",
  HISTORIC_GPS_STREAM_V5_REMAINING_TIME: "Tiempo restante estimado: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE: "Programación actual: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT: "Segmento actual: ",
  HISTORIC_PANEL_LABEL_DATE: "Fecha",
  HISTORIC_PANEL_LABEL_DATASHEET: "Hoja técnica",
  HISTORIC_PANEL_LABEL_TYPE: "Tipo",
  HISTORIC_PANEL_LABEL_SUMMARY: "Resumen",
  HISTORIC_MAINTENANCE_ON: "Modo de mantenimiento habilitado",
  HISTORIC_MAINTENANCE_OFF: "Modo de mantenimiento deshabilitado",
  IRRIGATION_TYPE_SIMPLE: "Riego Simple",
  IRRIGATION_TYPE_SCHEDULE: "Riego Programado",
  IRRIGATION_TYPE_SEGMENT: "Riego por Segmentos",
  IRRIGATION_MODE_NONE: "Ninguno",
  IRRIGATION_MODE_WET: "Húmedo",
  IRRIGATION_MODE_DRY: "Seco",
  IRRIGATION_DIRECTION_STOPPED: "Detenido",
  IRRIGATION_DIRECTION_FORWARD: "Avanzar",
  IRRIGATION_DIRECTION_REVERSE: "Retroceder",
  EDIT_PIVOT_LAST_SETTINGS: "Última configuración: ${}",
  EDIT_PIVOT_MANUAL: "- Manual",
  EDIT_PIVOT_BY_WEB: "- Internet",
  EDIT_PIVOT_ERROR: "El formulario tiene valores no válidos.",
  EDIT_PIVOT_ERROR_CENTER_LAT: "Latitud central no válida",
  EDIT_PIVOT_ERROR_CENTER_LNG: "Longitud central no válida",
  EDIT_PIVOT_ERROR_START_REF_LAT: "Latitud de referencia inicial no válida",
  EDIT_PIVOT_ERROR_START_REF_LNG: "Longitud de referencia inicial no válida",
  EDIT_PIVOT_ERROR_END_REF_LAT: "Latitud de referencia final no válida",
  EDIT_PIVOT_ERROR_END_REF_LNG: "Longitud de referencia final no válida",
  EDIT_PIVOT_ERROR_RADIUS: "Valor de radio no válido",
  EDIT_PIVOT_ERROR_TOTAL_RADIUS: "Valor de radio total no válido",
  EDIT_PIVOT_ERROR_AREA: "Valor de área no válido",
  EDIT_PIVOT_ERROR_SPEED: "Valor de la última velocidad de la torre no válido",
  EDIT_PIVOT_ERROR_FLOW: "Valor de caudal no válido",
  EDIT_PIVOT_ERROR_POTENCY: "Valor de potencia de la bomba no válido",
  EDIT_PIVOT_ERROR_PUMP_TIME: "Valor de tiempo de la bomba no válido",
  EDIT_PIVOT_ERROR_POWER_TIME: "Valor de tiempo de potencia no válido",
  EDIT_PIVOT_ERROR_POWER_RANGE_MIN: "Tamaño de nombre no válido",
  EDIT_PIVOT_ERROR_POWER_RANGE_MAX: "Tamaño de nombre no válido",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MIN: "Tamaño de nombre no válido",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MAX: "Tamaño de nombre no válido",
  EDIT_PIVOT_ERROR_CLOCK_DATE: "Fecha no válida",
  EDIT_PIVOT_ERROR_CLOCK_TIME: "Tiempo no válido",
  EDIT_PIVOT_CLOCK_LABEL: "Ajustar el reloj del dispositivo",
  EDIT_PIVOT_SAVE_AWAIT:
    "Configuración enviada. Esperar por confirmación dispositivo",
  EDIT_PIVOT_SAVE_AWAIT_ERROR: "Se ha producido un error",
  EDIT_PIVOT_SAVE_CONTROLLER_SUCCESS: "Controlador guardado correctamente",
  EDIT_PIVOT_SAVE_GPS_SUCCESS: "GPS guardado correctamente",
  EDIT_PIVOT_SAVE_PUMP_SUCCESS: "Bomba guardada correctamente",
  EDIT_PIVOT_GPS_LABEL: "Radio GPS",
  EDIT_PIVOT_PUMP_LABEL: "Radio Bomba",
  EDIT_PIVOT_DELIVERED_LABEL: "Radio Controlador",
  EDIT_PIVOT_CENTRAL_LABEL: "Radio Central",
  HISTORIC_GPS_STREAM_COMUNICATION_ERROR: "Pivote sin comunicación",
  HISTORIC_STREAM_LOST_ERROR:
    "Posible pérdida de información desde GPS o controlador",
  TERRAIN_MAP: "Terreno",
  GENERIC_REGISTER_DATA_ERROR: "Error al registrar los datos",
  GENERIC_REGISTER_DATA_SUCCESS: "¡Datos registrados correctamente!",
  CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY: "No se pudo crear el pivote",
  METERSYSTEM_WATER_LEVEL_BY_TIME: "Nivel de agua por tiempo",
  METERSYSTEM_CURRENT_FLOW: "Caudal:",
  METERSYSTEM_NO_READINGS: "No hay lecturas",
  METERSYSTEM_TOTAL_FLOW: "Caudal Total",
  METERSYSTEM_FLOW_BY_TIME: "Caudal por tiempo",
  METER_SYSTEM_EDIT: "Editar medidor",
  METER_SYSTEM_EDIT_GENERAL: "Configuraciones Generales",
  METERSYSTEM_STATUS_1: "El dispositivo está encendido",
  METERSYSTEM_STATUS_2: "Encendido después de un corte de energía",
  METERSYSTEM_STATUS_3: "Monitoreando el sensor",
  METERSYSTEM_STATUS_176: "Bomba apagada",
  METERSYSTEM_STATUS_177: "Bomba encendida",
  METERSYSTEM_STATUS_178: "Bomba del dispositivo apagada por otro IMeter",
  METERSYSTEM_STATUS_179: "Bomba del dispositivo encendida por otro IMeter",
  METERSYSTEM_STATUS_180: "Bomba del dispositivo apagada por un sensor",
  METERSYSTEM_STATUS_181: "Bomba del dispositivo encendida por un sensor",
  METERSYSTEM_STATUS_192: "Horómetro corrupto",
  METERSYSTEM_STATUS_208: "Falla de la bomba al bloquear el contactor",
  METERSYSTEM_STATUS_209: "Tiempo de pausa de encendido",
  METERSYSTEM_STATUS_210: "El dispositivo está apagado",
  METERSYSTEM_STATUS_211: "Apagado por falla de energía",
  METERSYSTEM_STATUS_212: "Nivel de batería inferior a 10V",
  METERSYSTEM_STATUS_213: "El dispositivo perdió contacto con otro Imeter",
  METER_SYSTEMS: "Sistemas de medición:",
  DEVICES_CONTROLLER_NOT_FOUND: "Controlador no encontrado",
  DEVICES_ANALYSIS: "Análisis:",
  DEVICES_STATUS_COLOR_NOT_FOUND: "Color de estado no encontrado",
  DEVICE: "Dispositivo:",
  DEVICE_FOUND: "Encontrado",
  DEVICE_NOT_FOUND: "No encontrado",
  DEVICE_CENTRAL: "Central",
  DEVICE_LINE_VERY_STRONG: "Muy Fuerte",
  DEVICE_LINE_STRONG: "Fuerte",
  DEVICE_LINE_MODERATE: "Moderado",
  DEVICE_LINE_WEAK: "Débil",
  DEVICE_LINE_ERROR: "Error",
  DEVICE_LINE: "Línea:",
  ICROP_FORM_NO_ACCESS: "Parece que aún no tiene acceso a esta función.",
  ICROP_FORM_SELECT_A_FARM: "Seleccione una granja:",
  EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS: "Importar feriados nacionales",
  PAGE_TRY_AGAIN: "Intente nuevamente",
  EDIT_PIVOT_LAT_AND_LONG: "Latitud y Longitud",
  PAUSE_TIME_WITHOUT_CONFIG: "Tiempo de Pausa no habilitado",
  WEEK_OF_THE_DAY: "Semana del día",
  BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO:
    "Esta tabla no tiene datos para el rango de fechas.",

  BASIC_INFO_BOX_INFORMATION_CALCULATED:
    "Se están calculando las informaciones para los datos que ya existen en el sistema, esto puede tardar mucho tiempo.",
  CROP_COTTOM: "Algodón",
  CROP_RICE: "Arroz",
  CROP_OAT: "Avena",
  CROP_POTATO: "Patata",
  CROP_SWEET_POTATO: "Patata Dulce",
  CROP_SUGAR_CANE: "Caña de Azúcar",
  CROP_BARLEY: "Cebada",
  CROP_PEA: "Guisante",
  CROP_BEAN: "Frijol",
  CROP_MILLET: "Mijo",
  CROP_CORN: "Mijo",
  CROP_SWEET_CORN: "Maíz Dulce",
  CROP_POPCORN: "Palomitas de Maíz",
  CROP_CORN_SEEDS: "Semillas de maíz",
  CROP_SILAGE_CORN: "Maíz ensilado",
  CROP_PASTURE: "Pastos",
  CROP_SOY: "Soja",
  CROP_SOY_SEEDS: "Semillas de soja",
  CROP_SORGHUM: "Sorgo",
  CROP_SORGHUM_SEEDS: "Semillas de sorgo",
  CROP_TOMATO: "Tomate",
  CROP_WHEAT: "Trigo",

  ORBITAL_SENSOR: "Sensor orbital",
  ANNOUNCEMENT_SYSTEM_TITLE: "Anuncio",

  PRESSURE_DELAY_INPUT:
    "El tiempo de retraso se refiere a la cantidad de tiempo después de que el pivote se haya presurizado. Hay un tiempo de espera para que la presión llegue a la última torre, para que el pivote pueda empezar a moverse.",

  PRESSURE_SENSOR_RANGE_MIN:
    "El valor mínimo indica el rango de presión que el dispositivo debe mantener. Por debajo del mínimo el pivote se detiene.",

  PRESSURE_SENSOR_RANGE_MAX:
    "El valor máximo indica el rango de presión que debe mantener el dispositivo. Por encima del máximo los pivotes se detienen.",

  SENSOR_SCALE_END:
    "La escala del sensor se refiere a la presión máxima que puede medir.",

  GET_NEW_STREAM: "Buscar la última actualización",

  ADD_SENSOR: "Añadir sensor",

  WATER_LEVEL_SENSOR: "Sensor de nivel",

  TRIGGER_RULE: "Regla de activación",

  CHART: "Gráfica",

  PIVOT_UNDER_MAINTENANCE: "Pivote en mantenimiento",

  GET_NEW_STREAM_ERROR: "Error mientras se busca la última actualización",
  PIVOT_MANAGEMENT: "Gestión de pivotes",

  CHOOSE_ACCUMULATED_VOL_PERIOD: "Elegir el periodo de información:",

  LATEST_INSTANT_FLOW: "Último caudal instantáneo",

  APPROXIMATE_USAGE_TIME: "Tiempo estimado de uso",

  APPROXIMATE_USAGE_TIME_ABBREVIATED: "Tiempo estimado de uso",

  ACCUMULATED_VOLUME: "Volumen total acumulado",

  HOURS_24: "24 HORAS",

  HOURS_48: "48 HORAS",

  HOURS_72: "72 HORAS",

  WEEK_1: "1 SEMANA",

  MONTH_1: "1 MES",

  FLOW_BY_TIME_CHART: "Gráfica de caudal por tiempo",

  MONTHLY_WATER_ACTION_CONFIRMATION:
    "Se ha superado el Límite Mensual de Agua, ¿seguro que desea continuar?",

  ATENTION: "Atención",
  CONTINUE: "Continuar",
  SEGMENT_FINAL_ANGLE: "Ángulo final del segmento",
  WORKING_ANGLE: "Rango del ángulo de pivote",
  HARDWARE: "Hardware",
  DAILY_RAINING: "de lluvia hoy",
  PLUVIOMETER_HISTOGRAM: "Histograma",
  IRRIGATION_INCREMENTED_DUE_TO_RAIN:
    "Velocidad de riego incrementada a ${}% debido a la lluvia.",
  RAINING_NOW: "Llueve ahora",
  ICROP_DOC_URL:
    "https://irricontroldev.s3.us-east-2.amazonaws.com/icrop_en.pdf",
  ICROP_WELCOME_PANEL_TITLE:
    "¡Bienvenido al panel de Administración de Pivote!",
  ICROP_WELCOME_PANEL_P1:
    "Aquí tiene acceso a las recomendaciones de riego por pivote, y a la información sobre el cultivo y el suelo.",
  ICROP_WELCOME_PANEL_P2:
    "Descargar el documento a continuación o acceder al panel.",
  ICROP_DOC_DOWNLOAD: "Descargar documento",
  ICROP_ACCESS_PANEL: "Acceder al panel",
  WEEK_ACCUMULATED_RAIN: "Lluvias acumuladas en la semana",
  ACCUMULATED_MILLIMETERS: "Milímetros acumulados",
  BASE_CHANGE_CLOCK_ALERT:
    "El reloj de los dispositivos de abajo se cambiará a la hora actual",
  EQUIPMENT_ID_MISSING:
    "Equipo no encontrado en la solicitud. Por favor, póngase en contacto con el equipo de soporte",
  RADIO_ID_NOT_FOUND_IN_DATABASE: "Radio no encontrada",
  RADIO_ID_ALREADY_USED: "La Radio ya está en uso",
  SEND_RADIO_CHANGE_TO: "Enviar actualización de radio a",
  NO_EQUIPMENT: "Ninguno",
  ALL_EQUIPMENTS: "Todos los Equipos",
  SEND: "Enviar",
  CHANGE_BASE_RADIO_MODAL_TITLE: "Cambiar Radio Central",
  USER_PERMISSION: "Sin permiso",
  USER_PERMISSION_READ: "Visualizador",
  USER_PERMISSION_OPERATOR: "Operador",
  USER_PERMISSION_CONFIGURATOR: "Configurador",
  SETORIAL_ENDGUN_ERROR:
    "Este pivote es sectorial. Las configuraciones sobre la zona roja no tendrán ningún efecto",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION: "Descripción",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT:
    "El tiempo de pausa son rangos de tiempo en los que el pivote no debe funcionar en modo húmedo para evitar su uso en momentos críticos, por ejemplo, cuando el costo de la energía eléctrica es mayor.",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT:
    "Es habitual segmentar algunos pivotes en dos o más partes debido a la variedad de siembra, el ciclo de cultivo o incluso para facilitar la gestión de la operación. Con esta función, puede segmentar los pivotes y aportar más control sobre su funcionamiento.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT:
    "En el caso de los pivotes que tienen un endgun instalado en la última torre, es posible segmentar la activación impidiendo que se encienda en zonas donde no debe regar, como carreteras, casas, etc.",
  PIVOT_CONFIG_V5_AUTORREVERSION_TEXT:
    "La autoreversión es una característica del equipo que hace posible que el pivote llegue al final de su recorrido y vuelva automáticamente, funcionando. El retorno del pivote se produce cuando llega a un obstáculo físico, llamado fin de recorrido -disponible para los paneles SmartConnect- o cuando alcanza su ángulo final de trabajo.",
  WORKING_ANGLE_LABEL: "¿Cuál es el ángulo máximo que alcanza este pivote?",
  PASSWORD_RECOVERY_INSERT_EMAIL:
    "Ingrese su dirección de correo electrónico para que podamos enviarle un correo de recuperación de cuentas.",
  VALID_UNTILL: "Válido hasta",
  NO_EQUIPMENTS: "No hay equipos",
  UPDATE_USER_TO_ADMINISTRATOR_SUCCESS:
    "Usuario administrador añadido con éxito.",
  CENTRAL_FOUND: "Central encontrada",
  CONTROLLER_FOUND: "Controlador encontrado",
  PUMP_FOUND: "Bomba encontrada",
  REPEATER_FOUND: "Repetidor encontrado",
  FIELD_CAPACITY: "Capacidad de campo",
  FULL_MOISTURE: "Humedad completa",
  MODERATE_MOISTURE: "Humedad moderada",
  LIMIT_POINT: "Punto límite",
  MODERATE_WATER_STRESS: "Estrés hídrico moderado",
  WATER_STRESS: "Estrés hídrico",
  APPROPRIATE: "Adecuado",
  INNAPPROPRIATE: "No adecuado",
  SCALE: "Escala",
  BASIC_INFO_BOX_RAINFALL: "Lluvia",
  PERIOD_TOTAL_WATER_CONSUMPTION: "Consumo total para el período",
  SECTOR_PIVOT_REASON_FALSE: "No",
  HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME: "Fecha y hora: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX:
    "Rango de hora de finalización: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN: "Rango de hora de inicio: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX: "Rango de voltaje máximo: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN: "Rango de voltaje mínimo: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE: "Rango de caudal: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME:
    "Tiempo de retorno tras corte de energía: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME: "Tiempo de bombeo: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER: "Última velocidad de la torre: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA: "Área regada: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS: "Radio regado: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER: "Radio hasta la última torre: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE: "Referencia final:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE: "Referencia inicial:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_CENTER: "Centro:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT: "Pivot Sectorial: ${}",
  SECTOR_PIVOT_REASON_TRUE: "Sí",
  HISTORIC_CONFIG_PIVOT_OLD_END_GUN: "Endgun: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_NAME: "Nombre: ${}",
  HISTORIC_CONFIG_PIVOT_DRIVE: "${}",
  HISTORIC_CONFIG_PIVOT_PINNED: "Configuraciones Favoritas",
  HISTORIC_IRPD_ACTION_USER: "Usuario: ",
  MEASURE_SCALE_UNIT_METER: "m³",
  MEASURE_SCALE_UNIT_LITER: "L",
  MEASURE_SCALE_100: "100",
  MEASURE_SCALE_10: "10",
  MEASURE_SCALE_1: "1",
  HISTORIC_IRPD_STREAM_HOURMETER: "Horómetro: ",
  HISTORIC_IRPD_ACTION_CREATED: "${} atrás | ${}",
  NUMBER_OF_BARS_12: "12",
  NUMBER_OF_BARS_24: "24",
  NUMBER_OF_BARS_30: "30",
  EDIT_FARM_WEEK: "Semana",
  CREATE_DEVICE_MODAL_PROTOCOL_5: "G2",
  CREATE_DEVICE_MODAL_PROTOCOL_4_1: "G1",
  EDIT_FARM_IRPD_INFOS_RECALCULATE_TEXT:
    "Presione la tecla para recalcular los informes para las bombas de la granja.",
  EDIT_FARM_IRPD_INFOS_ENABLE_TEXT:
    "Presione la tecla para habilitar los informes para las bombas de la granja.",
  SELECTED_FARM_LIST: "Lista",
  SWAP_RADIO: "Radios de pivote de conmutación",
  EDIT_RADIO: "Editar radio",
  HISTORIC_PIVOT_ACTION_BLADE: "Percentímetro: ",
  HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE: "Actualización",
  HISTORIC_PAINEL_TYPE_COMMAND: "Orden",
  PEAK_TIME_DISABLED: "Esté atento: Tiempo de pausa deshabilitado",
  TABLE_OF: "de",
  FORGET_PASSWORD_OPTION_PASSWORD: "He olvidado mi contraseña",
  SIGN_UP_FIELD_USERNAME_CHECK:
    "Utilice sólo letras y números. De 8 a 20 caracteres.",
  SIGN_UP_FIELD_USERNAME_ALREADY_EXIST: "Nombre de usuario no  disponible",
  SIGN_UP_FIELD_REQUIRED: "Este campo es obligatorio.",
  CREATE_FARM_LABEL: "Crear Granja",
  SELECTED_FARM_MAP: "Mapa",
  RADIO: "Radio",
  SWAP: "Intercambio",
  SIGN_UP_FIELD_PASSWORD_CHECK:
    "Asegúrese de que su contraseña tiene más de 8 caracteres e incluye números.",
  NO_COMPATIBLE_RADIO_FOUND: "No se ha encontrado una radio compatible.",
  SWAP_RADIO_OF: "Intercambiar radio de ",
  SELECT_TO_SWAP_RADIO_OF: "Seleccione el pivote para intercambiar radios de ",
  HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL: "Manual",
  NO_NOTIFICATIONS: "Usted no tiene notificaciones.",
  SAVE_RADIO: "Guardar radio",
  CANCEL: "Cancelar",
  CONTROL: "Controlador",
  PUMP_CABLE: "Cable de Bomba",
  HISTORIC_IRPD_ACTION_NOW: "Inmediatamente",
  HISTORIC_PAINEL_TYPE_GPS_STREAM: "Actualización GPS",
  HISTORIC_PAINEL_NO_GPS: "No hay información de GPS",
  HISTORIC_PAINEL_TYPE_UPDATE: "Actualización",
  HISTORIC_PANEL_STREAM_V5_STOP_PIVOT: "Detener Pivote",
  HISTORIC_PAINEL_TYPE_MAINTENANCE: "Mantenimiento",
  HISTORIC_IRPD_ACTION_START_AT: "Inicio: ",
  HISTORIC_IRPD_ACTION_END_AT: "Finalización: ",
  CREATE_DEVICE_MODAL_IRPD_SOON: "Próximamente",
  REDO_CONVENTIONAL_ACTION: "Cargar último riego",
  PIVOT_ACTION_VALUE_MODE_DRY: "Seco",
  UPDATING: "Actualizando...",
  SIGN_UP_FIELD_PASSWORD_REPEAT_CHECK: "La contraseña no coincide.",
  EDIT_FARM_CONTACT: "Contacto",
  CREATE_DEVICE_MODAL_PIVO_CHOOSER:
    "Automatización Pivot (SmartConnect y Nexus)",
  CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER:
    "Bauer Pivot (SmartTouch y Universal Pro)",
  CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER: "Monitor de Pivote",
  CREATE_DEVICE_MODAL_IRPD_CHOOSER: "Control remoto de bombas",
  CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE: "Radio para Bombas (Spoti)",
  CREATE_DEVICE_MODAL_PUMP_IRPD_CODE: "Radio para Bombas (Irripump)",
  HISTORIC_PANEL_LABEL_BY: "Por",
  HISTORIC_PAINEL_TYPE_PUMP_UPDATE: "Actualizar Bomba",
  HISTORIC_CONFIG_PIVOT_V5_LANGUAGE: "Lenguaje del Dispositivo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MIN: "Voltaje Mínimo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MAX: "Voltaje Máximo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_STABLE: "Voltaje Estable: ${}",
  HISTORIC_CONFIG_PIVOT_V5_WORKING_ANGLE: "Sector: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_TIMEOUT: "Tiempo de bombeo: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_DELAY:
    "Retraso de presión de la bomba: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_READ_PRESSURE: "Lectura de la presión: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH: "Interruptor de presión: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_SOFT_START_TIME: "Hora de Inicio Suave: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Valor máximo del sensor de presión: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Valor mínimo del sensor de presión: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_START: "Tiempo de pausa Inicio: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_END: "Tiempo de pausa finalización: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POTENCY: "Energía de la Bomba: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POWER_DELAY: "Tiempo de Energía: ${}",
  HISTORIC_CONFIG_PIVOT_V5_CANAL_AVERAGE_POWER:
    "Potencia Promedio del Canal: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PILOT_AVERAGE_POWER:
    "Potencia Promedio del Pivote: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_START: "Ángulo Inicial: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_END: "Ángulo Final ${}",
  HISTORIC_CONFIG_PIVOT_V5_FIELD_ENDGUN_ANGLE_MODE: "Modo: ",
  HISTORIC_CREATED_AT: "Enviado el: ",
  HISTORIC_IRPD_ACTION_NEVER: "Nunca",
  HISTORIC_IRPD_ACTION_START: "Encender",
  HISTORIC_IRPD_ACTION_STOP: "Apagar",
  PAINEL_STREAM_RESUMED_REASON_242: "Detenciones inesperadas",
  PAINEL_STREAM_RESUMED_REASON_246: "Detenido - potencia inestable",
  PAINEL_STREAM_RESUMED_REASON_248: "Detenido - baja presión",
  PAINEL_STREAM_RESUMED_REASON_250: "Detenido - tiempo de espera de la presión",
  PAINEL_STREAM_RESUMED_REASON_251: "Detenido - relé térmico",
  PAINEL_STREAM_RESUMED_REASON_253: "Detenido - falla de alimentación",
  PAINEL_STREAM_RESUMED_REASON_255: "Seguridad",
  IRPD_ACTION_VALUE_MODE_WET: "Húmedo",
  IRPD_ACTION_VALUE_MODE_DRY: "Seco",
  IRPD_STATUS_ON_BY_SENSOR: "Bomba encendida por sensor",
  IRPD_STATUS_WAITING_AFTER_POWER_FAILURE:
    "Esperando un tiempo después de un corte de energía",
  IRPD_STATUS_HOURMETER_CORRUPTED: "Horómetro no contabilizado",
  IRPD_STATUS_INCORRECT_SCHEDULE: "Programación incorrecta",
  IRPD_STATUS_END_SCHEDULE_REACHED:
    "Bomba apagada después de terminar la programación",
  CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER: "Energía de la Bomba",
  CREATE_DEVICE_MODAL_PROTOCOL: "Versión",
  FARM_FIELD_WATER_BILLING_DATE: "Día de facturación de agua",
  CREATE_USER_MODAL_USERNAME_OR_EMAIL:
    "Nombre, nombre de usuario o correo electrónico",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD:
    "¿Confirma que quiere activar el pivote en AVANCE con estos ajustes?",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE:
    "¿Confirma que quiere activar el pivote en RETROCESO con estos ajustes?",
  MEASURE_SCALE: "Escala de Medición",
  MEASURE_SCALE_UNIT: "Unidad de Escala de Medición",
  VOLTAGE: "Voltaje",
  DATASHEET_ALERT_CONFIRMATION:
    "¿Está seguro de que desea reemplazar la configuración de la hoja técnica por esta?",
  HISTORIC_CONFIG_PIVOT_SUBTITLE:
    "La configuración mostrada en esta pestaña está marcada como hoja técnica. Para cambiarla, vaya a las otras pestañas y establezca otra configuración como hoja técnica.",
  HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY:
    "Configuración eliminada de los favoritos",
  DATASHEET_PLACEHOLDER: "Configuración de hoja técnica",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ADD_SUCCESSFULLY:
    "¡Configuración establecida como hoja técnica actual!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED:
    "Error al establecer la configuración como hoja técnica. ¡Inténtelo de nuevo más tarde!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ERROR: "Error, inténtelo de nuevo más tarde",
  EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND: "La radio central no es válida",
  EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED:
    "La radio central ya ha sido utilizada",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID: "Radio de controlador no válida",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED:
    "Radio de controlador ya utilizada",
  CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID: "Radio de GPS no válida",
  CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED: "Radio de GPS ya utilizada",
  CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID: "Radio de bomba no válida",
  CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED: "Radio de bomba ya utilizada",
  CREATE_USER_MODAL_404: "Usuario no existe",
  CREATE_USER_MODAL_INVALID: "Nombre de usuario o correo electrónico no válido",
  EDIT_PERMISSIONS_ADD_ADM_ERROR: "Error al añadir como administrador",
  RADIO_CHANGED_SUCCESS: "Radio cambiada correctamente",
  RADIO_SWAP_SUCCESS: "Radios intercambiadas correctamente",
  HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY:
    "Configuración añadida a favoritos",
  HISTORIC_CONFIG_PIVOT_PINNED_ERROR: "Error, inténtelo más tarde.",
  IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG:
    "No existe una configuración de riego previa",
  FARM_DISABLED:
    "Granja deshabilitada. El acceso se liberará solo después de la identificación del pago de las tarifas anuales.",
  FARM_DISABLED_CONTACT: "Contacto",
  IRPD_STATUS_OPERATING: "Operating",
  IRPD_STATUS_SCHEDULE_EXPIRED: "Schedule Expired",
  CENTRAL_UPDATE: "Central Update",
  SHOW_CENTRAL_STATUS: "Show Central Updates",
  CREATE_USER_MODAL_EMAIL_NOT_FOUND: "Email not found",
  CREATE_USER_MODAL_SEND_INVITE: "Send Invite",
  CREATE_USER_MODAL_KEEP_TYPING: "Keep typing...",
  CREATE_USER_MODAL_FIND_USER_ERROR:
    "An error occurred while searching for user",
  EDIT_FARM_EDIT_USER: "Editar Usuario",
  EDIT_FARM_NO_ADMIN: "Ningún administrador registrado",
  USER_PERMISSION_CUSTOMIZED: "Personalizado",
  CREATE_DEVICE_MODAL_TYPE: "Escribe",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_CENTRAL: "pivote central supervisado",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_LINEAR: "pivote lineal supervisado",
  SIGNAL_STRENGTH: "Signal Strength (dbm)",
  ID_RADIO: "ID (Radio)",
  LOGS: "Logs",
  POWER_RANGE_PEAK_ACRONYM: "PH",
  POWER_RANGE_OFF_PEAK_ACRONYM: "OPH",
  POWER_RANGE_REDUCED_ACRONYM: "NT",
  PIVOT_SEGMENT_CONFIG_REVERSE: "Reverse Config",
  PIVOT_SEGMENT_CONFIG_FORWARD: "Forward Config",
  PIVOT_SEGMENT_BEGIN_REVERSE: "Start Reverse",
  PIVOT_SEGMENT_BEGIN_FORWARD: "Start Forward",
  SELECTED_PIVOT_ANGLE_MODAL_FAILURE: "Failed to send Irrigation by angle",
  EDIT_FARM_ANGLE_RANGES: "Rangos",
  FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE:
    "* La activación de la Endgun se realiza automáticamente según la configuración del pivot.",
  CREATE_DEVICE_MODAL_PANEL_TYPE: "Tipo de Panel",
  CREATE_DEVICE_MODAL_NEXUS: "Nexus",
  CREATE_DEVICE_MODAL_SMARTCONNECT: "SmartConnect",
  CREATE_DEVICE_MODAL_BRAND_MODEL: "Fabricante",
  CREATE_BRAND_MODEL_BAUER: "Bauer",
  CREATE_BRAND_MODEL_IRRIGABRAS: "Irrigabras",
  CREATE_BRAND_MODEL_VALLEY: "Valley",
  CREATE_BRAND_MODEL_LINDSAY: "Lindsay",
  CREATE_BRAND_MODEL_CARBORUNDUM: "Carborundum",
  CREATE_BRAND_MODEL_KREBS: "Krebs",
  CREATE_BRAND_MODEL_FOCKINK: "Fockink",
  CREATE_BRAND_MODEL_REINKE: "Reinke",
  CREATE_BRAND_MODEL_OTHER: "Otro",
  CREATE_DEVICE_MODAL_UNIT: "Unidad de la potencia",
  CREATE_DEVICE_MODAL_KW: "kW",
  CREATE_DEVICE_MODAL_HP: "hp",
  CREATE_DEVICE_POTENCY_PLACEHOLDER: "Potencia",
  CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT: "Rendimiento",
  CREATE_DEVICE_MODAL_CONVERTED_POTENCY: "Potencia Convertida",
  ENABLE_PLUVIOMETER: "Habilitar pluviómetro",
  DISABLE_PLUVIOMETER: "Desactivar pluviómetro",
  PLUVIOMETER_BOX_ENABLE_DIALOG: "Estas seguro que quieres ${}",
  CREATE_BRAND_MODEL_INSERT: "Ingresse el Fabricante",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID: "Radio repetidora no válida",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED: "Radio repetidora ya usada",
  CREATE_DEVICE_MODAL_METER: "Sistema de medición",
  CREATE_DEVICE_MODAL_SENSOR: "Sensor",
  CREATE_DEVICE_MODAL_SENSOR_5: "Sensor 5MCA",
  CREATE_DEVICE_MODAL_SENSOR_3: "Sensor 10MCA",
  CREATE_DEVICE_MODAL_SENSOR_2: "Sensor 20MCA",
  CREATE_DEVICE_MODAL_SENSOR_7: "Sensor 100MCA",
  CREATE_DEVICE_MODAL_IMETER: "Radio do IMeter",
  CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND: "Invalid Meter System radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED: "IMeter radio already used",
  CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND: "Invalid Pump radio",
  CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED: "Pump radio already used",
  CREATE_DEVICE_MODAL_ERROR_INVALID_HARDWARE_ID: "Harware ID is not available",
  SEND_COMMAND_ERROR: "Error al enviar la orden.",
  CREATE_DEVICE_MODAL_ENERGY_TYPE: "el tipo",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR: "Solar",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT: "Doble voltaje",
  PIVOT_STATUS_NO_COMMUNICATION_INFO_POPOVER:
    "Equipo sin comunicación con la plataforma.",
  REPORT_TABLE_DISTANCE: "Distancia recorrida",
  PRIVACY_POLICY: "política de privacidad",
  TERMS_AND_CONDITIONS: "Términos y condiciones",
  ANNOUNCEMENT_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Irricontrol's Terms and Conditions",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "I declare that I have read and agree to the Irricontrol Terms and Conditions of Use.",
  TERMS_AND_CONDITIONS_LOGIN_1:
    "By using the Irricontrol platform, you declare that you have read and accepted the",
  TERMS_AND_CONDITIONS_LOGIN_2: "and the",
  PAGE_NO_ACCESS_TO_FARMS: "Usted no tiene permiso para acceder a esta página.",
  PAGE_NO_INTERNET:
    "No se pudo conectar a Internet, verifique su acceso a la red.",
  PAGE_PRIVACY_POLICY_NOT_ACCEPTED:
    "Aún no ha aceptado los Términos y Condiciones de Uso de Irricontrol.",
  PAGE_CODE_404:
    "No pudimos encontrar la página que solicitó. Vuelva a intentarlo.",
  PAGE_DEFAULT: "Ha ocurrido un problema inesperado, lo sentimos.",
  BACK_TO_INICIAL_PAGE: "Volver a la página de inicio",
  BILLING_TAB_REDIRECT: "Soporte técnico",
  PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP:
    "Distancia del centro a la última torreta.",
  PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP:
    "Velocidad de la última torreta al 100%.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN: "Tensión mínima de funcionamiento",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX: "Tensión máxima de funcionamiento",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP:
    "Si el pivote funciona fuera de los límites de tensión durante un periodo superior al tiempo establecido, la acción del pivote se detiene.",
  SELECTED_ALERT_SELECT_PIVOT_VERSION_TOOLTIP: "Elige la versión del pivote:",
  IRRIGATION_END_DATE_PREDICTION_TOOLTIP:
    "La predicción de end_date considera el tiempo de inactividad debido a las horas punta.",
  BASIC_BAR_TOOLTIP_HP: "Horas punta.",
  BASIC_BAR_TOOLTIP_HFP: "Horas no pico.",
  BASIC_BAR_TOOLTIP_HR: "Horas no pico.",
  PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP:
    "Idioma a utilizar en el controlador del panel.",
  EDIT_IRPD_FIELD_ENERGY_TIME: "Tiempo de energía",
  EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP:
    "Tiempo de retorno para seguir ejecutando el comando después de un corte de energía.",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY: "Tiempo de alimentación",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP:
    "Tiempo de retorno para seguir ejecutando el comando después de un corte de energía.",
  MEASUREMENT_MAX_VALUE: "Escala del sensor",
  MEASUREMENT_PULSES_VALUE: "Volumen",
  MEASUREMENT_MAX_VALUE_TOOLTIP:
    " Se refiere al tiempo límite para que el pivote se presurice. Si se supera este tiempo sin alcanzar la presión, la bomba se apaga por seguridad.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP:
    "Equipo para medir la presión en el centro del pivote.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP:
    "Si el pivote funciona fuera de los límites de presión durante un periodo superior al establecido, la acción del pivote se detiene.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE: "Condición de parada",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP:
    '"Por valor" el comando de pivote se detiene tras alcanzar el umbral fijado en el campo "Valor". "Por decrecimiento" el pivote aumenta la velocidad para compensar la hoja aplicada.',
  EDIT_PLUVIOMETER_MM_TO_STOP: "Valor",
  EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP:
    "Umbral de precipitación, en milímetros, establecido en una zona determinada.",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END: "Escala del sensor",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP:
    "Valor referido a la escala del pluviómetro utilizado.",
  PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT: "Tiempo de espera de la bomba",
  PRESSURE_TIMEOUT_INPUT:
    "Se refiere al tiempo límite para que el pivote se presurice. Si se supera este tiempo sin alcanzar la presión, la bomba se apaga por seguridad.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE: "Valor mínimo del sensor",
  PIVOT_CONFIG_FIELD_SETORIAL: "Pivote del sector",
  PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP:
    "Pivote que tiene un ángulo de trabajo distinto de 360°.",
  PIVOT_CONFIG_FIELD_SPEED: "Velocidad de la última torre",
  PIVOT_CONFIG_FIELD_SPEED_TOOLTIP: "Velocidad de la última torre al 100%.",
  PIVOT_CONFIG_FIELD_POWER_TIME:
    "Tiempo de retorno tras un fallo de alimentación",
  PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP:
    "Tiempo que una orden sigue siendo válida después de un corte de energía",
  PIVOT_CONFIG_FIELD_PUMP_TIME: "Tiempo de presurización.",
  PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP:
    "Se refiere al tiempo límite para que el pivote presione. Si se supera este tiempo sin alcanzar la presión, la bomba se apaga por seguridad.",
  PIVOT_CONFIG_FIELD_POWER_RANGE: "Tensión de trabajo.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP:
    "Característica que impide que el equipo funcione fuera de las tensiones mínimas y máximas.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MIN: "Tensión mínima de funcionamiento",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MAX: "Tensión máxima de funcionamiento",
  ENERGY_TIME_DESCRIPTION:
    "En esta función puede configurar el tiempo en minutos que la bomba debe esperar para reiniciarse después de un corte de energía.",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP:
    "Ubicación de la última torre en el punto 0°.",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP: "Ubicación del centro del pivote.",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER: "Ubicación del Dispositivo",
  PIVOT_CONFIG_V5_FIELD_NAME: "Nombre del pivote",
  PIVOT_CONFIG_V5_FIELD_LANGUAGE: "Idioma del dispositivo",
  PIVOT_CONFIG_V5_FIELD_AREA: "Zona de regadío",
  PIVOT_CONFIG_FIELD_AREA: "Área regada",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME: "Límite de tiempo",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_LIMIT_TIME: "Tiempo de espera",
  PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP:
    "Característica que impide que el equipo funcione fuera de las tensiones mínimas y máximas durante un tiempo superior al umbral.",
  PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL: "Reloj del equipo",
  PIVOT_CONFIG_FIELD_POTENCY: "Potencia de la bomba",
  PIVOT_CONFIG_FIELD_RTC: "Ajustar el reloj del equipo",
  PIVOT_CONFIG_FIELD_HOUR_RANGE: "Hora punta",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN: "Hora punta inicial",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX: "Hora máxima de finalización",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_ENABLE: "Habilitar pluviómetro",
  PIVOT_CONFIG_V5_FIELD_CLOCK_DATE: "Fecha del equipo",
  PIVOT_CONFIG_V5_FIELD_CLOCK_TIME: "Fecha del equipo",
  EDIT_PIVOT_LABEL_POWER: "Potencia de la bomba",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE: "Lectura de presión",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY: "Tiempo de retardo",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH: "Tiempo de presostato inestable",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END: "Fin de la hora punta",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1: "Habilitar tiempo de pico 1",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2: "Habilitar tiempo de pico 2",
  PIVOT_CONFIG_V5_FIELD_ANGLE_START: "Ángulo inicial",
  PIVOT_CONFIG_V5_FIELD_ANGLE_END: "Ángulo final",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_START: "Ángulo de inicio",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END: "Ángulo final",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_START: "Ángulo inicial",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_END: "Ángulo final",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_START: "Ángulo de inicio",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_END: "Ángulo final",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME: "Tiempo de espera de la autoreversión",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP:
    "Tiempo que tarda el pivote en detener su movimiento y cambiar de dirección.",
  EDIT_PLUVIOMETER_MODE_BY_VALUE: "Por valor",
  EDIT_PLUVIOMETER_MODE_DECREMENT: "Por decremento",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF: "Siempre apagado",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON: "Siempre activado",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS: "Activado por segmento",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH: "Por fin de carrera",
  SIMPLE_IRRIGATION_BY_ANGLE: "Por ángulo",
  PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER: "Booster",
  IRPD_FIELD_NAME: "Nombre de la bomba",
  PIVOT_CONFIG_V5_FIELD_POTENCY: "Potencia de la bomba",
  PIVOT_CONFIG_V5_IS_DATASHEET: "Hoja técnica",
  IRPD_MONTHLY_WATER_LIMIT: "Límite mensual de consumo de agua",
  EDIT_IRPD_FIELD_RTCDATE: "Reloj del Equipo",
  EDIT_IRPD_FIELD_HOUR_RANGE_MAX: "Fin de la hora punta",
  EDIT_IRPD_FIELD_HOUR_RANGE_MIN: "Inicio de la hora punta",
  MEASUREMENT_OFFSET: "Compensación de medición",
  MEASUREMENT_OFFSET_TOOLTIP:
    "Fijar el valor de las mediciones de la sonda, aproximándolas a las reales.",
  METERSYSTEM_FLOW_CURVE: "Caudal Residual",
  METERSYSTEM_FLOW_CURVE_TOOLTIP:
    "Cuando el medidor de nivel se instala en un río, la Plataforma de Irricontrol ofrece la opción de añadir las constantes de la Curva Clave del Río obtenidas mediante mediciones en el mismo. Mediante esta ecuación y el valor medido en la sonda de nivel, la Plataforma de Irricontrol calcula el caudal residual que se muestra en el Informe de la página de inicio del sistema de medición analizado.",
  PERMISSIONS_REGISTER_USER_INFO: "Permisos",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL: "Ubicación de la Central",
  PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP:
    "Ubicación de la última torre en el punto 0°.",
  PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP:
    "Ubicación del ángulo final del pivote",
  PIVOT_CONFIG_FIELD_CENTER_TOOLTIP: "Ubicación del centro del pivote.",
  NO_PERMISSION: "Sin permiso",
  NO_PERMISSION_DESCRIPTION:
    "En este permiso, el usuario no tendrá acceso al equipo seleccionado. Por lo tanto, no aparecerá en la pantalla de inicio de la granja.",
  VISUALIZER: "Visualizador",
  VIZUALIZER_DESCRIPTION:
    "En este permiso, el usuario tiene acceso al equipo y puede ver desde los informes hasta los comandos enviados. Sin embargo, el usuario no podrá enviar comandos al equipo, ni realizar ediciones de configuración en el equipo y no podrá acceder a la página de ediciones de la granja.",
  OPERATOR: "Operador",
  OPERATOR_DESCRIPTION:
    "En este permiso, el usuario tiene los mismos permisos que el Visor, además, puede enviar comandos al equipo. Sin embargo, el usuario no podrá realizar ediciones en la configuración del equipo y no podrá acceder a la página de ediciones de la granja.",
  CONFIGURATOR: "Configurador",
  CONFIGURATOR_DESCRIPTION:
    "En este permiso, el usuario tiene los mismos permisos que el Operador, además, podrá realizar ediciones de la configuración del equipo. Sin embargo, el mismo no podrá acceder a la página de ediciones de la granja",
  ADMINISTRATOR: "Administrador",
  ADMINISTRATOR_DESCRIPTION:
    " Finalmente, como Administrador, el usuario tendrá todos los permisos del Configurador. Tendrá acceso a los informes, al historial de comandos, al envío de nuevos comandos, a la edición de toda la información del equipo, a la edición de la granja, y también podrá añadir y gestionar usuarios",
  PERMISSION_INFO: "Información de permisos",
  HISTORIC_PIVOT_GPS_V5_SENSOR: "${} | ${} | ${} - ${} | ${}",
  HISTORIC_PIVOT_GPS_V5: "${} | ${} | ${} | ${}",
  VALUE: "${}",
  VALUE_HOUR_AND_MINUTE: "${}h ${}min",
  VALUE_AND_UNIT_HOUR: "${}h",
  VALUE_AND_UNIT_BAR: "${} bar",
  VALUE_AND_UNIT_MIN: "${} min",
  VALUE_AND_UNIT_DEGREES: "${}°",
  VALUE_AND_UNIT_PERCENT_MILIMETERS: "${}% - ${} mm",
  VALUE_AND_UNIT_MILIMETERS: "${} mm",
  VALUE_OF_TURNS: "${} vueltas",
  VALUE_AND_UNIT_POWER: "${} voltios",
  EDIT_PIVOT_MONITOR_LABEL: "Monitor Radio",
  CREATE_DEVICE_MODAL_MONITOR_CODE: "Monitor Radio",
  GPS_FOUND: "GPS/Monitor encontrado",
  EDIT_FARM_USER_PERMISSION_ERROR: "Error saving permissions",
  MOBILE_ONLY_INFO: "This feature only works on the mobile version.",
  I18N_KEYS: "TAGS I18N",

  ERROR_MAX_16_CHARACTERS: "El campo debe tener hasta 16 caracteres",
  ERROR_MAX_32_CHARACTERS: "El campo debe tener hasta 32 caracteres",
  ERROR_16_CARACTERS_RADIO_CONTROLER:
    "El campo Radio del Controlador debe contener hasta 16 caracteres",
  ERROR_16_CARACTERS_RADIO_GPS: "El campo Radio GPS debe tener 16 caracteres",
  ERROR_16_CARACTERS_RADIO_PUMP:
    "El campo Radio del cable de la bomba debe tener 16 caracteres",
  ERROR_16_CARACTERS_RADIO_MONITOR:
    "El campo Radio del Monitor debe tener 16 caracteres",
  ERROR_16_CARACTERS_RADIO_REPEATER:
    "El campo Radio del repetidor debe tener 16 caracteres",
  ERROR_16_CARACTERS_RADIO_IMETER:
    "El campo Radio del IMeter debe tener 16 caracteres",

  ERROR_INVALID_CHARACTERS: "El campo contiene caracteres no válidos",
  ERROR_INVALID_AMOUNT_MIN: "Por debajo de la cantidad mínima de caracteres",
  ERROR_INVALID_AMOUNT_MAX: "Por encima de la cantidad máxima de caracteres",
  ERROR_EMPTY_FIELD: "Este campo no puede estar vacío",
  ERROR_NEGATIVE_VALUES: "Rellenar sólo con valores positivos",
  ERROR_ZIP_CODE: "CEP no encontrado",
  ERROR_MIN_MAX_PRECIPITATION: "Debe tener un valor de hoja no válido",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN:
    "Valor mínimo de tensión, mayor que el máximo",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX:
    "Valor mínimo de tensión, mayor que el máximo",
  ERROR_GENERAL: "El campo tiene valores no válidos",
  ERROR_ZERO: "El valor del campo no puede ser 0",
  ERROR_ADD_NAME: "Añadir un nombre para el segmento",
  ERROR_PIVOT_LAT_AND_LONG: "Latitud y longitud no válidas",
  ERROR_PIVOT_LAT: "Latitud inválida",
  ERROR_PIVOT_LONG: "Longitud inválida",
  FORGET_PASSWORD_FAILED:
    "Las contraseñas deben tener:Nal menos 8 caracteres;Nal menos un número;Nal menos un carácter especial;",
  SIGN_UP_PASSWORD:
    "Contraseña (8+ caracteres incluyendo números y caracteres especiales)",
  EDIT_PROFILE_ADDRESS_2: "Address details",
  CPF_ALREADY_USED: "CPF already used on the platform",
  SIGN_UP_WARNING_ERROR: "This email or CPF is already registered",
  IRPD_STATUS_NO_COMMUNICATION: "No Communication",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5: "5.0",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1: "5.1",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_3: "5.3",
  METERSYSTEM_WATER_LEVEL: "Medición",
  METERSYSTEM_OFFSET_WATER_LEVEL: "Desplazamiento",
  METERSYSTEMGH_WATER_LEVEL: "Nivel del reservorio",
  METERSYSTEM_AJUSTED_WATER_LEVEL: "Nivel del Embalse",
  PIVOT_REPORT_TOTAL_COST_HINT:
    "Para cambiar los precios de las bandas de energía, vaya a la configuración de pivote.",
  EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE: "Precios de las Bandas de Energía",
  ERROR_VALUE_UNDER: "Este valor es menor que el mínimo aceptado",
  ERROR_VALUE_OVER: "Este valor es mayor que el máximo aceptado",
  ERROR_MIN_MAX: "Debe tener valores superiores a cero",
  CPF_ALREADY_REGISTERED: "Este correo o CPF ya está registrado",
  CURRENCY: "$",
  PIVOT_CONFIG_V5_BRAND_MODEL: "Fabricante",
  CREATE_DEVICE_MODAL_OTHER: "-",
  IRPD_PRESSURE_CHART_DAY: "Día",
  IRPD_PRESSURE_CHART_NUMBER_OF_POINTS: "Número de Puntos",
  NUMBER_OF_POINTS_12: "12",
  NUMBER_OF_POINTS_24: "24",
  NUMBER_OF_POINTS_30: "30",
  BASIC_INFO_BOX_CHART_PLUVIOMETER: "Pluviómetro",
  BASIC_INFO_BOX_CHART_WATER_CONSUMPTION: "Gráfico de Consumo",
  BASIC_INFO_BOX_CHART_WATER_PRESSURE: "Gráfico de Presión",
  IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR: "Sensor de presion",
  TABLE_HP: "Hora punta",
  BASIC_INFO_BOX_CHART_GENERATED_FOR: "Chart for ${}",
  MAINTENANCE_REASON_01: "Supply - Generator",
  MAINTENANCE_SPEC_NONE_01: "---",
  MAINTENANCE_SPEC_01A: "injector nozzle",
  MAINTENANCE_SPEC_01B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_01C: "injection pump",
  MAINTENANCE_SPEC_01D: "injector barrel",
  MAINTENANCE_SPEC_01E: "fuel shortage",
  MAINTENANCE_SPEC_01F: "fuel filter",
  MAINTENANCE_SPEC_01G: "water separator filter",
  MAINTENANCE_SPEC_01H: "fuel hose",
  MAINTENANCE_SPEC_01I: "fuel",
  MAINTENANCE_SPEC_01J: "fuel tank leakage",
  MAINTENANCE_REASON_02: "Supply - Water Pump",
  MAINTENANCE_SPEC_NONE_02: "---",
  MAINTENANCE_SPEC_02A: "injector nozzle",
  MAINTENANCE_SPEC_02B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_02C: "injection pump",
  MAINTENANCE_SPEC_02D: "injector barrel",
  MAINTENANCE_SPEC_02E: "fuel shortage",
  MAINTENANCE_SPEC_02F: "fuel filter",
  MAINTENANCE_SPEC_02G: "water separator filter",
  MAINTENANCE_SPEC_02H: "fuel hose",
  MAINTENANCE_SPEC_02I: "fuel",
  MAINTENANCE_SPEC_02J: "fuel tank leakage",
  MAINTENANCE_REASON_03: "Cooling - Water Pump",
  MAINTENANCE_SPEC_NONE_03: "---",
  MAINTENANCE_SPEC_03A: "water pump",
  MAINTENANCE_SPEC_03B: "alternator belt",
  MAINTENANCE_SPEC_03C: "belt tightener",
  MAINTENANCE_SPEC_03D: "primary air filter",
  MAINTENANCE_SPEC_03E: "secondary air filter",
  MAINTENANCE_SPEC_03F: "propeller",
  MAINTENANCE_SPEC_03G: "cracked radiator",
  MAINTENANCE_SPEC_03H: "radiator cap",
  MAINTENANCE_SPEC_03I: "belt tensioner",
  MAINTENANCE_REASON_04: "Cooling - Pivot",
  MAINTENANCE_SPEC_NONE_04: "---",
  MAINTENANCE_SPEC_04A: "water pump",
  MAINTENANCE_SPEC_04B: "alternator belt",
  MAINTENANCE_SPEC_04C: "belt tightener",
  MAINTENANCE_SPEC_04D: "primary air filter",
  MAINTENANCE_SPEC_04E: "secondary air filter",
  MAINTENANCE_SPEC_04F: "propeller",
  MAINTENANCE_SPEC_04G: "cracked radiator",
  MAINTENANCE_SPEC_04H: "radiator cap",
  MAINTENANCE_SPEC_04I: "belt tensioner",
  MAINTENANCE_REASON_05: "Centrifugal Pump",
  MAINTENANCE_SPEC_NONE_05: "---",
  MAINTENANCE_SPEC_05A: "gasket - tighten",
  MAINTENANCE_SPEC_05B: "gasket - change",
  MAINTENANCE_SPEC_05C: "lubricant - low level",
  MAINTENANCE_SPEC_05D: "damaged retainer",
  MAINTENANCE_SPEC_05E: "broken bearing",
  MAINTENANCE_REASON_06: "Vinasse Canal",
  MAINTENANCE_SPEC_NONE_06: "---",
  MAINTENANCE_SPEC_06A: "lack of vinasse",
  MAINTENANCE_SPEC_06B: "gate failure",
  MAINTENANCE_SPEC_06C: "low flow",
  MAINTENANCE_REASON_07: "Electricity - Water Pump",
  MAINTENANCE_SPEC_NONE_07: "---",
  MAINTENANCE_SPEC_07A: "alternator",
  MAINTENANCE_SPEC_07B: "automatic",
  MAINTENANCE_SPEC_07C: "battery",
  MAINTENANCE_SPEC_07D: "battery - cable",
  MAINTENANCE_SPEC_07E: "battery - support",
  MAINTENANCE_SPEC_07F: "wiring",
  MAINTENANCE_SPEC_07G: "fuse",
  MAINTENANCE_SPEC_07H: "starter motor",
  MAINTENANCE_SPEC_07I: "auxiliary start relay",
  MAINTENANCE_SPEC_07J: "solenoid",
  MAINTENANCE_REASON_08: "Electricity - Pivot",
  MAINTENANCE_SPEC_NONE_08: "---",
  MAINTENANCE_SPEC_08A: "alternator",
  MAINTENANCE_SPEC_08B: "battery",
  MAINTENANCE_SPEC_08C: "battery - cabble",
  MAINTENANCE_SPEC_08D: "battery - support",
  MAINTENANCE_SPEC_08E: "drive contactor",
  MAINTENANCE_SPEC_08F: "wiring",
  MAINTENANCE_SPEC_08G: "fuse",
  MAINTENANCE_SPEC_08H: "generator - brush",
  MAINTENANCE_SPEC_08I: "generator - brush holder",
  MAINTENANCE_SPEC_08J: "drive micro",
  MAINTENANCE_SPEC_08K: "security micro",
  MAINTENANCE_SPEC_08L: "starter motor",
  MAINTENANCE_SPEC_08M: "gearmotor",
  MAINTENANCE_SPEC_08N: "percent meter",
  MAINTENANCE_SPEC_08O: "plate",
  MAINTENANCE_REASON_09: "Structure - Water Pump",
  MAINTENANCE_SPEC_NONE_09: "---",
  MAINTENANCE_SPEC_09A: "header",
  MAINTENANCE_SPEC_09B: "support cable ratchet",
  MAINTENANCE_SPEC_09C: "suction hose",
  MAINTENANCE_SPEC_09D: "suction hose - clamp",
  MAINTENANCE_SPEC_09E: "suction hose - support cable",
  MAINTENANCE_SPEC_09F: "flat tire",
  MAINTENANCE_SPEC_09G: "wheel bearing",
  MAINTENANCE_SPEC_09H: "expansion tank",
  MAINTENANCE_SPEC_09I: "expansion tank - handle",
  MAINTENANCE_SPEC_09J: "expansion tank - register",
  MAINTENANCE_REASON_10: "Structure - Pivot",
  MAINTENANCE_SPEC_NONE_10: "---",
  MAINTENANCE_SPEC_10A: "cardan",
  MAINTENANCE_SPEC_10B: "cardan - flexible joint",
  MAINTENANCE_SPEC_10C: "cardan - universal joint",
  MAINTENANCE_SPEC_10D: "misalignment",
  MAINTENANCE_SPEC_10E: "sprinkler cleaning",
  MAINTENANCE_SPEC_10F: "pivot cleaning",
  MAINTENANCE_SPEC_10G: "span junction hose",
  MAINTENANCE_SPEC_10H: "broken hose",
  MAINTENANCE_SPEC_10I: "wheel stud",
  MAINTENANCE_SPEC_10J: "retaining plate",
  MAINTENANCE_SPEC_10K: "flat tire",
  MAINTENANCE_SPEC_10L: "sprinkler replacement",
  MAINTENANCE_SPEC_10M: "hose replacement",
  MAINTENANCE_SPEC_10N: "loose wheel",
  MAINTENANCE_SPEC_10O: "security system inoperative",
  MAINTENANCE_SPEC_10P: "front reducer support",
  MAINTENANCE_SPEC_10Q: "rear reducer support",
  MAINTENANCE_SPEC_10R: "trusses",
  MAINTENANCE_SPEC_10S: "reinforcement tube",
  MAINTENANCE_SPEC_10T: "base beam",
  MAINTENANCE_REASON_11: "Lubrication - Generator",
  MAINTENANCE_SPEC_NONE_11: "---",
  MAINTENANCE_SPEC_11A: "lubricant - filter",
  MAINTENANCE_SPEC_11B: "lubricant - low level",
  MAINTENANCE_SPEC_11C: "leakage - flexible",
  MAINTENANCE_SPEC_11D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_11E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_11F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_11G: "leakage - mirror cover",
  MAINTENANCE_SPEC_11H: "leakage - turbine",
  MAINTENANCE_REASON_12: "Lubrication - Water Pump",
  MAINTENANCE_SPEC_NONE_12: "---",
  MAINTENANCE_SPEC_12A: "lubricant - filter",
  MAINTENANCE_SPEC_12B: "lubricant - low level",
  MAINTENANCE_SPEC_12C: "leakage - flexible",
  MAINTENANCE_SPEC_12D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_12E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_12F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_12G: "leakage - mirror cover",
  MAINTENANCE_SPEC_12H: "leakage - turbine",
  MAINTENANCE_REASON_13: "Scheduled Downtime",
  MAINTENANCE_SPEC_NONE_13: "---",
  MAINTENANCE_SPEC_13A: "supply",
  MAINTENANCE_SPEC_13B: "change of location",
  MAINTENANCE_SPEC_13C: "pivoting",
  MAINTENANCE_SPEC_13D: "towing",
  MAINTENANCE_SPEC_13E: "hose change",
  MAINTENANCE_SPEC_13F: "wheels turning",
  MAINTENANCE_REASON_NONE: "---",
  MAINTENANCE_SPEC_NONE: "---",
  MAINTENANCE_NOTE_NONE: "-> ",
  MAINTENANCE_BUTTON_TOOLTIP_ENABLE: "Habilitar Modo Mantenimiento",
  MAINTENANCE_BUTTON_TOOLTIP_DISABLE: "Deshabilitar Modo Mantenimiento",
  MAINTENANCE_MODAL_REASON_QUESTION: "Inform reason for downtime?",
  MAINTENANCE_MODAL_REASON_LABEL: "Select a reason:",
  MAINTENANCE_MODAL_SPEC_QUESTION: "Specify the reason for downtime?",
  MAINTENANCE_MODAL_SPEC_LABEL: "Select a specification:",
  MAINTENANCE_MODAL_NOTE_QUESTION: "Any notes?",
  MAINTENANCE_MODAL_NOTE_LABEL: "Note:",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE:
    "¿Habilitar el Modo Mantenimiento?",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE:
    "¿Deshabilitar el Modo Mantenimiento?",
  MAINTENANCE_MODALS_BUTTON_CANCEL: "Cancelar",
  MAINTENANCE_MODALS_BUTTON_NEXT: "Siguiente",
  MAINTENANCE_MODALS_BUTTON_CONFIRM: "Confirmar",
  CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER: "Linear Pivot Monitor",
  CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID: "Invalid base radio",
  CREATE_DEVICE_MODAL_FLOW_RATE: "Flow Rate",
  CREATE_DEVICE_MODAL_PIVOT_LENGTH: "Length",
  CREATE_DEVICE_MODAL_PIVOT_SPEED: "Speed",
  EDIT_MONITOR_LINEAR_LABEL: "Edit Pivot Linear Monitor",
  PIVOT_CONFIG_FIELD_SIZE: "Pivot Size",
  PIVOT_CONFIG_FIELD_SIZE_TOOLTIP: "Total Length of the Linear Pivot",
  PIVOT_CONFIG_V5_LINEAR_SPEED: "Linear Pivot Speed",
  PIVOT_CONFIG_V5_LINEAR_SPEED_TOOLTIP: "Linear pivot speed at 100%",
  BASIC_INFO_BOX_TOTAL_WET_HOURS: "Total hours worked.",
  EDIT_LINEAR_PIVOT_MONITOR_LABEL: "Edit Linear Pivot Monitor",
  LPM_CONFIG_FIELD_LENGTH: "Length",
  LPM_CONFIG_FIELD_LENGTH_TOOLTIP: "Linear pivot total length",
  LPM_CONFIG_V5_FIELD_SPEED: "Speed",
  LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Speed of the linear pivot to 100%",
  BASIC_INFO_BOX_CHART_GENERATED_BETWEEN:
    "Chart for the period from ${} to ${}",
  BASIC_INFO_BOX_WATER_PRESSURE: "Presión",
  GRAPHIC_SCALE: "Graphic scale",
  GRAPHIC_SCALE_TOOLTIP:
    "The Graphic Scale refers to the maximum meters of water column (MCA) it can measure.",
  LPM_EFFICIENCY: "Eficiencia: ",
  IMETER_FIELD_NAME: "Meter name",
  METERSYSTEM_FIELD_NAME: "System Name",
  MCA_10: "10 MCA",
  MCA_20: "20 MCA",
  MCA_100: "100 MCA",
  IMETER_CONFIG_V5_FIELD_SENSOR: "Sensor",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS: "Fertirrigación: ",
  REPORT_TABLE_OPERATION_NOTE: "Observación",
  REPORT_TABLE_IRRIGATION_MODE: "Modo",
  PANEL_STREAM_STATUS_26: "fertirrigación",
  EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG: "Bomba de fertirrigación",
  SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP: "Fertirrigación",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE: "Comentarios",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED: "Comentarios: ",
  IRRIGATION_MODE_FERTIRRIGATION: "Fertirrigación",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP:
    'Seleccione "Habilitar fertirrigación" si desea utilizar la funcionalidad de fertirrigación en los comandos de riego de este pivote.',
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK: "Activar Fertirrigación",
  EDIT_IMETER_LEVEL_TAB: "Level",
  EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE: "Sensor's information",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE: "Chart configuration",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT:
    "These values are for inserting reference lines into the measurement chart.",
  EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT: "Maximum value",
  EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT: "Minimum value",
  BASIC_INFO_BOX_CHART_LAKE_LEVEL: "Level Chart",
  BASIC_INFO_BOX_LEVEL: "Level",
  GENERAL_BOX_MAX_LIMIT: "Maximum value:",
  GENERAL_BOX_MIN_LIMIT: "Minimum value:",
  PERIOD_TOTAL_RAINFALL: "Lluvia total para el período",
  PLUVIOMETER_CHART_NUMBER_OF_BARS: "Número de Barras",
  IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS: "Número de Barras",
  IMETER_LEVEL_CHART_NUMBER_OF_POINTS: "Número de Puntos",
  SEGMENTS_PLANT_DATE: "Fecha de plantación",
  SEGMENTS_HARVEST_DATE: "Fecha estimada de cosecha",
  EDIT_PIVOT_LABEL_PIVOT_SEGMENTS: "Segmentos y plantación",
  SEGMENT_NAME: "Nombre del segmento/plantación",
  MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY: "Recorte",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_PLANT_DATE: "Plantación: ${}",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_HARVEST_DATE: "Cosecha: ${}",
  NEW_SEGMENT: "Añadir/editar segmentos",
  HOVER_ON_SEGMENT:
    "Pase el cursor por debajo del dibujo para obtener información sobre el segmento",
  ANGLE_RANGE: "Rango de ángulos",
  ACCUMULATED_BLADE: "Hoja acumulada",
  SEGMENT_TYPE: "Tipo de plantación/gestión",
  CROP_WARNING_CONFIG:
    "Para más información, configure las fechas de plantación/gestión en editar pivote.",
  CROP_WARNING_INFO: "No hay información en este intervalo de tiempo.",
  CROP_WARNING_SECTORIAL:
    "Este pivote es sectorial. Los segmentos de pivote solo consisten en las áreas coloreadas del mapa.",
  PLUVIOMETER_WITHOUT_CONFIG: "Pluviometer not enabled",
  ACTIVATED: "Activated",
  DEACTIVATED: "Deactivated",
  EDIT_PIVOT_V5_AUTOREVERSION_MODE: "Condición de parada",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE: "Por ángulo",
  ENDGUN_START_ANGLE: "Inicio",
  ENDGUN_FINAL_ANGLE: "Fin",
  EDIT_IRPD_LOAD_SUCCESS: "¡Configuración cargada correctamente!",
  EDIT_IRPD_LOAD_ERROR: "Error al cargar la configuración.",
  EDIT_IRPD_LOAD_BUTTON: "Cargar",
  HISTORIC_CONFIG_INF_MONITOR: "Monitor",
  HISTORIC_CONFIG_INF_CONTROLLER: "Controller",
  HISTORIC_CONFIG_INF_HARDWARE: "Hardware",
  HISTORIC_CONFIG_INF_GPS: "GPS",
  HISTORIC_CONFIG_INF_PUMP: "Pump",
  HISTORIC_CONFIG_INF_DATASHEET: "Hoja técnica",
  HISTORIC_CONFIG_INF_IMETER: "Meter",
  HISTORIC_PANEL_LABEL_MONITOR: "MO",
  HISTORIC_PANEL_LABEL_CONTROLLER: "C",
  HISTORIC_PANEL_LABEL_HARDWARE: "H",
  HISTORIC_PANEL_LABEL_GPS: "G",
  HISTORIC_PANEL_LABEL_PUMP: "P",
  HISTORIC_PANEL_LABEL_IMETER: "ME",
  LEGEND: "Legend",
  ALL_CONFIGURATIONS: "All Configurations",
  CONFIG_NOT_SENT: "configuration not sent",
  CONFIG_SENT: "configuration sent, but not received",
  CONFIG_RECEIVED: "configuration received",
  TEXT_CONFIG_NOT_SENT: "Configuration was not sent to the device.",
  TEXT_CONFIG_SENT:
    "Configuration was sent to the device, but it was not received.",
  TEXT_CONFIG_RECEIVED: "Configuration was received by the device.",
  IRPD_EDIT_LABEL: "Edit Water Pump",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START: "Comienzo de la hora punta",

  BAUER_PIVOT_EDIT_ZERO_POSITION: "Posición Cero",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON: "Siempre Encendido",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE: "Modo Automatico",
  BAUER_PIVOT_EDIT_SECTOR_BEGIN: "Sector de inicio",
  BAUER_PIVOT_EDIT_SECTOR_END: "Sector final",
  BAUER_PIVOT_EDIT_AUTOREVERSE: "Normal",
  BAUER_PIVOT_EDIT_PARKING_FUNCTION: "Posición de estacionamiento",
  BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION: "Posición intermedia",

  BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE: "Presión actual",
  BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE: "Ángulo de corriente",
  BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE: "Tasa de riego",

  BAUER_PIVOT_HISTORY_TOOLTIP_USER: "Enviado por el usuario",
  BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE: "Enviado por dispositivo",

  BAUER_PIVOT_HISTORY_USER: "Descripción general",
  BAUER_PIVOT_HISTORY_PROTOCOL: "Protocolo",
  BAUER_PIVOT_HISTORY_PROTOCOL_TABLE: "Table de protocolo",

  BAUER_PIVOT_STATUS_0: "Máquina no en funcionamiento",
  BAUER_PIVOT_STATUS_1: "Máquina funcionando a velocidad de avance",
  BAUER_PIVOT_STATUS_2:
    "Esperando hasta la hora de inicio para comenzar el riego",
  BAUER_PIVOT_STATUS_3: "Riego detenido",
  BAUER_PIVOT_STATUS_4: "Circuito de seguridad interrumpido",
  BAUER_PIVOT_STATUS_5: "Máquina que funciona en modo de emergencia",
  BAUER_PIVOT_STATUS_6: "Máquina detenida por control remoto",
  BAUER_PIVOT_STATUS_7: "El interruptor de seguridad apagado",
  BAUER_PIVOT_STATUS_8: "Presión de agua demasiado baja",
  BAUER_PIVOT_STATUS_9: "Detenido por el fuerte viento",
  BAUER_PIVOT_STATUS_10: "Voltaje demasiado bajo",
  BAUER_PIVOT_STATUS_11: "Error de bus CAN",
  BAUER_PIVOT_STATUS_12: "Error I2C",
  BAUER_PIVOT_STATUS_13: "Iniciar tiempo de retraso activo",
  BAUER_PIVOT_STATUS_14: "Posición de estacionamiento alcanzada",
  BAUER_PIVOT_STATUS_15: "Máquina funcionando en marcha atrás",
  BAUER_PIVOT_STATUS_16: "Error GPS",
  BAUER_PIVOT_STATUS_17: "Detenido por la lluvia",
  BAUER_PIVOT_STATUS_18: "Error de esquina",
  BAUER_PIVOT_STATUS_19: "Error del sensor de span",
  BAUER_PIVOT_STATUS_20: "Error al sensor de inclinación",
  BAUER_PIVOT_STATUS_21: "Último error de la torre",
  BAUER_PIVOT_STATUS_22:
    "Distancia al cable de guía enterrado demasiado grande",
  BAUER_PIVOT_STATUS_23: "Señal de orientación enterrada demasiado baja",
  BAUER_PIVOT_STATUS_24: "Error de orientación enterrado",
  BAUER_PIVOT_STATUS_25: "Error del convertidor de frecuencia de esquina",
  BAUER_PIVOT_STATUS_26: "Error al compresor",
  BAUER_PIVOT_STATUS_27: "No hay movimiento detectado",
  BAUER_PIVOT_STATUS_28: "La posición de parada intermedia alcanzada",
  BAUER_PIVOT_STATUS_40: "Presión del agua demasiado alta",
  BAUER_PIVOT_STATUS_41: "Interruptor de emergencia presionado",

  BAUER_PIVOT_STATUS_NEW_CON: "Nueva comunicación",
  BAUER_PIVOT_STATUS_MAN_START: "Arranque manual",
  BAUER_PIVOT_STATUS_MAN_STOP: "Parada manual",
  BAUER_PIVOT_STATUS_ACK_FWD: "Avance recibido",
  BAUER_PIVOT_STATUS_ACK_REV: "Marcha atrás recibida",
  BAUER_PIVOT_STATUS_ACK_STOP: "Parada recibida",
  BAUER_PIVOT_STATUS_START_FWD: "Comando de avance enviado",
  BAUER_PIVOT_STATUS_START_REV: "Comando de marcha atrás enviada",
  BAUER_PIVOT_STATUS_STOP_CMD: "Comando de parada enviada",
  BAUER_PIVOT_STATUS_GET_CONFIG: "Obtener configuración",
  BAUER_PIVOT_STATUS_SET_CONFIG: "Establecer configuración",
  BAUER_PIVOT_STATUS_ACK_CONFIG: "Configuración recibida",
  BAUER_PIVOT_STATUS_ACK_VRI: "VRI recibido",
  BAUER_PIVOT_STATUS_RFTP_ERROR_1: "Archivo: No data",
  BAUER_PIVOT_STATUS_RFTP_MAX_RETRY: "Fichero: Límite excedido",
  BAUER_PIVOT_STATUS_RFTP_ERR_FILE: "Fichero: error de suma de comprobación",
  BAUER_PIVOT_STATUS_RFTP_OK: "Fichero: correcto",
  BAUER_PIVOT_STATUS_ACK_START: "Inicio recibido",
  BAUER_PIVOT_STATUS_DEFAULT: "Actualización",

  PUMP: "Bomba",
  IMETER: "iManage",
  EDIT_REPEATER_LABEL_GENERAL: "General",
  EDIT_REPEATER_CENTRAL_LABEL: "Radio central",
  EDIT_REPEATER_RADIO_LABEL: "Radio Repetidora",
  REPEATER_FIELD_NAME: "Nombre",
  REPEATER_CONFIG_FIELD_ENERGY_TYPE: "Tipo",
  REPEATER_CONFIG_FIELD_SOLAR: "Solar",
  REPEATER_CONFIG_FIELD_BIVOLT: "Bivolt",
  REPEATER_CONFIG_DEVICE_FIELD_GP_CENTER: "Ubicación del dispositivo",
  REPEATER_EDIT_LABEL: "Repeater",
  RADIO_SWAP_CONTROLLER_TITLE: "Cambiar la radio del Controlador",
  RADIO_SWAP_GPS_TITLE: "Cambiar la radio del GPS",
  RADIO_SWAP_PUMP_TITLE: "Cambiar la radio de la Bomba",
  RADIO_SWAP_IMETER_TITLE: "Cambiar la radio de iManage",
  RADIO_SWAP_REPEATER_TITLE: "Cambiar la radio del Repetidor",
  RADIO_SWAP_CONTROLLER_SUBTITLE:
    "Selecciona el pivote para cambiar la radio del Controlador.",
  RADIO_SWAP_GPS_SUBTITLE:
    "Seleccionar el pivote para cambiar la radio del GPS.",
  RADIO_SWAP_PIVOT_PUMP_SUBTITLE:
    "Selecciona el pivote para cambiar la radio de la Bomba.",
  RADIO_SWAP_IMETER_SUBTITLE: "Selecciona un iManage para cambiar de radio.",
  RADIO_SWAP_PUMP_SUBTITLE: "Selecciona una Bomba para cambiar el radio.",
  RADIO_SWAP_REPEATER_SUBTITLE:
    "Selecciona un Repetidor para cambiar de radio.",
  REPEATER: "Repeater",
  BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO:
    "Datos de presión generados entre ${} y ${}",
  BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO:
    "Datos de comparación generados entre ${} y ${}",
  BASIC_INFO_BOX_COMPARE_DATE_START: "Comienza en:",
  BASIC_INFO_BOX_COMPARE_DATE_END: "Parar en:",
  BASIC_INFO_BOX_PRESSURE_INFO:
    'Los valores de "Presión" son relativos al intervalo de tiempo definido por el informe. Los valores de "Presión de comparación" son relativos al intervalo de tiempo seleccionado en este gráfico. Utilice los datos de "Presión de comparación" para comparar las lecturas de presión de cada ángulo de riego entre distintos periodos de tiempo.',
  HISTORIC_IRPD_STREAM_CONSUMPTION: "Estimated consumption",
  HISTORIC_IRPD_STREAM_PRESSURE: "Pressure",
  DEVICE_BOX_SHOW_PERIODIC_STREAMS: "Show periodic streams",
  LOGIN_ERROR_ACCEPT_PRIVACY:
    "To continue accessing, accept the privacy policy",
  EDIT_FARM_BILLING: "Billing",
  FARM_BILLING_FIELD_PHONE: "Telephone",
  FARM_BILLING_FIELD_ADDRESS: "Address",
  FARM_BILLING_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  FARM_BILLING_FIELD_CITY: "City",
  FARM_BILLING_FIELD_STATE: "State",
  FARM_BILLING_FIELD_COUNTRY: "Country",
  ALERT_DIALOG_ALERT_DELETE:
    "Are you sure you want to delete this notification?",
  NOTIFICATIONS_TABPANEL_LABEL_PIVOTS: "Pivots",
  NOTIFICATIONS_TABPANEL_LABEL_MONITOR: "Pivot monitors",
  NOTIFICATIONS_TABPANEL_LABEL_PUMP: "Pumps",
  NOTIFICATIONS_TABPANEL_LABEL_METER: "Level meters",
  SHOW_REASONS: "Show reasons",
  HIDE_REASONS: "Hide reasons",
  EDIT_NOTIFICATIONS: "Edit notifications",
  EDIT_NOTIFICATIONS_QUESTION: "Do you wish to save your changes?",
  EDIT_NOTIFICATIONS_SAVE: "Save",
  CREATE_NOTIFICATIONS: "Create notifications",
  FARM_HAS_NO_NOTIFICATIONS: "You have no notifications for ${}",
  CREATE_NOTIFICATIONS_QUESTION:
    "Do you wish to create this group of notifications?",
  CREATE_NOTIFICATIONS_CREATE: "Create",
  DELETE_NOTIFICATIONS_QUESTION:
    "Do you wish to delete this group of notifications?",
  DELETE_NOTIFICATIONS: "Delete notifications",
  DELETE_NOTIFICATIONS_CANCEL: "Cancel",
  DELETE_NOTIFICATIONS_DELETE: "Delete",
  CREATE_NOTIFICATIONS_ALL_DAY: "All day",
  CREATE_NOTIFICATIONS_BACK: "Back",
  CREATE_NOTIFICATIONS_NEXT: "Next",
  CREATE_NOTIFICATIONS_FINISH: "Finish",
  CREATE_NOTIFICATIONS_BASIC_INFO: "Basic information",
  CREATE_NOTIFICATIONS_TIME_PERIOD: "Time",
  CREATE_NOTIFICATIONS_DEVICES: "Devices",
  CREATE_NOTIFICATIONS_REASONS: "Reasons",
  CREATE_NOTIFICATIONS_CHOOSE_NAME:
    "Choose a name for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER:
    "Type a name for this group of notifications",
  CREATE_NOTIFICATIONS_PICK_TIME: "Pick a time for this group of notifications",
  CREATE_NOTIFICATIONS_STARTS_ON: "Starts at",
  CREATE_NOTIFICATIONS_ENDS_ON: "Ends at",
  CREATE_NOTIFICATIONS_SELECT_THE_DEVICES:
    "Select the devices for this group of notifications",
  CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES: "Select all the devices",
  CREATE_NOTIFICATIONS_SELECT_REASONS:
    "Select the reasons for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_GENERATION:
    "Choose the pivot generation for this group of notifications",
  HISTORY_OF_MEASUREMENTS: "History of Measurements",
  FARM_BILLING_FIELD_COMPANY_NAME: "Company name",
  FARM_BILLING_FIELD_DOCUMENT: "Document",
  FARM_BILLING_FIELD_DOCUMENT_TYPE: "Document type",
  FARM_BILLING_FIELD_EMAIL: "Email",
  EDIT_BILLING_SAVE_SUCCESS: "Billing information saved",
  EDIT_BILLING_ERROR: "Error while saving billing information",
  FARM_GENERAL_INFORMATION_TITLE: "General Information",
  FARM_BILLING_DESCRIPTION:
    "This information will be used to issue invoices for the use of the Irricontrol Platform.",
  FARM_BILLING_INFORMATION_TITLE: "Billing Information",
  FARM_BILLING_ADDRESS_TITLE: "Billing Address",
  FARM_CONTACT_INFORMATION_TITLE: "Contact Information",
  FARM_CONTACT_ADDRESS_TITLE: "Contact Address",
  FARM_CONTACT_ADDRESS_SWITCH: "Same as billing address",
  FARM_LOCATION_INFORMATION_TITLE: "Location Information",
  CREATE_FARM_STEP_LABEL_GENERAL: "General",
  CREATE_FARM_STEP_LABEL_CONTACT: "Contact",
  CREATE_FARM_STEP_LABEL_BILLING: "Billing",
  CREATE_FARM_STEP_LABEL_LOCATION: "Location",
  CENTRAL_RADIO: "Central Radio",
  CREATE_FARM_QUESTION: "Do you wish to create this farm?",
  CREATE_FARM_CREATE: "Create",
  FARM_CONTACT_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  COMMUNICATION_TYPE: "Communication Type",
  CREATE_DEVICE_MODAL_XBEE: "XBee",
  CREATE_DEVICE_MODAL_4G: "4G",
  CREATE_DEVICE_MODAL_GATEWAY_CODE: "Gateway Number",
  ERROR_16_CARACTERS_GATEWAY_CODE:
    "The Gateway Number field must have up to 16 characters",
  ERROR_16_CARACTERS_RADIO_GATEWAY:
    "The Gateway Radio field must have up to 16 characters",
  COMMUNICATION_TYPE_OPTION_XBEE: "XBee",
  COMMUNICATION_TYPE_OPTION_4G_LTE: "4G/LTE",
  SELECTED_PIVOT_ONLINE: "Pivot with internet",
  SELECTED_PIVOT_OFFLINE: "Pivot without internet",
  CENTRAL_LINKED: "Does the farm have a Central?",
  CENTRAL_LINKED_TOOLTIP:
    "Farms that have a Central are those that use radio communication. Farms that do not have a Central are those that use 4G communication, available only for pivots.",
  FARM_LOCATION: "Farm Location",
  EDIT_PIVOT_GATEWAY_NUMBER_LABEL: "Gateway Number",
  EDIT_GATEWAY: "Edit gateway",
  SELECTED_IRPD_ONLINE: "Pump with internet",
  SELECTED_IRPD_OFFLINE: "Pump without internet",
  GATEWAY_CHANGED_SUCCESS: "Gateway changed successfully",
  GATEWAY_ID_ALREADY_USED: "Gateway already used on another device",
  SAVE_GATEWAY: "Save gateway",
  HISTORIC_DATA_UNDEFINDED: "Sin definir",
  HOVER_OVER_SEGMENT:
    "Hover the cursor over the drawing for segment information",
  CREATE_NOTIFICATIONS_CRITICAL: "Critical",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS:
    "Do you want this notification to be a critical alert?",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO:
    "Critical alerts play different and louder sounds than conventional notifications. These notifications are disruptive and should be used when immediate action is required, for example in the event of unexpected downtime.",
  CRITICAL_ALERTS_QUESTION_ENABLE: "Do you want to enable critical alerts?",
  CRITICAL_ALERTS_QUESTION_DISABLE: "Do you want to disable critical alerts?",
  CRITICAL_ALERTS_CANCEL: "Cancel",
  CRITICAL_ALERTS_ENABLE: "Enable",
  CRITICAL_ALERTS_DISABLE: "Disable",
  CRITICAL_ALERTS: "Critical alerts",
  FARM_PAYMENT_METHOD_TITLE: "Payment Method",
  FARM_BILLING_HISTORY_TITLE: "Billing History",
  BILLING_HISTORY_NO_INVOICES_FOUND: "No invoices found",
  BILLING_HISTORY_NO_BILLING_DATA_FOUND: "Billing data not found",
  BILLING_INVOICE_DUE_DATE: "Due Date",
  BILLING_INVOICE_STATUS: "Status",
  BILLING_INVOICE_STATUS_OPEN: "Open",
  BILLING_INVOICE_STATUS_PAID: "Paid",
  BILLING_INVOICE_STATUS_PAST_DUE: "Past due",
  BILLING_INVOICE_STATUS_VOID: "Void",
  BILLING_INVOICE_AMOUNT: "Amount",
  BILLING_INVOICE_LINK: "Link",
  BILLING_INVOICE_LINK_PAY: "Pay",
  BILLING_INVOICE_LINK_DOCS: "Documents",
  HISTORIC_PAINEL_TYPE_POSITION_STREAM: "Actualización de ángulo",
  DEVICE_BOX_SHOW_POSITION: "Mostrar posición",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Condiciones de uso y protección de datos",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE:
    "Actualización de las condiciones de uso y protección de datos",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT: "Nuestras",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2:
    "pretendemos añadir condiciones al uso de nuestros servicios para mantener el cumplimiento de las leyes vigentes que garantizan la privacidad de tus datos",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT: "Hemos actualizado nuestros",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2:
    "para añadir nuevas condiciones al uso de nuestros servicios y mantener el cumplimiento de las leyes vigentes que garantizan la privacidad de tus datos",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN:
    "Al continuar, aceptas nuestras Condiciones actualizadas y todas las políticas descritas en ellas. Acceda a ellas y revíselas en",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK:
    "Condiciones de uso y protección de datos",
  POLICY_TERMS_AND_CONDITIONS_CHECKBOX:
    "Declaro que he leído y acepto las Condiciones de Uso y Protección de Datos de la Plataforma Irricontrol",
  ACCEPT_TEXT: "ACEPTO",
  PAYMENT_METHOD_WARNNING:
    "Facturas en pago manual. Para activar los pagos automáticos, añada una tarjeta de crédito a las opciones de pago.",
  PAYMENT_METHOD_ADD: "Agregar método de pago",
  PAYMENT_METHOD_UPDATE: "Actualizar método de pago",
  PAYMENT_METHOD_CREDIT_CARD_NUMBER: "Número de tarjeta de crédito",
  PAYMENT_METHOD_CREDIT_CARD_EXPIRY: "Validez",
  PAYMENT_METHOD_CREDIT_NAME: "Nombre en la tarjeta",
  DISPLAY_DATE_FORMAT: "DD/MM/YYYY",
  DEBTOR_NOTIFICANTION_USER:
    "Su granja tiene pagos atrasados. Pida a su administrador que realice los pagos",
  DEBTOR_NOTIFICANTION_ADM:
    "Su granja tiene pagos atrasados. Realice los pagos",
  DEBTOR_NOTIFICANTION_LINK: "<en configuración>",
  DEBTOR_NOTIFICANTION_COUNT:
    "La granja se bloquea automáticamente en ${} días",
  BILLING_DESCRIPTION_TEXT:
    "La pestaña de facturación concentra la información financiera esencial relacionada con las transacciones comerciales en la plataforma.",
  PAYMENT_METHOD_WARNING:
    "Para habilitar los pagos automáticos, añada una tarjeta de crédito",
  BILLING_WARNING: "¡Hay pagos atrasados!",
  SUCCESS: "Éxito",
  SUCCESS_PAGE_TEXT: "Forma de pago actualizada correctamente",
  CANCEL_PAGE_TEXT: "No se ha podido actualizar el método de pago",
  BILLING: "Facturación",
  UNDER_CONSTRUCTION: "En construcción",
  PRESSURE_CHART_COMPARISON_END_TOWER: "Presión de comparación - Última torre",
  PRESSURE_CHART_END_TOWER: "Presión - Última torre",
  PRESSURE_CHART_COMPARISON_CENTER: "Presión de comparación - Torre central",
  PRESSURE_CHART_CENTER: "Presión - Torre central",
  EDIT_PROFILE_TERMS_AND_CONDITIONS:
    "Para más información sobre las condiciones que rigen la plataforma Irricontrol visite ",
  EDIT_FARM_ERROR_FIELDS: "Error en el registro, compruebe los campos de nuevo",
  EDIT_FARM_ERROR: "No se ha podido registrar la granja",
  NO_INFO: "No hay comunicación",
  HARDWARE_ID_ALREADY_EXISTS: "Id. de hardware ya utilizado",
  ENCODER: "Codificador",
  ENCODER_DESCRIPTION_TEXT:
    "Los sistemas de pivote LTE/4G emplean un equipo de precisión instalado en la torre central, que se encarga de registrar la posición angular del pivote en tiempo real. La pestaña del codificador está dedicada a configurar y calibrar el codificador, lo que permite establecer la referencia angular de 0° del pivote en función de su posición actual.",
  ENCODER_BTN: "Restablecer el ángulo de 0°",
  ENCODER_CONFIRMATION: "¿Desea restablecer el ángulo de giro actual a 0º?",
  EDIT_ENCODER_LABEL_SETTING_HISTORIC:
    "Historial de restablecimiento del encoder",
  REDEFINE: "Sí",
  REDEFINE_ENCONDER_LABEL: "Reinicio del encoder",
  EDIT_ENCODER_SAVE_AWAIT_ERROR:
    "Error al restablecer el codificador al ángulo 0º",
  EDIT_ENCODER_SAVE_AWAIT_SUCCESS: "Encoder reseteado a ángulo 0º",
  ENCODER_RESET_INSTRUCTION_TITLE: "Cómo utilizar el reinicio del encoder",
  ENCODER_RESET_INSTRUCTION_STEP1: "Paso 1:",
  ENCODER_RESET_INSTRUCTION_STEP1_TEXT:
    "Coloque el pivote en el punto de referencia inicial deseado. Asegúrese de que el encoder instalado en el centro del pivote está en la posición correcta",
  ENCODER_RESET_INSTRUCTION_STEP2: "Paso 2:",
  ENCODER_RESET_INSTRUCTION_STEP2_TEXT:
    'Haga clic en el botón "RESET ANGLE 0º"',
  ENCODER_RESET_INSTRUCTION_STEP3: "Paso 3:",
  ENCODER_RESET_INSTRUCTION_STEP3_TEXT:
    'Se abrirá una pantalla de confirmación. Confirme el restablecimiento haciendo clic en "Sí"',
  ENCODER_RESET_INSTRUCTION_DESCRIPTION:
    "Es importante tener en cuenta que este equipo conserva el ángulo actual, incluso cuando el panel pivotante está apagado. Por lo tanto, sólo utilice el reset durante la primera instalación, en casos de sustitución del equipo, o cuando se actualice la configuración de referencia inicial.",
  PAYMENT_METHOD_CREDIT_CARD: "Tarjeta de crédito",
  PAYMENT_METHOD_CREDIT_BILL: "Recibo bancario",
  EDIT_BILLING_NOT_FOUND_USER:
    "No hay información de facturación registrada. Pida a su administrador que la registre",
  EDIT_BILLING_NOT_FOUND_ADM:
    "No hay información de facturación registrada. Realice el registro",
  PAYMENT_METHOD_DELETE_TEXT:
    "¿Está seguro de que desea eliminar los cargos automáticos?",
  PAYMENT_METHOD_DELETE_TITLE: "Eliminar la facturación automática",
  PAYMENT_METHOD_DELETE_BTN: "Eliminar",
  PAYMENT_METHOD_UPDATE_BTN: "Actualizar",
  BILLING_PREFERRED_LANGUAGE: "Idioma preferido para el correo electrónico",
  BILLING_PREFERRED_LANGUAGE_TOOLTIP:
    'El "Idioma preferido para los correos electrónicos" determina en qué idioma recibirá las comunicaciones por correo electrónico sobre pagos y detalles de facturas en la Plataforma Irricontrol. La selección de este idioma no cambia el idioma de la interfaz en la Plataforma.',
  FARM_PAYMENT_METHOD_DESCRIPTION:
    'Añada una "Tarjeta de Crédito" para habilitar el pago automático de las facturas. Esta opción garantiza que sus facturas se paguen en la fecha correcta, sin necesidad de intervención manual en cada ciclo de facturación, evitando cualquier tipo de bloqueo en la plataforma.',
  REPORT_EMAIL_NOTIFICATION:
    "El informe se enviará por correo electrónico en un plazo de ${} min.",
  REPORT_PROCESSING_NOTIFICATION: "Informe en trámite. Estimación: ${} mín.",
  REPORT_OPERATION: "Informe de operación",
  REPORT_FARM: "Informar granja",
  REPEATER_FIELD_HEIGHT: "Altura",
  BATTERY_LEVEL_LOW: "Batería baja",
  BATTERY_LEVEL_CHARGED: "Batería completamente cargada",
  PERIODIC_WARNING: "Periódico",
  GH_LEVEL_TITLE: "Información del depósito",
  GH_LEVEL_DESCRIPTION:
    "Valores necesarios para que el Medidor de Nivel monitoree y controle el depósito",
  GH_MAX_LINE_TOOLTIP:
    "Valor máximo porcentual deseado del nivel máximo del depósito. Una vez alcanzado este valor, el Medidor de Nivel apagará las bombas asociadas.",
  GH_MIN_LINE_TOOLTIP:
    "Valor mínimo porcentual deseado del nivel máximo del depósito, para referencia visual de las mediciones",
  GH_RESERVOIR_TOOLTIP:
    "Nivel máximo de agua que el depósito está diseñado para soportar",
  GH_OFFSET_TOOLTIP:
    "Valor de ajuste para las mediciones del nivel, si la sonda no alcanza el fondo del depósito",
  ERROR_GH_MIN_LINE: "Debe ser menor que el valor máximo y al menos 0",
  ERROR_GH_MAX_LINE: "Debe ser diferente de cero y mayor que el valor mínimo",
  ROUNDS_LIMIT_TITLE: "Límite de vueltas de pivote",
  ROUNDS_LIMIT_DESCRIPTION:
    "Número máximo de vueltas de pivote permitidas antes de la parada automática",
};

export default i18n_es;
