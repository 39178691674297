import { UserProfile } from "../sharedTypes";

export enum PivotsTypes {
  SET_PIVOTS = "@@pivots/SET_PIVOTS",
  UPDATE_OR_INSERT_PIVOT = "@@pivots/UPDATE_OR_INSERT_PIVOT",
  SET_CONVENTIONAL_CONFIG = "@@pivots/SET_CONVENTIONAL_CONFIG",
}

export interface PivotConfig {
  id: number;
  pinned?: boolean;
  name?: string;
  uuid?: string | null;
  arrived: string;
  created: string;
  manual: boolean;
  radius: number;
  flow: number;
  speed: number;
  total_radius: number;
  area: number;
  center: string;
  reference: string;
  setorial: boolean;
  end_reference: string;
  image_maps_url: string;
  reversion: boolean;
  endgun: number;
  extra: boolean;
  rtc: string;
  pump_time: number;
  power_time: number;
  power_range_max: number;
  power_range_min: number;
  hour_range_max: string;
  hour_range_min: string;
  sent: boolean;
  delivered: boolean;
  received: boolean;
  pump_delivered: boolean;
  gps_delivered: boolean;
  image_url?: any;
  user: any;
  username?: string;
  pivot: number;
  sector_angle?: number | null;
  content?: any;
  kwh_peak?: string;
  kwh_out_of_peak?: string;
  kwh_reduced?: string;
}

export interface PanelStreamOld {
  id: number;
  uuid?: any;
  arrived: string;
  created: string;
  payload: string;
  door: boolean;
  power: boolean;
  security: boolean;
  termo: boolean;
  reason: number;
  frame_id: number;
  batery: number;
  hourmeter: number;
  phase_a: number;
  watmeter: number;
  pressure_switch: number;
  end_course: boolean;
  reversion: number;
  sms_sent: boolean;
  device: string;
  pivot: number;
}

export interface Pivot {
  id: number;
  communication_type: number;
  image?: string | null;
  config?: PivotConfig;
  controllerconfig?: ControllerConfig;
  latest_panel_stream?: PanelStreamOld;
  controllerstream_panel?: ControllerStream;
  controllerstream_gps?: ControllerStream;
  controllerstream_periodic?: ControllerStream;
  lpm_gps_streams?: any;
  latest_gps_stream: GPSStream;
  latest_pluviometer_stream: PluviometerStream;
  name: string;
  potency: number;
  brand_model: string;
  panel_type: string;
  reference_angle?: number;
  protocol: number;
  pluviometer?: boolean;
  farm: number;
  base: number;
  monitor: number;
  control: number;
  pump?: number;
  base_radio_id?: string;
  monitor_radio_id?: string;
  control_radio_id?: string;
  automation_type?: number;
  pump_radio_id?: string;
  is_online?: boolean;
  map_history?: number[];
  permission_level: number;
  irrigation_end_angle: number;
  type: "FULL"; //Usado para ser diferenciado do PivotLight
  regenerate_reports?: boolean;
  // Irrifast exclusive fields
  is_irrifast?: boolean;
  irrifast_tower_status?: {
    tower_number: number;
    aligment_status: number;
  }[];
}

export interface Device {
  id: number;
  created?: Date;
  radio_id: string;
}

export interface PivotActionVRI {
  id?: number;
  uuid?: string;
  arrived?: string;
  created?: string;
  updated_at?: string;
  name?: string;
  mode?: number;
  start_angle: number;
  direction_array: number[]; //avanço: 1, reverso: 0
  speed_array: number[];
  endgun_array: number[];
  angle_array: number[];
  manual: boolean;
  saved?: boolean;
  origin?: number;
  user?: UserProfile;
  pivot: number;
}

export interface PivotActionVRIDeliverer {
  id: number;
  uuid: string;
  arrived: string;
  created: string;
  sent: boolean;
  achieve: number;
  delivered: number;
  start?: any;
  recomendation: boolean;
  display: boolean;
  manual: boolean;
  frame_id: number;
  actionrecipe: PivotActionVRI;
  user: number;
  user_profile: UserProfile;
  content?: any;
}

export interface GPSStream {
  id: number;
  uuid?: any;
  arrived: string;
  created: string;
  payload: string;
  position: string;
  water_pressure: number;
  content?: any;
  battery: number;
  power: number;
  clockwise: number;
  angle: string;
  frame_id: number;
  current: number;
  pressure_center: number;
  speed: number;
  reason: number;
  hourmeter: number;
  device: string;
  pivot: number;
}

export interface PivotAction {
  id: number;
  uuid: string;
  arrived: string;
  created: string;
  updated_at: string;
  manual: boolean;
  sent: boolean;
  achieve: number;
  delivered: number;
  name: string;
  start?: any;
  direction: number;
  speed: number;
  endgun: number;
  frame_id: number;
  mode: number;
  injector: number;
  scheduled_stop: number;
  recomendation: boolean;
  display: boolean;
  end?: any;
  user: number;
  user_profile: UserProfile;
  pivot: number;
}

export interface IrrigationV5 {
  uuid: string;
  next: string;
  message_error?: any;
  previous: string;
  created_on_hardware: boolean;
  created_at?: string;
  username?: string;
  created: string;
  device: number;
  arrived: string;
  message_status: number;
  message_subtype?: string;
  gps_config?: number;
  created_by: any;
  pivot?: number;
  content: any;
  total_flow: number;
}

export interface PluviometerStream extends IrrigationV5 {
  farm: number;
  equipment: any;
}

export interface SimpleIrrigation extends IrrigationV5 {
  end_date_forecast?: string;
  operation_duration_forecast?: number;
  current_angle: number;
}
export interface ScheduleIrrigation extends IrrigationV5 {
  end_date_forecast?: string;
  operation_duration_forecast?: number;
  current_angle: number;
  angle_V5: boolean;
}
export interface SegmentIrrigation extends IrrigationV5 {
  end_date_forecast?: string;
  operation_duration_forecast?: number;
  current_angle: number;
}
export interface StopAction extends IrrigationV5 {
  end_date_forecast?: string;
  operation_duration_forecast?: number;
  current_angle: number;
}
export interface ControllerStream {
  id?: number;
  uuid: string;
  created_on_hardware: boolean;
  created_at?: Date;
  created: Date;
  reason: number;
  arrived_at: Date;
  content: any;
  is_gps_update: boolean;
  message_status: number;
  arrived: Date;
  equipment: number;
  current_angle: number;
  end_tower_pressure: number;
}
export interface ControllerConfig extends IrrigationV5 {
  id?: number;
  pump_config: number;
  pinned?: boolean;
  name?: string;
  segments_crop: any[];
  device_statuses?: {
    gps: number;
    pump: number;
    hwsettings: number;
  };
  kwh_peak?: string;
  kwh_out_of_peak?: string;
  kwh_reduced?: string;
  injection_pump?: boolean;
  brand_model?: string;
  panel_type?: string;
  potency?: any;
  name_pivot_on_config?: string;
}

export interface PivotMaintenance {
  uuid: string;
  pivot: number;
  user: any;
  maintenance: boolean;
  created: Date;
}

export interface CentralStream {
  uuid: string;
  status: boolean;
  created: Date;
  message_subtype?: string;
  content: any;
}

export enum PIVOT_COMMUNICATION_TYPE {
  TYPE_XBEE,
  TYPE_4G,
}

export const EmptyPivot = (): Pivot => {
  return {
    id: -1,
    communication_type: PIVOT_COMMUNICATION_TYPE.TYPE_XBEE,
    config: {
      id: -1,
      arrived: "string",
      created: "string",
      manual: false,
      radius: -1,
      flow: -1,
      speed: -1,
      total_radius: -1,
      area: -1,
      center: "string",
      reference: "string",
      setorial: false,
      end_reference: "string",
      image_maps_url: "string",
      reversion: false,
      endgun: -1,
      extra: false,
      rtc: "string",
      pump_time: -1,
      power_time: -1,
      power_range_max: -1,
      power_range_min: -1,
      hour_range_max: "string",
      hour_range_min: "string",
      sent: false,
      delivered: false,
      received: false,
      pump_delivered: false,
      gps_delivered: false,
      image_url: "string",
      user: -1,
      pivot: -1,
    },
    latest_panel_stream: {
      id: -1,
      arrived: "string",
      created: "string",
      payload: "string",
      door: false,
      power: false,
      security: false,
      termo: false,
      reason: -1,
      frame_id: -1,
      batery: -1,
      hourmeter: -1,
      phase_a: -1,
      watmeter: -1,
      pressure_switch: -1,
      end_course: false,
      reversion: -1,
      sms_sent: false,
      device: "string",
      pivot: -1,
    },
    latest_gps_stream: {
      id: -1,
      arrived: "string",
      created: "string",
      payload: "string",
      position: "string",
      water_pressure: -1,
      battery: -1,
      power: -1,
      clockwise: -1,
      angle: "string",
      frame_id: -1,
      current: -1,
      pressure_center: -1,
      speed: -1,
      reason: -1,
      hourmeter: -1,
      device: "string",
      pivot: -1,
    },
    latest_pluviometer_stream: null,
    name: "string",
    potency: 0,
    permission_level: 0,
    brand_model: "string",
    panel_type: "string",
    protocol: 4.0,
    farm: 0,
    base: 0,
    monitor: 0,
    control: 0,
    irrigation_end_angle: 0,
    type: "FULL",
  };
};

export interface PivotsState {
  list: Pivot[];
}
