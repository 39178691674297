import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import { ValidationErrorMessage } from "../../../../../../components/Form/types";

import { Button, Box, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  ArrowBack,
  Settings,
  Map,
  PieChart,
  PanoramaFishEye,
} from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";
import "./TablePivotSegments.scss";
import "./EditPivotFormV5.scss";
import { useStyles } from "./EditPivotFormV5.style";
import useNotify from "../../../../../../hooks/tools/useNotify";
import Routes from "../../../../../../routes/routes";
import { useParams, useNavigate } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green, red } from "@material-ui/core/colors";
import { isMobile } from "../../../../../../mobileConfig";

import MobileZone from "../../../../../../components/Zones/MobileZone";
import { showModal } from "../../../../../../redux/modal/actions";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import { useDispatch, useSelector } from "react-redux";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { centerMarkerSelector } from "../../../../../../recoils/DraggableMapRecoil";

import styled from "@emotion/styled";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import { Alert } from "@material-ui/lab";
import GeneralAngleSelector from "../../../../../../components/GeneralAngleSelector/GeneralAngleSelector";
import { BLUE_COLORS_TABLE } from "../../../utils/utils";
import EndgunAngleSelector from "../../../../../../components/EndgunAngleSelector/EndgunAngleSelector";
import DialogModal from "../../../../../../components/DialogModal/DialogModal";
import axios from "axios";
import _ from "lodash";
import DraggableMap from "../EditPivotForm/components/DraggableMap/DraggableMap";
import FormInputPosition from "../EditPivotForm/components/FormInputPosition/FormInputPosition";
// import FormSwitch from "../../../../../../components/Form/FormSwitch/FormSwitch";
import { BauerPivotObj } from "../../../../../../redux/bauerPivot/types";

import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import {
  checkIsOnIntervalString,
  validateSegments,
} from "../../../../../../utils/checks";

const greenDot = require("../../../../../../assets/images/markers/light-green-dot.svg");

const { SOCKET_SUFFIX } = process.env;

const StyledH4 = styled.h4`
  margin: 10px 0px 0px 0px;
`;
interface Props {
  farmID: number;
  bauerPivot: BauerPivotObj;
  setBauerPivot: React.Dispatch<BauerPivotObj>;
}

export interface Segments {
  number_editing: number;
  angle_start: number;
  angle_end: number;
}

export interface Endgun {
  number_editing: number;
  start_angle: number;
  end_angle: number;
}

export enum TYPE {
  CENTRAL_PIVOT_AUTOMATION = 0,
  CENTRAL_PIVOT_MONITOR,
  LINEAR_PIVOT_MONITOR,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box style={{ height: "100%" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

//Functions to give start values

// Start angles array
const generateInitialAngles = (bauerPivot) => {
  let segmentArray = [];
  for (let index = 6; index > 0; index--) {
    if (
      bauerPivot.latest[`segment_${index}_end`] == 0 ||
      !bauerPivot.latest[`segment_${index}_end`]
    )
      continue;
    else
      segmentArray.push({
        angle: bauerPivot.latest[`segment_${index}_end`],
        fwd: bauerPivot.latest[`segment_${index}_fwd`],
        rev: bauerPivot.latest[`segment_${index}_rev`],
        crop_harvest_date: null,
        crop_plant_date: null,
        name: `Segment ${index}`,
        segment_type: "",
      });
  }
  if (segmentArray.length == 0) {
    segmentArray.push({
      angle: 360,
      fwd: 10,
      rev: 10,
      crop_harvest_date: null,
      crop_plant_date: null,
      name: `Segment 1`,
      segment_type: "",
    });
  }
  return segmentArray.reverse();
};

const generateArrayAngleStart = (arrayAngles) => {
  let arrayAngleStart = ["0"];
  for (let i = 0; i < arrayAngles.length - 1; i++) {
    if (arrayAngles[i].angle)
      arrayAngleStart.push(arrayAngles[i].angle.toString());
  }

  return arrayAngleStart;
};

const generateArrayAngleEnd = (arrayAngles) => {
  let arrayAngleEnd = [];
  if (arrayAngles) {
    for (let i = 0; i < arrayAngles.length; i++) {
      if (arrayAngles[i].angle)
        arrayAngleEnd.push(arrayAngles[i].angle.toString());
    }
    return arrayAngleEnd;
  }
  return ["360"];
};

// Start endgunArray
const generateInitialEndgunArray = (bauerPivot) => {
  let endGunArr = [];
  for (let index = 6; index > 0; index--) {
    if (
      bauerPivot.latest[`end_gun_1_segment_${index}_end`] == 0 ||
      !bauerPivot.latest[`end_gun_1_segment_${index}_end`]
    )
      continue;
    else
      endGunArr.push({
        end_angle: bauerPivot.latest[`end_gun_1_segment_${index}_end`],
        number_editing: 0,
        start_angle: bauerPivot.latest[`end_gun_1_segment_${index}_begin`],
      });
  }
  if (endGunArr.length == 0) {
    endGunArr.push({
      end_angle: 360,
      number_editing: 0,
      start_angle: 0,
    });
  }
  return endGunArr.reverse();
};

// Start endgun2Array
const generateInitialEndgun2Array = (bauerPivot) => {
  let endGunArr = [];
  for (let index = 6; index > 0; index--) {
    if (
      bauerPivot.latest[`end_gun_2_segment_${index}_end`] == 0 ||
      !bauerPivot.latest[`end_gun_2_segment_${index}_end`]
    )
      continue;
    else
      endGunArr.push({
        end_angle: bauerPivot.latest[`end_gun_2_segment_${index}_end`],
        number_editing: 0,
        start_angle: bauerPivot.latest[`end_gun_2_segment_${index}_begin`],
      });
  }
  if (endGunArr.length == 0) {
    endGunArr.push({
      end_angle: 360,
      number_editing: 0,
      start_angle: 0,
    });
  }
  return endGunArr.reverse();
};

function NewEditPivotV5(props: Props) {
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    name: undefined,
    pivotSegments: undefined,
    sectorStart: undefined,
    sectorEnd: undefined,
    lastTowerRadius: undefined,
  });

  const { bauerPivot, setBauerPivot } = props;

  const kmInLatAngle = 0.0089928;

  const [value, setValue] = useState(0);
  const classes = useStyles(props);
  const notify = useNotify();
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [arrayAngles, setArrayAngles] = useState(() =>
    generateInitialAngles(bauerPivot)
  );

  const [event, setEvent] = useState(false);

  // ------------------------------------------------------------------ COMEÇO DAS ABAS

  // GERAL -------------------------------------------------------------------

  const setValidName = useCallback(
    (value) => {
      setBauerPivot({ ...bauerPivot, name: value });
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 1, 16),
      });
    },
    [validationErrors]
  );

  const setAutoreverse = useCallback(() => {
    setBauerPivot({
      ...bauerPivot,
      latest: {
        ...bauerPivot.latest,
        autoreverse: true,
        parking_on_off: false,
        intermediate_on_off: false,
      },
    });
  }, [bauerPivot, setBauerPivot]);

  const setParkingOnOff = useCallback(() => {
    setBauerPivot({
      ...bauerPivot,
      latest: {
        ...bauerPivot.latest,
        autoreverse: false,
        parking_on_off: true,
        intermediate_on_off: false,
      },
    });
  }, [bauerPivot, setBauerPivot]);

  const setParkingPosition = useCallback(
    (value: string) => {
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            parking_position: 0,
          },
        });
      } else if (parseInt(value) >= 0 && parseInt(value) <= 360) {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            parking_position: parseInt(value) * 100,
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  const setIntermediateOnOff = useCallback(() => {
    setBauerPivot({
      ...bauerPivot,
      latest: {
        ...bauerPivot.latest,
        autoreverse: false,
        parking_on_off: false,
        intermediate_on_off: true,
      },
    });
  }, [bauerPivot, setBauerPivot]);

  const setIntermediatePosition = useCallback(
    (value: string) => {
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            intermediate_position: 0,
          },
        });
      } else if (parseInt(value) >= 0 && parseInt(value) <= 360) {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            intermediate_position: parseInt(value) * 100,
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  // LOCALIZAÇÃO -------------------------------------------------------------

  const [pivotCenterCoordsError, setPivotCenterCoordsError] = useState(false);

  // ANGLE START
  const [centerLat, setCenterLat] = useState(
    bauerPivot.center_latitude.toString()
  );
  const [centerLatLabel, setCenterLatLabel] = useState("");
  const [centerLng, setCenterLng] = useState(
    bauerPivot.center_longitude.toString()
  );
  const [centerLngLabel, setCenterLngLabel] = useState("");

  // ANGLE END
  const [workingAngle, setWorkingAngle] = useState(
    bauerPivot.latest.sector_end / 100 - bauerPivot.latest.sector_begin / 100
      ? bauerPivot.latest.sector_end / 100 -
          bauerPivot.latest.sector_begin / 100
      : 360
  );

  useEffect(() => {
    setWorkingAngle(
      bauerPivot.latest.sector_end / 100 - bauerPivot.latest.sector_begin / 100
        ? bauerPivot.latest.sector_end / 100 -
            bauerPivot.latest.sector_begin / 100
        : 360
    );
  }, [bauerPivot.latest.sector_begin, bauerPivot.latest.sector_end]);

  useEffect(() => {
    let newValues = {
      segment_1_begin: 0,
      segment_1_end: 0,
      segment_1_fwd: 0,
      segment_1_rev: 0,
      segment_2_begin: 0,
      segment_2_end: 0,
      segment_2_fwd: 0,
      segment_2_rev: 0,
      segment_3_begin: 0,
      segment_3_end: 0,
      segment_3_fwd: 0,
      segment_3_rev: 0,
      segment_4_begin: 0,
      segment_4_end: 0,
      segment_4_fwd: 0,
      segment_4_rev: 0,
      segment_5_begin: 0,
      segment_5_end: 0,
      segment_5_fwd: 0,
      segment_5_rev: 0,
      segment_6_begin: 0,
      segment_6_end: 0,
      segment_6_fwd: 0,
      segment_6_rev: 0,
    };
    arrayAngles.forEach((value, index) => {
      if (index == 0)
        newValues[`segment_${index + 1}_begin`] =
          bauerPivot.latest.sector_begin / 100;
      else
        newValues[`segment_${index + 1}_begin`] = parseInt(
          arrayAngles[index - 1].angle
        );

      newValues[`segment_${index + 1}_end`] = parseInt(value.angle);
      newValues[`segment_${index + 1}_fwd`] = parseInt(value.fwd);
      newValues[`segment_${index + 1}_rev`] = parseInt(value.rev);
    });
    setBauerPivot({
      ...bauerPivot,
      latest: {
        ...bauerPivot.latest,
        ...newValues,
      },
    });
  }, [arrayAngles, bauerPivot.latest.sector_begin]);

  const [showLastArrayAngle, setShowLastArrayAngle] = useState(true);

  const setSectorBegin = useCallback(
    (value: string) => {
      const sectorStart = parseInt(value) * 100;
      if (sectorStart >= bauerPivot.latest.sector_end) {
        setValidationErrors({
          ...validationErrors,
          sectorStart: "EDIT_PIVOT_V5_ERROR_ANGLE_START",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          sectorStart: undefined,
        });
      }
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            sector_begin: 0,
          },
        });
      } else if (parseInt(value) >= 0 && parseInt(value) <= 360) {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            sector_begin: parseInt(value) * 100,
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  const setSectorEnd = useCallback(
    (value: string) => {
      const sectorEnd = parseInt(value) * 100;
      if (sectorEnd <= bauerPivot.latest.sector_begin) {
        setValidationErrors({
          ...validationErrors,
          sectorEnd: "EDIT_PIVOT_V5_ERROR_ANGLE_END",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          sectorEnd: undefined,
        });
      }
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            sector_end: 0,
          },
        });
      } else if (parseInt(value) >= 0 && parseInt(value) <= 360) {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            sector_end: parseInt(value) * 100,
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  const setZeroPosition = useCallback(
    (value: string) => {
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            zero_position: 0,
          },
        });
      } else if (parseInt(value) >= 0 && parseInt(value) <= 360) {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            zero_position: parseInt(value) * 100,
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  const setLastTowerRadius = useCallback(
    (value: string) => {
      if (value <= "0") {
        setValidationErrors({
          ...validationErrors,
          lastTowerRadius: "VALIDATION_FIELD_INVALID",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          lastTowerRadius: undefined,
        });
      }
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            last_tower_radius: 0,
          },
        });
      } else {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            last_tower_radius: parseInt(value),
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  // SEGMENTOS E PLANTIO -------------------------------------------------------------------

  const arrayAnglesHasErrors = useMemo(() => {
    return (
      arrayAngles?.[arrayAngles?.length - 1]?.name == "" ||
      arrayAngles?.[arrayAngles?.length - 1]?.name?.length > 16
    );
  }, [arrayAngles]);

  useEffect(() => {
    if (
      !validateSegments(
        workingAngle,
        generateArrayAngleStart(arrayAngles),
        generateArrayAngleEnd(arrayAngles)
      )
    ) {
      setValidationErrors({
        ...validationErrors,
        ["pivotSegments"]: "EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pivotSegments"]: undefined,
      });
    }
  }, [bauerPivot.latest.sector_end, arrayAngles]);

  const setMaximumRounds = useCallback(
    (value: string) => {
      if (value == "") {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            maximum_rounds: 0,
          },
        });
      } else {
        setBauerPivot({
          ...bauerPivot,
          latest: {
            ...bauerPivot.latest,
            maximum_rounds: parseInt(value),
          },
        });
      }
    },
    [bauerPivot, setBauerPivot]
  );

  // CANHÃO FINAL -------------------------------------------------------------------

  const [endgunArray, setEndgunArray] = useState(() =>
    generateInitialEndgunArray(bauerPivot)
  );
  const [endgun2Array, setEndgun2Array] = useState(() =>
    generateInitialEndgun2Array(bauerPivot)
  );

  useEffect(() => {
    let newValues = {
      end_gun_1_segment_1_begin: 0,
      end_gun_1_segment_1_end: 0,
      end_gun_1_segment_2_begin: 0,
      end_gun_1_segment_2_end: 0,
      end_gun_1_segment_3_begin: 0,
      end_gun_1_segment_3_end: 0,
      end_gun_1_segment_4_begin: 0,
      end_gun_1_segment_4_end: 0,
      end_gun_1_segment_5_begin: 0,
      end_gun_1_segment_5_end: 0,
      end_gun_1_segment_6_begin: 0,
      end_gun_1_segment_6_end: 0,
      end_gun_2_segment_1_begin: 0,
      end_gun_2_segment_1_end: 0,
      end_gun_2_segment_2_begin: 0,
      end_gun_2_segment_2_end: 0,
      end_gun_2_segment_3_begin: 0,
      end_gun_2_segment_3_end: 0,
      end_gun_2_segment_4_begin: 0,
      end_gun_2_segment_4_end: 0,
      end_gun_2_segment_5_begin: 0,
      end_gun_2_segment_5_end: 0,
      end_gun_2_segment_6_begin: 0,
      end_gun_2_segment_6_end: 0,
    };
    endgunArray.forEach((value, index) => {
      newValues[`end_gun_1_segment_${index + 1}_begin`] = parseInt(
        value.start_angle
      );
      newValues[`end_gun_1_segment_${index + 1}_end`] = parseInt(
        value.end_angle
      );
    });
    endgun2Array.forEach((value, index) => {
      newValues[`end_gun_2_segment_${index + 1}_begin`] = parseInt(
        value.start_angle
      );
      newValues[`end_gun_2_segment_${index + 1}_end`] = parseInt(
        value.end_angle
      );
    });
    setBauerPivot({
      ...bauerPivot,
      latest: {
        ...bauerPivot.latest,
        ...newValues,
      },
    });
  }, [endgunArray, endgun2Array]);

  // const [showEndgunDrawing, setShowEndgunDrawing] = useState(false);

  // ------------------------------------------------------------------ FIM DAS ABAS

  // Temos um Alert  que pode mostrar mensagens diferentes de acordo com  a situação
  // Caso não definirmos uma altura para sua div pai de forma a ter espaço em altura
  // tanto para o  texto do alert com menos caracteres quanto para o texto  com mais
  // caracteres, quando o usuário está mexendo o componente de seleção  de segmentos
  // a tela ira pular e atrapalhar a usabilidade (basta recomer esse height e testar
  // e será possível verificar o problema.
  function heightToPreventFlicks() {
    if (window.innerWidth >= 625) {
      return "auto";
    } else if (window.innerWidth < 625 && window.innerWidth >= 393) {
      return "70px";
    } else if (window.innerWidth < 393 && window.innerWidth >= 337) {
      return "90px";
    } else if (window.innerWidth < 337 && window.innerWidth >= 273) {
      return "120px";
    }
  }

  const saveButtonHandler = async () => {
    let dispatchChangeConfig = { ...bauerPivot.latest, command: "SET-CONFIG" };
    delete dispatchChangeConfig.raw_message;
    delete dispatchChangeConfig.created_at;
    delete dispatchChangeConfig.uuid;
    let bauerPivotInfo = {
      center_latitude: centerLat,
      center_longitude: centerLng,
      name: bauerPivot.name,
    };
    dispatch(
      showModal({
        content: (
          <DialogModal
            title={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"}
            yes={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"}
            yesAction={() => {
              try {
                notify("EDIT_PIVOT_SAVE_AWAIT", "info");
                (async () => {
                  const postSendResponse = await coreHTTPClient.post(
                    `v4/smartrain/pivot/${pivotID}/config`,
                    dispatchChangeConfig
                  );
                  const patchBauerPivotInfoServer = await coreHTTPClient.patch(
                    `v4/smartrain/pivot/${pivotID}`,
                    bauerPivotInfo
                  );
                })();
                navigate(
                  Routes.DASHBOARD.SELECTED_BAUER_PIVOT.replace(
                    ":farm",
                    String(farmID)
                  ).replace(":pivot", String(pivotID))
                );
              } catch (err) {
                console.log(err);
              }
            }}
            no={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"}
          />
        ),
      })
    );
  };

  const buttonBack = (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        navigate(
          Routes.DASHBOARD.SELECTED_BAUER_PIVOT.replace(
            ":farm",
            String(farmID)
          ).replace(":pivot", String(pivotID))
        );
      }}
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setDisableSave(true);
          return;
        } else {
          setDisableSave(false);
        }
      }
    }
  }, [validationErrors]);

  const saveButton = (
    <Button
      color="primary"
      endIcon={<CheckCircleIcon />}
      onClick={saveButtonHandler}
      disabled={arrayAnglesHasErrors || disableSave || pivotCenterCoordsError}
    >
      {i18n("EDIT_PIVOT_SAVE_BUTTON")}
    </Button>
  );

  const markerButton = (
    imageUrl: string,
    setStartRefLat,
    setStartRefLng,
    recoilSelector = null
  ) => (
    <button
      className="edit-pivot-form__3d-button"
      onClick={() => {
        dispatch(
          showModal({
            content: (
              <GeolocationGetter
                setLatitude={setStartRefLat}
                setLongitude={setStartRefLng}
                recoilSelector={recoilSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={imageUrl} />
    </button>
  );

  return (
    <div className="general-form__container">
      <DashboardBox
        disableFade={true}
        leftElement={buttonBack}
        centerElement={<h2>{i18n("EDIT_PIVOT_LABEL")}</h2>}
        rightElement={saveButton}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "grid",
            gridTemplateColumns: "12fr",
            height: "100%",
          }}
        >
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            variant={"scrollable"}
            scrollButtons="auto"
            indicatorColor="primary"
            aria-label="Edit Farm"
          >
            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_GENERAL")}</>}
              icon={
                validationErrors["name"] ? (
                  <Settings
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Settings
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              {...selectedTab(0)}
            />

            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_LOCATION")}</>}
              icon={
                pivotCenterCoordsError ||
                validationErrors["sectorStart"] ||
                validationErrors["sectorEnd"] ||
                validationErrors["lastTowerRadius"] ? (
                  <Map
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Map
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              {...selectedTab(1)}
            />

            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_PIVOT_SEGMENTS")}</>}
              icon={
                <>
                  {validationErrors["pivotSegments"] || arrayAnglesHasErrors ? (
                    <PieChart
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <PieChart
                      style={{
                        color: green[500],
                      }}
                    />
                  )}
                </>
              }
              {...selectedTab(2)}
            />

            <Tab
              style={{}}
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_END_GUN")}</>}
              icon={
                <PanoramaFishEye
                  style={{
                    color: green[500],
                  }}
                />
              }
              {...selectedTab(3)}
            />
          </Tabs>

          {/* GERAL ------------------------------------------------------------------- */}

          <div
            style={{
              backgroundColor: "#fff",
              display: "grid",
              gridTemplateColumns: "1fr",
              height: "100%",
            }}
          >
            <div
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <TabPanel value={value} index={0}>
                <StyledH4>{i18n("METER_SYSTEM_EDIT_GENERAL")}</StyledH4>
                <div className="general-form__row-3-3-3-3">
                  <FormInput
                    id={"CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"}
                    label="CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER"
                    helperText={validationErrors["name"]}
                    state={[bauerPivot.name, setValidName]}
                  />
                </div>
                <StyledH4>{i18n("PIVOT_ACTION_FIELD_MODE")}</StyledH4>
                <div style={{ display: "grid" }}>
                  <div className="general-form__row-3-3-3-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={bauerPivot.latest.autoreverse}
                          onChange={setAutoreverse}
                          name="checkedA"
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<RadioButtonChecked />}
                        />
                      }
                      label={<>{i18n("BAUER_PIVOT_EDIT_AUTOREVERSE")}</>}
                    />
                  </div>
                  <div className="general-form__row-3-3-3-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={bauerPivot.latest.parking_on_off}
                          onChange={setParkingOnOff}
                          name="checkedA"
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<RadioButtonChecked />}
                        />
                      }
                      label={<>{i18n("BAUER_PIVOT_EDIT_PARKING_FUNCTION")}</>}
                    />
                    <FormInput
                      id={"parking_position"}
                      label=""
                      stringLabel={"Parking Position"}
                      state={[
                        Math.floor(
                          bauerPivot.latest.parking_position / 100
                        ).toString(),
                        setParkingPosition,
                      ]}
                      endAdornment="º"
                      disabled={!bauerPivot.latest.parking_on_off}
                      inputMode="numeric"
                    />
                  </div>
                  <div className="general-form__row-3-3-3-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={bauerPivot.latest.intermediate_on_off}
                          onChange={setIntermediateOnOff}
                          name="checkedA"
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<RadioButtonChecked />}
                        />
                      }
                      label={
                        <>{i18n("BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION")}</>
                      }
                    />
                    <FormInput
                      id={"intermediate_position"}
                      label=""
                      stringLabel={"Intermediate Position"}
                      state={[
                        Math.floor(
                          bauerPivot.latest.intermediate_position / 100
                        ).toString(),
                        setIntermediatePosition,
                      ]}
                      endAdornment="º"
                      disabled={!bauerPivot.latest.intermediate_on_off}
                      inputMode="numeric"
                    />
                  </div>
                </div>
              </TabPanel>

              {/* LOCALIZAÇÃO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={1}>
                <StyledH4>{i18n("METER_SYSTEM_EDIT_GENERAL")}</StyledH4>
                <div className="general-form__row-3-3-3-3">
                  <FormInput
                    id={"SECTOR_BEGIN"}
                    helperText={validationErrors["sectorStart"]}
                    label="BAUER_PIVOT_EDIT_SECTOR_BEGIN"
                    state={[
                      bauerPivot.latest.sector_begin
                        ? Math.floor(
                            bauerPivot.latest.sector_begin / 100
                          ).toString()
                        : "0",
                      setSectorBegin,
                    ]}
                    endAdornment="º"
                    inputMode="numeric"
                  />
                  <FormInput
                    id={"SECTOR_END"}
                    helperText={validationErrors["sectorEnd"]}
                    label="BAUER_PIVOT_EDIT_SECTOR_END"
                    state={[
                      bauerPivot.latest.sector_end
                        ? Math.floor(
                            bauerPivot.latest.sector_end / 100
                          ).toString()
                        : "360",
                      setSectorEnd,
                    ]}
                    endAdornment="º"
                    inputMode="numeric"
                  />
                  <FormInput
                    id={"Zero_Position"}
                    label="BAUER_PIVOT_EDIT_ZERO_POSITION"
                    state={[
                      Math.floor(
                        bauerPivot.latest.zero_position / 100
                      ).toString(),
                      setZeroPosition,
                    ]}
                    endAdornment="º"
                    inputMode="numeric"
                  />
                  <FormInput
                    id={"Last_Tower_Radius"}
                    label=""
                    helperText={validationErrors["lastTowerRadius"]}
                    stringLabel={i18n("PIVOT_CONFIG_FIELD_RADIUS")}
                    state={[
                      bauerPivot.latest.last_tower_radius
                        ? bauerPivot.latest.last_tower_radius.toString()
                        : "0",
                      setLastTowerRadius,
                    ]}
                    endAdornment="m"
                    inputMode="numeric"
                  />
                </div>
                <div className="general-form__row-4-4-4">
                  <FormInputPosition
                    setError={setPivotCenterCoordsError}
                    id="pivot-config-center"
                    markerUrl={markerButton(
                      greenDot,
                      setCenterLat,
                      setCenterLng,
                      centerMarkerSelector
                    )}
                    label={"PIVOT_CONFIG_V5_FIELD_GP_CENTER"}
                    labelTooltip={"PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP"}
                    lat={[centerLatLabel, setCenterLatLabel]}
                    lng={[centerLngLabel, setCenterLngLabel]}
                    recoilSelector={centerMarkerSelector}
                  />
                </div>

                <div className="edit-pivot-form__map">
                  <DraggableMap
                    zoomScroll={false}
                    _setExperimentalRecoil
                    markers={[
                      {
                        lat: {
                          state: centerLat,
                          setFunction: setCenterLat,
                        },
                        lng: {
                          state: centerLng,
                          setFunction: setCenterLng,
                        },
                        markerUrl: greenDot,
                        key: "center",
                        event: {
                          state: event,
                          setFunction: setEvent,
                        },
                      },
                    ]}
                  />
                </div>
              </TabPanel>

              {/* SEGMENTOS E PLANTIO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={2}>
                <div>
                  <h4>{i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}</h4>

                  <p style={{ marginTop: "-19px" }}>
                    {i18n("EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT")}
                  </p>

                  <div
                    key={`segments-alert`}
                    style={{
                      height: heightToPreventFlicks(),
                      marginBottom: isMobile()
                        ? `calc(${heightToPreventFlicks()} / 2)`
                        : 0,
                    }}
                  >
                    <Alert
                      severity={
                        validationErrors["pivotSegments"] ? "error" : "info"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      {validationErrors["pivotSegments"]
                        ? i18n("EDIT_PIVOT_V5_SEGMENT_WARNING")
                        : i18n("PIVOT_CONFIG_V5_SEGMENT_ERROR", [6])}
                      <div
                        style={{
                          marginBottom:
                            isMobile() && !validationErrors["pivotSegments"]
                              ? "44px"
                              : 0,
                        }}
                      ></div>
                    </Alert>
                  </div>

                  {workingAngle != 360 && (
                    <Alert severity={"error"} style={{ marginBottom: "10px" }}>
                      {i18n("SETORIAL_ENDGUN_ERROR")}
                    </Alert>
                  )}

                  <div
                    className={
                      isMobile() ? null : "edit-pivot-form-v5__container_max"
                    }
                  >
                    <h4>{i18n("ROUNDS_LIMIT_TITLE")}</h4>
                    <p
                      style={{
                        marginTop: isMobile() ? "-19px" : "-30px",
                        marginBottom: isMobile() ? "auto" : "0px",
                      }}
                    >
                      {i18n("ROUNDS_LIMIT_DESCRIPTION")}
                    </p>

                    <div
                      className="general-form__row-6-6"
                      style={{ marginBottom: "10px" }}
                    >
                      <FormInput
                        id={"Maximum_Rounds"}
                        // helperText={validationErrors["voltageMin"]}
                        label="SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS"
                        state={[
                          bauerPivot.latest.maximum_rounds
                            ? bauerPivot.latest.maximum_rounds?.toString()
                            : "0",
                          setMaximumRounds,
                        ]}
                        inputMode="numeric"
                      />
                    </div>

                    <div>
                      <GeneralAngleSelector
                        anglesState={[arrayAngles, setArrayAngles]}
                        maxLength={6}
                        setShowLastAngle={setShowLastArrayAngle}
                        angleDirection={true}
                        hasNames={true}
                        sectorLength={workingAngle}
                        angleStart={bauerPivot.latest.sector_begin / 100}
                        label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END"}
                        centerPosition={[
                          bauerPivot.center_latitude.toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        gpsPosition={[
                          (
                            Math.round(
                              (bauerPivot.center_latitude +
                                (kmInLatAngle *
                                  bauerPivot.latest.last_tower_radius) /
                                  1000) *
                                1e7
                            ) / 1e7
                          ).toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        referenceAngle={bauerPivot.latest.zero_position / 100}
                        referenceRadius={bauerPivot.latest.last_tower_radius}
                      />
                    </div>

                    <MobileZone>
                      <div style={{ marginBottom: "200px" }}></div>
                    </MobileZone>
                  </div>
                </div>
              </TabPanel>

              {/* CANHÃO FINAL 1 ------------------------------------------------------------------- */}

              <TabPanel value={value} index={3}>
                <div>
                  <h4>{i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}</h4>

                  <p style={{ marginTop: "-19px" }}>
                    {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT")}
                  </p>

                  {bauerPivot.latest.end_gun_mode === 2 ? (
                    <Alert severity={"info"} style={{ marginBottom: "10px" }}>
                      {i18n("PIVOT_CONFIG_V5_SEGMENT_ERROR", [6])}
                    </Alert>
                  ) : null}

                  {workingAngle != 360 && (
                    <Alert severity={"error"} style={{ marginBottom: "10px" }}>
                      {i18n("SETORIAL_ENDGUN_ERROR")}
                    </Alert>
                  )}

                  <div
                    className="general-form__row-12"
                    style={{ marginBottom: "10px" }}
                  >
                    <FormSelect
                      id={"ENDGUN_MODE"}
                      label={"BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE"}
                      state={[
                        bauerPivot.latest.end_gun_mode,
                        (value: string) => {
                          setBauerPivot({
                            ...bauerPivot,
                            latest: {
                              ...bauerPivot.latest,
                              end_gun_mode: parseInt(value),
                            },
                          });
                        },
                      ]}
                      values={[
                        ["0", "PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT"],
                        ["1", "BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON"],
                        ["2", "BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE"],
                      ]}
                    />
                  </div>

                  {bauerPivot.latest.end_gun_mode == 2 && (
                    <h4>{i18n("EDIT_PIVOT_LABEL_END_GUN") + " 1"}</h4>
                  )}

                  {bauerPivot.latest.end_gun_mode == 2 && (
                    <div className="edit-pivot-form-v5__container">
                      <EndgunAngleSelector
                        key={`EndgunAngleComponent_${isMobile()}`}
                        maxLength={6}
                        strokeSize={0.9}
                        startOnReference={true}
                        angleDirection={true}
                        anglesState={[endgunArray, setEndgunArray]}
                        setShowLastAngle={setShowLastArrayAngle}
                        sectorLength={workingAngle}
                        angleStart={bauerPivot.latest.sector_begin / 100}
                        label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END"}
                        centerPosition={[
                          bauerPivot.center_latitude.toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        gpsPosition={[
                          (
                            Math.round(
                              (bauerPivot.center_latitude +
                                (kmInLatAngle *
                                  bauerPivot.latest.last_tower_radius) /
                                  1000) *
                                1e7
                            ) / 1e7
                          ).toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        referenceAngle={bauerPivot.latest.zero_position / 100}
                        referenceRadius={bauerPivot.latest.last_tower_radius}
                      />

                      <div>
                        {endgunArray.length ? (
                          <div className="edit-pivot-form-v5__table">
                            <div
                              style={
                                isMobile()
                                  ? {}
                                  : {
                                      marginTop: "-10px",
                                      maxHeight: "500px",
                                      overflowY: "auto",
                                    }
                              }
                            >
                              {endgunArray.map((elem, index) => (
                                <>
                                  {index === 0 && (
                                    <StyledH4 style={{ marginBottom: 10 }}>
                                      {i18n("SELECTED_PIVOT_VRI_IRRIGATION")}
                                    </StyledH4>
                                  )}

                                  <div
                                    className={
                                      index === endgunArray.length - 1
                                        ? "table-pivot-segmentsLast"
                                        : "table-pivot-segments"
                                    }
                                    style={
                                      !showLastArrayAngle &&
                                      index === endgunArray.length - 1
                                        ? { opacity: 0 }
                                        : {}
                                    }
                                  >
                                    <div
                                      className="table-pivot-segments__index"
                                      style={{
                                        backgroundColor: BLUE_COLORS_TABLE[
                                          index
                                        ].replace("99", ""),
                                        color: "white",
                                      }}
                                    >
                                      {index + 1}
                                    </div>

                                    <div className="table-pivot-segments__info">
                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START",
                                            [endgunArray[index].start_angle]
                                          )}
                                        </div>
                                      </div>

                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END",
                                            [endgunArray[index]?.end_angle]
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  )}

                  {bauerPivot.latest.end_gun_mode == 2 && (
                    <h4>{i18n("EDIT_PIVOT_LABEL_END_GUN") + " 2"}</h4>
                  )}

                  {bauerPivot.latest.end_gun_mode == 2 && (
                    <div className="edit-pivot-form-v5__container">
                      <EndgunAngleSelector
                        key={`EndgunAngleComponent_${isMobile()}`}
                        maxLength={6}
                        strokeSize={0.9}
                        startOnReference={true}
                        angleDirection={true}
                        anglesState={[endgun2Array, setEndgun2Array]}
                        setShowLastAngle={setShowLastArrayAngle}
                        sectorLength={workingAngle}
                        angleStart={bauerPivot.latest.sector_begin / 100}
                        label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END"}
                        centerPosition={[
                          bauerPivot.center_latitude.toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        gpsPosition={[
                          (
                            Math.round(
                              (bauerPivot.center_latitude +
                                (kmInLatAngle *
                                  bauerPivot.latest.last_tower_radius) /
                                  1000) *
                                1e7
                            ) / 1e7
                          ).toString(),
                          bauerPivot.center_longitude.toString(),
                        ]}
                        referenceAngle={bauerPivot.latest.zero_position / 100}
                        referenceRadius={bauerPivot.latest.last_tower_radius}
                      />

                      <div>
                        {endgun2Array.length ? (
                          <div className="edit-pivot-form-v5__table">
                            <div
                              style={
                                isMobile()
                                  ? {}
                                  : {
                                      marginTop: "-10px",
                                      maxHeight: "500px",
                                      overflowY: "auto",
                                    }
                              }
                            >
                              {endgun2Array.map((elem, index) => (
                                <>
                                  {index === 0 && (
                                    <StyledH4 style={{ marginBottom: 10 }}>
                                      {i18n("SELECTED_PIVOT_VRI_IRRIGATION")}
                                    </StyledH4>
                                  )}

                                  <div
                                    className={
                                      index === endgun2Array.length - 1
                                        ? "table-pivot-segmentsLast"
                                        : "table-pivot-segments"
                                    }
                                    style={
                                      !showLastArrayAngle &&
                                      index === endgun2Array.length - 1
                                        ? { opacity: 0 }
                                        : {}
                                    }
                                  >
                                    <div
                                      className="table-pivot-segments__index"
                                      style={{
                                        backgroundColor: BLUE_COLORS_TABLE[
                                          index
                                        ].replace("99", ""),
                                        color: "white",
                                      }}
                                    >
                                      {index + 1}
                                    </div>

                                    <div className="table-pivot-segments__info">
                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START",
                                            [endgun2Array[index].start_angle]
                                          )}
                                        </div>
                                      </div>

                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END",
                                            [endgun2Array[index]?.end_angle]
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  )}

                  <MobileZone>
                    {bauerPivot.latest.end_gun_mode === 2 && (
                      <div style={{ marginBottom: "200px" }}></div>
                    )}
                  </MobileZone>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </DashboardBox>
    </div>
  );
}

export default NewEditPivotV5;
