import { Irpd } from "../../../../../../../../redux/irpds/types";
import { ReactJSX, dateHumanized } from "../../../../../../../../utils/types";
import { getIrpdStreamStatus } from "../../../../../../../../utils/models/irpds";
import React from "react";
import moment from "moment";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { blue } from "@material-ui/core/colors";
import {
  messageErrorsV5,
  messageStatusV5,
} from "../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

export const handleWaterBladeByAngle = (
  data: any[],
  referenceAngle: number
) => {
  var dataArray: any[] = [];
  if (referenceAngle) var refAngle = Math.floor(referenceAngle);
  data.forEach((row, index) => {
    if (referenceAngle) {
      var newIndex = (359 - refAngle + index) % 359;
      //newIndex = Math.abs(359-newIndex)

      dataArray.push({
        Ângulo: `${newIndex}°`,
        "mm: ": data[newIndex],
      });
    } else
      dataArray.push({
        Ângulo: `${index}°`,
        "mm: ": row,
      });
  });

  //
  return dataArray;
};

const doneIcon = (
  <DoneIcon
    style={{
      margin: "0 5px 0 5px",
      fontSize: "1.4rem",
      color: blue[500],
    }}
  />
);

const doneAllIcon = (
  <DoneAllIcon
    style={{
      margin: "0 5px 0 5px",
      fontSize: "1.4rem",
      color: blue[500],
    }}
  />
);

export const messageStatus = (
  send: boolean,
  messageStatus: number
): ReactJSX => {
  if (send && messageStatus === 0) return doneIcon;

  if (send && messageStatus === 1) return doneAllIcon;
};

export const checkStatus = (mode: number) => {
  if (mode === 1) {
    return <>{i18n("HISTORIC_IRPD_ACTION_START")}</>;
  } else {
    return <>{i18n("HISTORIC_IRPD_ACTION_STOP")}</>;
  }
};

export const expandDateActionnArgs = (date: string, add_hours: number = 0) => {
  return [moment(date).add(add_hours, "hours").format("DD MMM - HH:mm")];
};

export const getResume = (model: string, data: any, irpd: Irpd) => {
  let type;
  let overview: string | ReactJSX = "";
  switch (model) {
    case "irpd_stream":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_PUMP_UPDATE")}</>;
      overview = <>{getIrpdStreamStatus(data.reason).text}</>;
      break;

    case "irpd_action":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")}</>;
      overview = (
        <>
          {checkStatus(data.mode)}
          {messageStatus(data.sent, data.delivered)}
        </>
      );
    case "irpd_action_v5":
      if (data.message_subtype === "simple") {
        type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")}</>;
        overview = (
          <>
            {data?.content?.pump_action?.enable === 1
              ? i18n("SELECTED_IRPD_START")
              : i18n("SELECTED_IRPD_STOP")}
            {messageStatusV5(data)}
            {messageErrorsV5(data)}
          </>
        );
      } else if (data.message_subtype === "schedule") {
        type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")}</>;
        overview = (
          <>
            {i18n("SELECTED_PIVOT_SCHEDULE_IRRIGATION")}
            {messageStatusV5(data)}
            {messageErrorsV5(data)}
          </>
        );
      }
      break;
    case "irpd_stream_v5":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_PUMP_UPDATE")}</>;
      const offStatus = [246, 249, 250, 251, 252, 253, 254, 255];
      overview =
        irpd.protocol === 5.2 ? (
          <>
            {["event", "periodic"].includes(data?.message_subtype) &&
            offStatus.includes(data?.content?.imanage_master_status?.status)
              ? //? i18n("PERIODIC_WARNING") // Fixed message
                getIrpdStreamStatus(
                  data?.content?.imanage_master_status?.status
                ).text
              : data?.message_subtype === "event"
              ? getIrpdStreamStatus(
                  data?.content?.imanage_master_status?.status
                ).text
              : i18n("IRPD_STATUS_PUMPING")}
          </>
        ) : (
          <>
            {data?.message_subtype === "event"
              ? getIrpdStreamStatus(
                  data?.content?.imanage_master_status?.status
                ).text
              : i18n("IRPD_STATUS_PUMPING")}
          </>
        );
      break;
    case "CentralStream":
      type = <>{i18n("CENTRAL_UPDATE")}</>;
      overview = (
        <>
          {i18n(
            data?.status == 1
              ? "SELECTED_FARM_CENTRAL_ONLINE"
              : "SELECTED_FARM_CENTRAL_OFFLINE"
          )}
        </>
      );
      break;
    default:
      break;
  }

  return { type, overview };
};

export const expandDateArgs = (ISODate: string) => {
  return [dateHumanized(ISODate), moment(ISODate).format("DD MMM - HH:mm")];
};

export const irpdActionMode = (mode: number): string => {
  return mode === 0
    ? i18n("IRPD_ACTION_VALUE_MODE_WET")
    : i18n("IRPD_ACTION_VALUE_MODE_DRY");
};
