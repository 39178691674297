export enum IrpdsTypes {
  SET_IRPDS = "@@irpds/SET_IRPDS",
  UPDATE_OR_INSERT_IRPD = "@@irpds/UPDATE_OR_INSERT_IRPD",
  SET_CONVENTIONAL_CONFIG = "@@irpds/SET_CONVENTIONAL_CONFIG",
}

export const EmptyIrpd = (): Irpd => {
  return {
    id: -1,
    latest_irpd_stream: {
      uuid: -1,
      arrived: "string",
      created: "string",
      device: -1,
      payload: "string",
      content: {},
      irpd: -1,
      reason: -1,
      hourmeter: -1,
      frame_id: -1,
    },
    latest_irpd_stream_v5_periodic: null,
    latest_irpd_stream_v5_event: null,
    latest_irpd_config_v5: null,
    latest_irpd_config: {
      user: -1,
      irpd: -1,
      uuid: -1,
      arrived: "string",
      created: "string",
      hour_range_max: "string",
      hour_range_min: "string",
      rtc: "string",
      energy_time: -1,
      sent: false,
      delivered: false,
      manual: false,
    },
    farm: 0,
    name: "string",
    flow: 0,
    base: 0,
    pump: 0,
    potency: 0,
    position: "0, 0",
    protocol: 4,
    permission_level: 0,
  };
};

export interface Irpd {
  id: number;
  image?: string | null;
  latest_irpd_stream?: IrpdStream;
  latest_irpd_config?: IrpdConfig;
  latest_irpd_stream_v5_periodic: any;
  latest_irpd_stream_v5_event?: any;
  latest_irpd_config_v5: any;
  latest_irpd_pressure_stream?: IrpdPressureStream;
  farm: number;
  name: string;
  flow: number;
  base: number;
  pump: number;
  base_radio_id?: number | string;
  pump_radio_id?: number | string;
  potency: number;
  position: string;
  protocol: number;
  permission_level: number;
  regenerate_reports?: boolean;
  depth_well?: number;
}

export interface IrpdConfig {
  user: number;
  irpd: number;
  uuid: number;
  arrived: string;
  created: string;
  monthly_water_limit?: number;
  hour_range_max: string;
  hour_range_min: string;
  rtc: string;
  energy_time: number;
  sent: boolean;
  delivered: boolean;
  manual: boolean;
}

export interface IrpdAction {
  uuid: number;
  user: number;
  irpd: number;
  mode: number;
  start: any;
  end: any;
  created: string;
  type: number;
  message_subtype?: string;
  content: any;
  username: string;
  humanized: string;
  sent: boolean;
  delivered: number;
}

export interface IrpdStream {
  uuid: number;
  arrived: string;
  created: string;
  device: number;
  payload: string;
  message_subtype?: string;
  content: any;
  irpd: number;
  reason: number;
  hourmeter: number;
  frame_id: number;
}

export interface IrpdPressureStream {
  id: number;
  irpd: number;
  uuid: string;
  arrived: string;
  created: string;
  payload: string;
  pressure: number;
  frame_id: number;
  device: string;
}

export interface IrpdsState {
  list: Irpd[];
}
