import { HistoricResultItem } from "../../../../../../../../../hooks/models/useIrpdHistoric";
import { IrpdContext } from "../../../../../SelectedIrpd";
import { useContext } from "react";
import ExpandedIrpdStream from "./components/ExpandedIrpdStream/ExpandedIrpdStream";
import React from "react";
import ExpandedIrpdAction from "./components/ExpandedIrpdAction/ExpandedIrpdAction";
import {
  IrpdStream,
  IrpdAction,
} from "../../../../../../../../../redux/irpds/types";
import ExpandedIrpdActionSimpleV5 from "./components/ExpandedIrpdActionSimpleV5/ExpandedIrpdActionSimpleV5";
import { IrrigationV5 } from "../../../../../../../../../redux/pivots/types";
import ExpandedIrpdActionScheduleV5 from "./components/ExpandedIrpdActionScheduleV5.tsx/ExpandedIrpdActionScheduleV5";
import ExpandedIrpdStreamV5, {
  ExpandedIrpdStreamV5Gh,
} from "./components/ExpandedIrpdStreamV5/ExpandedIrpdStreamV5";

function ExpandedContent(props: HistoricResultItem) {
  const { irpd } = useContext(IrpdContext);

  const { model } = props;

  switch (model) {
    case "irpd_stream":
      return <ExpandedIrpdStream irpdStream={props.data as IrpdStream} />;

    case "irpd_stream_v5":
      const offStatus = [246, 249, 250, 251, 252, 253, 254, 255];
      if (
        irpd.protocol === 5.2 &&
        ["event", "periodic"].includes(props?.data?.message_subtype) &&
        offStatus.includes(props?.data?.content?.imanage_master_status?.status)
      )
        return (
          <ExpandedIrpdStreamV5Gh irpdStream={props.data as IrrigationV5} />
        );
      return <ExpandedIrpdStreamV5 irpdStream={props.data as IrrigationV5} />;

    case "irpd_action":
      return <ExpandedIrpdAction irpdAction={props.data as IrpdAction} />;

    case "irpd_action_v5":
      if ((props.data as IrrigationV5).message_subtype === "simple")
        return (
          <ExpandedIrpdActionSimpleV5 irpdAction={props.data} irpd={irpd} />
        );
      else if ((props.data as IrrigationV5).message_subtype === "schedule")
        return (
          <ExpandedIrpdActionScheduleV5 irpdAction={props.data} irpd={irpd} />
        );

    case "CentralStream":
      return <></>;
    default:
      return null;
  }
}

export default ExpandedContent;
