import React from "react";
import { DeviceNode } from "./DeviceTypes";
import PivotItem from "../../pages/Dashboard/Farm/SelectedFarm/components/DeviceBox/components/PivotItem/PivotItem";
import { SvgIcon, Collapse, makeStyles } from "@material-ui/core";
import { GpsFixed } from "@material-ui/icons";
import ButtonWithProgress from "../../pages/Dashboard/Devices/components/DeviceBox/components/ButtonWithProgress/ButtonWithProgress";
import { SignalQualityMap } from "../../pages/Dashboard/Devices/components/DeviceBox/DeviceBox";
import {
  DeviceNodeFound,
  EnabledDeviceMap,
} from "../../pages/Dashboard/Devices/Devices";
import {
  getPanelStreamStatusV5,
  PainelStreamStatus,
  getPanelStreamStatus,
} from "../models/pivots";
import IrpdItem from "../../pages/Dashboard/Farm/SelectedFarm/components/DeviceBox/components/IrpdItem/IrpdItem";
import CentralItem from "../../pages/Dashboard/Devices/components/DeviceBox/components/CentralItem/CentralItem";
import RepeaterItem from "../../pages/Dashboard/Devices/components/DeviceBox/components/RepeaterItem/RepeaterItem";
import CentralIcon from "./icons/CentralIcon";
import RepeaterIcon from "./icons/RepeaterIcon";
import ControllerIcon from "./icons/ControllerIcon";
import PumpIcon from "./icons/PumpIcon";
import { i18n, i18nTextId } from "../../i18n/i18nText";

interface Props {
  deviceNode: DeviceNode;
  devices: DeviceNode[];
  foundNodes: DeviceNodeFound[];
  enabledDevices: EnabledDeviceMap;
  doDeviceSearch: (radio_id: string) => any;
  onDeviceClick: () => any;
  isExpanded: boolean;
}

export const getDeviceIcon = (type, color, size, extraStyle = null) => {
  switch (type) {
    case "CENTRAL":
      return <CentralIcon color={color} size={size} extraStyle={extraStyle} />;
    case "REPEATER":
      return <RepeaterIcon color={color} size={size} extraStyle={extraStyle} />;
    case "PUMP":
      return <PumpIcon color={color} size={size} extraStyle={extraStyle} />;
    case "PIVOT":
      return (
        <ControllerIcon color={color} size={size} extraStyle={extraStyle} />
      );
    default:
      return null;
  }
};

export const useStyles = makeStyles({
  pivotDeviceButton: {
    width: "100%",
    backgroundColor: "#0066FF",
  },
  marginLeft: {
    marginLeft: "10px",
  },
});

function DeviceListComponent(
  props: Props,
  ref: React.RefObject<HTMLDivElement>
) {
  let { deviceNode, foundNodes, isExpanded, enabledDevices } = props;
  const classes = useStyles({});
  let internalComponent: JSX.Element;
  let enabledColor = "#0066FF";

  if (deviceNode.type == "PIVOT") {
    internalComponent = (
      <PivotItem
        pivot={deviceNode.object}
        shouldNotShowMapPreview
        shouldHideLatestStatus
        shouldHideInfoFromRecoil
      />
    );

    let { color }: PainelStreamStatus =
      deviceNode.object.protocol === 5
        ? getPanelStreamStatusV5(
            deviceNode.object.controllerstream_panel?.content.irrigation_status
              .irrigation_status
          )
        : getPanelStreamStatus(deviceNode.object.latest_panel_stream?.reason);

    enabledColor = color;
  } else if (deviceNode.type == "PUMP") {
    internalComponent = (
      <IrpdItem
        irpd={deviceNode.object}
        shouldNotShowMapPreview
        shouldHideLatestStatus
      />
    );
  } else if (deviceNode.type == "CENTRAL") {
    internalComponent = <CentralItem farm={deviceNode.object} />;
  } else if (deviceNode.type == "REPEATER") {
    internalComponent = (
      <RepeaterItem
        repeaterName={deviceNode.name}
        height={deviceNode.object.height}
      />
    );
  }

  if (!internalComponent) return null;

  return (
    <div key={deviceNode.radio_id} className="pivot-item_device-div">
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onDeviceClick();
        }}
        className="__uncollapsable"
      >
        <div style={{ pointerEvents: "none" }}>{internalComponent}</div>
        <div className="__overlay-items">
          <SvgIcon
            style={{
              fontSize: 48,
              transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <path d="M 18 9 L 12 13 L 6 9 L 5 10 l 7 5 l 7 -5 z"></path>
          </SvgIcon>
        </div>

        <div className="icons">
          {deviceNode.type == "PIVOT" && deviceNode.object.automation_type == 0
            ? getDeviceIcon(
                deviceNode.type,
                props.enabledDevices[deviceNode.radio_id]
                  ? enabledColor
                  : "#A5A5A5",
                24,
                deviceNode.type == "PIVOT" ? { marginRight: 5 } : null
              )
            : null}

          {/**
           * PIVOT ONLY, GPS E PIVOT NO MESMO COMPONENTE
           */}
          {deviceNode.type == "PIVOT" && (
            <GpsFixed
              fontSize={"small"}
              htmlColor={
                props.enabledDevices[deviceNode.object.monitor_radio_id]
                  ? enabledColor
                  : "#A5A5A5"
              }
            />
          )}
        </div>
      </div>
      <Collapse in={isExpanded}>
        <div className="pivot-item_device-div__analyze_text">
          {i18n("DEVICE_BOX_ANALYZE")}
        </div>

        <div className="pivot-item_device-div__bottom-div">
          {props.enabledDevices[deviceNode.radio_id] && (
            <ButtonWithProgress
              className={classes.pivotDeviceButton}
              variant="contained"
              color="secondary"
              onClick={() => {
                props.doDeviceSearch(deviceNode.radio_id);
              }}
            >
              {i18n("DEVICE_BOX_CONTROL")}
            </ButtonWithProgress>
          )}

          {/**
           * PIVOT ONLY, GPS E PIVOT NO MESMO COMPONENTE
           */}
          {deviceNode.type == "PIVOT" &&
            props.enabledDevices[deviceNode.object.monitor_radio_id] && (
              // {true && (
              <ButtonWithProgress
                className={[classes.pivotDeviceButton, classes.marginLeft].join(
                  " "
                )}
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (deviceNode.type == "PIVOT")
                    props.doDeviceSearch(deviceNode.object.monitor_radio_id);
                }}
              >
                {i18n("DEVICE_BOX_GPS")}
              </ButtonWithProgress>
            )}
        </div>

        <div className="pivot-item_device-div__pivot_info_div">
          <div className="pivot-item_device-div__pivot_info_div__sub">
            {props.devices.map((dev, i) => {
              if (i >= props.devices.length / 2) return;

              let node = props.foundNodes.find((n) => {
                if (!n.toObject || !n.fromObject) return false;

                return n.to == dev.radio_id && n.from == deviceNode.radio_id;
              });

              return (
                <span
                  key={dev.object.id + "1"}
                  className="pivot-item_device-div__pivot_info_div__info"
                >
                  {dev.name}:{" "}
                  {node
                    ? i18n(SignalQualityMap[node.quality] as i18nTextId)
                    : "..."}
                </span>
              );
            })}
          </div>

          <div className="pivot-item_device-div__pivot_info_div__sub">
            {props.devices.map((dev, i) => {
              if (i < props.devices.length / 2) return;

              let node = props.foundNodes.find((n) => {
                if (!n.toObject || !n.fromObject) return false;

                return n.to == dev.radio_id && n.from == deviceNode.radio_id;
              });

              return (
                <span
                  key={dev.object.id + "2"}
                  className="pivot-item_device-div__pivot_info_div__info"
                >
                  {dev.name}:{" "}
                  {node
                    ? i18n(SignalQualityMap[node.quality] as i18nTextId)
                    : "..."}
                </span>
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default React.forwardRef(DeviceListComponent);
