import React from "react";
import { i18n } from "../../../../../../../../../../../i18n/i18nText";
import { IrrigationV5 } from "../../../../../../../../../../../redux/pivots/types";
import { expandDateArgs } from "../../../utils";
import { formatFloatValue } from "../../../../../../../../../../../utils/models/format";

interface Props {
  irpdStream: IrrigationV5;
}

function ExpandedIrpdStreamV5(props: Props) {
  const { irpdStream } = props;

  const hours = irpdStream?.content?.pump_hourmeter?.hours;
  const minutes = irpdStream?.content?.pump_hourmeter?.minutes;
  const estimatedConsumption = irpdStream?.total_flow;
  const pressure =
    irpdStream?.content?.imanage_sensor_measure_value?.[0]?.value / 10;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_IRPD_ACTION_CREATED", [
            ...expandDateArgs(irpdStream.created),
          ])}
        </span>

        {irpdStream?.message_subtype === "event" ? (
          <span>
            {i18n("HISTORIC_IRPD_STREAM_HOURMETER")}
            <b>{i18n("VALUE", [`${hours}h ${minutes}min`])}</b>
          </span>
        ) : null}

        {irpdStream?.message_subtype === "periodic" ? (
          <>
            <span>
              {i18n("HISTORIC_IRPD_STREAM_CONSUMPTION")}:
              <b> {formatFloatValue(estimatedConsumption)} m³</b>
            </span>

            <span>
              {i18n("HISTORIC_IRPD_STREAM_PRESSURE")}:
              <b>
                {pressure > 0
                  ? ` ${formatFloatValue(pressure, 1)} bar`
                  : ` - bar`}
              </b>
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
}

function ExpandedIrpdStreamV5Gh(props: Props) {
  const { irpdStream } = props;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_IRPD_ACTION_CREATED", [
            ...expandDateArgs(irpdStream.created),
          ])}
        </span>
      </div>
    </div>
  );
}

export default ExpandedIrpdStreamV5;

export { ExpandedIrpdStreamV5Gh };
