import React, { useCallback, useEffect, useState } from "react";
import "./IrpdGeneralBox.scss";
import {
  Battery20,
  BatteryFull,
  PowerSettingsNew,
  InvertColors,
  Schedule,
  ErrorOutline,
} from "@material-ui/icons";
import WifiIcon from "@material-ui/icons/Wifi";
import { Button, Fade } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../../../../../redux/modal/actions";
import { useParams } from "react-router";
import useIrpdStatus from "../../../../../../hooks/tools/useIrpdStatus";
import { useStyles } from "../IrpdBox/IrpdBox.style";
import IrpdActionModal from "../IrpdActionModal/IrpdActionModal";
import MobileZone from "../../../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../../../components/Zones/DesktopZone";
import IrpdMap from "../IrpdMap/IrpdMap";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import useCentralIsonline from "../../../../../../hooks/tools/useCentralIsonline";
import moment from "moment";
import { isMobile } from "../../../../../../mobileConfig";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { Alert } from "@material-ui/lab";
import { useRecoilValue } from "recoil";
import { IrpdFamily } from "../../../../../../recoils/IrpdRecoil";
import { CenterIcon } from "../../../../Farm/SelectedFarm/components/DeviceBox/components/IrpdItem/IrpdItem";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { coreHTTPClient } from "../../../../../../services/webclient";
import {
  getIrpdStreamStatus,
  getLatestIrpdStreamV5,
} from "../../../../../../utils/models/irpds";
import { i18n } from "../../../../../../i18n/i18nText";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import StatusHelper from "../../../../Farm/SelectedFarm/components/DeviceBox/components/PivotItem/StatusHelperPopover";
import QuickSelect from "../../../../../../components/QuickSelect/QuickSelect";
import { formatFloatValue } from "../../../../../../utils/models/format";
import useNotifyIfDebtor from "../../../../../../hooks/models/useNotifyIfDebtor";
import useDebtor from "../../../../../../hooks/models/useDebtor";

const { SOCKET_SUFFIX } = process.env;

const StyledAlert = styled(Alert)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const ConfirmationContainer = styled.div`
  height: auto;
  position: relative;
  max-width: 300px;
  padding-bottom: 25px;

  @media (max-width: 1024px) {
    height: calc(100vh - 60px);
    max-width: 100%;
    padding: 20px 20px;
  }
`;

const ButtonsContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 0;
    max-width: 100%;
  }
`;

const alertVariants = {
  loop: {
    filter: "saturate(400%) ",
    transition: {
      yoyo: Infinity,
      duration: 0.5,
    },
  },
};

// TODO: Isolate this type
export interface PivotStatusAPIResponse {
  irrigation_status: number;
  power: number;
  voltage: number;
  angle: number;
}

function WaterLimitConfirmationModal({ modalContent, modalTitle }) {
  const dispatch = useDispatch();

  return (
    <ConfirmationContainer id="ConfirmationContainer">
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        {i18n("ATENTION")}
      </div>
      <br />
      <div style={{ textAlign: "center", marginBottom: "35px" }}>
        {i18n("MONTHLY_WATER_ACTION_CONFIRMATION")}
      </div>
      <ButtonsContainer id="ButtonsContainer">
        <Button
          onClick={() => {
            dispatch(hideModal());
          }}
          variant="contained"
          style={{
            backgroundColor: "red",
            color: "white",
            marginRight: "10px",
          }}
        >
          {i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}
        </Button>
        <Button
          onClick={() => {
            dispatch(hideModal());
            dispatch(
              showModal({
                content: modalContent,
                title: modalTitle,
              })
            );
          }}
          variant="contained"
          color="primary"
        >
          {i18n("CONTINUE")}
        </Button>
      </ButtonsContainer>
    </ConfirmationContainer>
  );
}

function IrpdGeneralBox(props: any) {
  const dispatch = useDispatch();
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const [, notifyIfDebtor] = useNotifyIfDebtor(farmID);
  const [, debtor, action] = useDebtor(farmID);

  useEffect(() => {
    action.get(farmID, false);
  }, []);

  const [isConnected, socket] = useSocketIO();
  const irpd = useRecoilValue(IrpdFamily(irpdID));

  const classes = useStyles(props);

  let [
    latestStatus,
    latestUpdate,
    latestIrpdStream,
    setLatestIrpdStream,
  ] = useIrpdStatus(irpd);

  const [loading, setLoading] = useState(true);
  let [isOnline, , isOnlineLoading] = useCentralIsonline(
    farmID,
    "GENERAL_BOX_IRPD_IS_ONLINE_FAILURE"
  );

  const [waterLimitExceeded, setWaterLimitExceeded] = useState(false);

  const [alertOpen, setAlertOpen] = useState(true);

  const [latestIrpdStreamV5, setLatestIrpdStreamV5] = useState(
    irpd.latest_irpd_stream_v5_event
      ? irpd.latest_irpd_stream_v5_event
      : irpd?.latest_irpd_stream_v5_periodic
  );

  useEffect(() => {
    if (irpd) {
      setLatestIrpdStreamV5(getLatestIrpdStreamV5(irpd));
    }
  }, [irpd]);

  const [pressureV5, setPressureV5] = useState<any>(
    irpd.latest_irpd_stream_v5_periodic?.content
      ?.imanage_sensor_measure_value?.[0]?.value / 10
  );

  useEffect(() => {
    new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.get(
          `/v3/farms/${farmID}/irpds/${irpdID}/water-consumption-status/`
        );
        setWaterLimitExceeded(response.data?.limit_exceeded);
      } catch (err) {
        // notify("GENERAL_BOX_IRPD_IS_ONLINE_FAILURE", "error");
      }
    });
  }, [irpdID]);

  useEffect(() => {
    if (
      (latestUpdate !== undefined && latestStatus !== undefined) ||
      irpd?.latest_irpd_stream_v5_periodic ||
      irpd?.latest_irpd_config_v5
    ) {
      setLoading(false);
    }
  }, [latestStatus, latestUpdate, irpd, irpdID]);

  const stopIrpdHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <IrpdActionModal
            farmID={farmID}
            irpdID={irpd.id}
            mode="0"
            protocol={irpd.protocol}
          />
        ),
      })
    );
  };

  const startIrpdHandler = useCallback(() => {
    notifyIfDebtor(farmID);

    const actionModal = (
      <IrpdActionModal
        farmID={farmID}
        irpdID={irpd.id}
        mode="1"
        protocol={irpd.protocol}
      />
    );

    const modalTitle = irpd.protocol < 5 ? "SELECTED_IRPD_START" : null;

    dispatch(
      showModal({
        content: (
          <>
            {waterLimitExceeded ? (
              <WaterLimitConfirmationModal
                modalContent={actionModal}
                modalTitle={irpd.protocol < 5 ? "SELECTED_IRPD_START" : null}
              />
            ) : (
              actionModal
            )}
          </>
        ),
        title: modalTitle,
      })
    );
  }, [waterLimitExceeded, irpd]);

  const scheduleIrpdHandler = useCallback(() => {
    notifyIfDebtor(farmID);

    const actionModal = (
      <IrpdActionModal
        farmID={farmID}
        irpdID={irpd.id}
        mode="2" //mode = 2 será para mostrar o modal de agendamento no irpd v5
        protocol={irpd.protocol}
      />
    );

    const modalTitle = irpd.protocol < 5 ? "SELECTED_IRPD_START" : null;

    dispatch(
      showModal({
        content: (
          <>
            {waterLimitExceeded ? (
              <WaterLimitConfirmationModal
                modalContent={actionModal}
                modalTitle={modalTitle}
              />
            ) : (
              actionModal
            )}
          </>
        ),
        title: modalTitle,
      })
    );
  }, [waterLimitExceeded, irpd]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);

      socket.bind("IrpdStreamV5_event", (data) => {
        setLatestIrpdStreamV5(data);
        setLatestIrpdStream({ ...latestIrpdStream, created: data.created });
      });

      socket.bind("IrpdStreamV5_periodic", (data) => {
        setLatestIrpdStreamV5((state) => {
          return { ...state, created: data.created };
        });
        setPressureV5(
          data.content?.imanage_sensor_measure_value[0]?.value / 10
        );
        // setLatestIrpdStream({ ...latestIrpdStream, created: data.created });
      });

      return () => {
        socket.unbind("IrpdStreamV5_event");
        socket.unbind("IrpdStreamV5_periodic");
        socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);
      };
    }
  }, []);

  /*
    Irrigation Action Buttons V5
  */
  const irpdButtonsV5 = (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={scheduleIrpdHandler}
        startIcon={<Schedule />}
        disabled={!isOnline}
      >
        {i18n("SELECTED_PIVOT_SCHEDULE_IRRIGATION")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={startIrpdHandler}
        startIcon={<InvertColors />}
        disabled={!isOnline}
      >
        <>{i18n("SELECTED_IRPD_START")}</>
      </Button>
      <Button
        style={isMobile() ? { gridColumn: "1/3" } : null}
        variant="contained"
        className={classes.stopPivotButton}
        onClick={stopIrpdHandler}
        startIcon={<PowerSettingsNew />}
        disabled={!isOnline}
      >
        <>{i18n("SELECTED_IRPD_STOP")}</>
      </Button>
    </>
  );

  /*
    Irrigation Action Buttons Old
  */
  const irpdButtonsOld = (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={startIrpdHandler}
        startIcon={<InvertColors />}
        disabled={!isOnline}
      >
        <>{i18n("SELECTED_IRPD_START")}</>
      </Button>
      <Button
        variant="contained"
        className={classes.stopPivotButton}
        onClick={stopIrpdHandler}
        startIcon={<PowerSettingsNew />}
        disabled={!isOnline}
      >
        <>{i18n("SELECTED_IRPD_STOP")}</>
      </Button>
    </>
  );

  //  latest_irpd_stream_v5_periodic.content.battery_level.battery_level

  // Irpd live informations
  const generalBoxContentOld = (
    <div className="generalbox">
      {alertOpen && waterLimitExceeded && (
        <motion.div
          variants={alertVariants}
          initial={{ filter: "saturate(100%)" }}
          animate="loop"
        >
          <StyledAlert
            onClose={() => {
              setAlertOpen(false);
            }}
            severity="error"
            icon={<ErrorOutline fontSize="inherit" />}
          >
            {i18n("SELECTED_FARM_WATER_LIMIT_EXCEEDED")}
          </StyledAlert>
        </motion.div>
      )}
      <div
        className={
          irpd?.permission_level >= 2
            ? "generalbox__inner"
            : "generalbox__lowPermissionInner"
        }
      >
        {!isMobile() && (
          <div className="generalbox__map">
            <IrpdMap irpd={irpd} draggable={false} />
          </div>
        )}

        <div className="generalbox__content">
          {isMobile() ? null : <QuickSelect farmID={farmID} />}

          <div className="generalbox__content__items">
            <div className="generalbox__status">
              <span style={{ backgroundColor: latestStatus?.color }}>
                {latestStatus?.text.toUpperCase()}
              </span>
            </div>
            <div className="generalbox__content__item">
              <img
                src={CenterIcon}
                width="20px"
                height="20px"
                style={{
                  marginRight: "8px",
                  opacity: 0.85,
                  height: 20,
                }}
              />
              {`${
                irpd.latest_irpd_pressure_stream?.pressure
                  ? irpd.latest_irpd_pressure_stream?.pressure / 10
                  : "-"
              } bar`}
            </div>
            <div className="generalbox__content__item">
              <>{i18n("GENERAL_BOX_LAST_UPDATE")}</>
              {latestIrpdStream
                ? moment(latestIrpdStream?.created).format("DD MMM HH:mm")
                : latestUpdate}
            </div>
          </div>
        </div>

        <div className="generalbox__actions">
          <div className="generalbox__actions__offlinealert">
            {!isOnline && !isOnlineLoading ? (
              <Alert severity="error">{i18n("SELECTED_IRPD_OFFLINE")}</Alert>
            ) : (
              <></>
            )}
          </div>
          {irpd?.permission_level >= 2 && (
            <>{irpd.protocol >= 5 ? irpdButtonsV5 : irpdButtonsOld}</>
          )}
        </div>
      </div>
    </div>
  );

  const generalBoxContentV5 = (
    <div className="generalbox">
      {alertOpen && waterLimitExceeded && (
        <motion.div
          variants={alertVariants}
          initial={{ filter: "saturate(100%)" }}
          animate="loop"
        >
          <StyledAlert
            onClose={() => {
              setAlertOpen(false);
            }}
            severity="error"
            icon={<ErrorOutline fontSize="inherit" />}
          >
            {i18n("SELECTED_FARM_WATER_LIMIT_EXCEEDED")}
          </StyledAlert>
        </motion.div>
      )}

      <div className="generalbox__inner">
        {!isMobile() && (
          <div className="generalbox__map">
            <IrpdMap
              latest={latestIrpdStreamV5}
              irpd={irpd}
              draggable={false}
            />
          </div>
        )}

        <div className="generalbox__content">
          {isMobile() ? null : <QuickSelect farmID={farmID} />}

          <div className="generalbox__content__items">
            <div
              className="generalbox__status"
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <span
                style={{
                  backgroundColor: getIrpdStreamStatus(
                    latestIrpdStreamV5?.content?.imanage_master_status?.status
                  ).color,
                }}
              >
                {getIrpdStreamStatus(
                  latestIrpdStreamV5?.content?.imanage_master_status?.status
                ).text.toUpperCase()}
              </span>

              {latestIrpdStreamV5?.content?.imanage_master_status?.status ==
              193 ? (
                <StatusHelper />
              ) : null}
            </div>

            {irpd.protocol === 5.2 ? (
              // <div className="generalbox__content__item">
              //   {!isNaN(
              //     Number(
              //       irpd.latest_irpd_stream_v5_periodic?.content?.battery_level
              //         ?.battery_level
              //     )
              //   ) ? (
              //     <>
              //       {irpd.latest_irpd_stream_v5_periodic?.content?.battery_level
              //         ?.battery_level === 1 ? (
              //         <>
              //           <BatteryFull
              //             style={{
              //               marginRight: "8px",
              //               color: "green",
              //               opacity: 0.85,
              //               height: 20,
              //               width: 20,
              //             }}
              //           />
              //           {i18n("BATTERY_LEVEL_CHARGED")}
              //         </>
              //       ) : (
              //         <>
              //           <Battery20
              //             style={{
              //               marginRight: "8px",
              //               color: "red",
              //               opacity: 0.85,
              //               height: 20,
              //               width: 20,
              //             }}
              //           />
              //           {i18n("BATTERY_LEVEL_LOW")}
              //         </>
              //       )}
              //     </>
              //   ) : null}
              // </div>
              // Uncomment the line above to restore battery status
              <></>
            ) : (
              <div className="generalbox__content__item">
                <img
                  src={CenterIcon}
                  width="20px"
                  height="20px"
                  style={{
                    marginRight: "8px",
                    opacity: 0.85,
                    height: 20,
                  }}
                />

                {pressureV5 > 0
                  ? formatFloatValue(pressureV5, 1) + ` bar`
                  : "- bar"}
              </div>
            )}

            {/* -------------última atualização no general box nesta div aqui ------*/}
            <div className="generalbox__content__item">
              <>{i18n("GENERAL_BOX_LAST_UPDATE")}</>
              {moment(latestIrpdStreamV5?.created).format("DD MMM HH:mm")}
            </div>
            {/* --------------------------------------------------------------------*/}
          </div>
        </div>

        <div className="generalbox__actions">
          <div className="generalbox__actions__offlinealert">
            {!isOnline && !isOnlineLoading ? (
              <Alert severity="error">{i18n("SELECTED_IRPD_OFFLINE")}</Alert>
            ) : (
              <></>
            )}
          </div>
          {irpd?.permission_level >= 2 && (
            <>{irpd.protocol >= 5 ? irpdButtonsV5 : irpdButtonsOld}</>
          )}
        </div>
      </div>
    </div>
  );

  if (loading) return <></>;
  else
    return (
      <>
        <MobileZone>
          <Fade in={true}>
            {irpd?.protocol < 5 ? generalBoxContentOld : generalBoxContentV5}
          </Fade>
        </MobileZone>

        <DesktopZone>
          {irpd?.protocol < 5 ? generalBoxContentOld : generalBoxContentV5}
        </DesktopZone>
      </>
    );
}

export default React.memo(IrpdGeneralBox);
